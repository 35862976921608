import MainLayout from "../../components/Layouts";
import React from "react";
import AddContent from "../../components/Content/AddContent";

const ContentAddPage = () => {
  return (
    <MainLayout>
      <AddContent />
    </MainLayout>
  );
};

export default ContentAddPage;
