import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Empty, Form, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { getPage, getParamValue } from "../../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import LoaderFull from "../../common/LoaderFull";
import _ from "lodash";
import { isAllowedService } from "../../../utils/services";
import { responseNotification } from "../../../utils/notify";

const ContentCategoriesList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState("");

  const [contentCategoryData, setContentCategoryData] = useState<any>({
    loading: false,
    data: null,
  });

  const isParent = "false";

  const getContentCategory = useCallback(async () => {
    setContentCategoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/web-content/category?type=${type}` +
          (isParent ? `&isParent=${isParent}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setContentCategoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setContentCategoryData({ loading: false, data: [] });
        console.error("Page: Error", err);
      });
  }, [type, limit, page]);

  const onDelete = async (id: any, val: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/web-content/category`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            isActive: val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getContentCategory();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getContentCategory();
  }, [getContentCategory]);

  return (
    <>
      <BreadCrumb
        title="Category List"
        subTitle={`${
          contentCategoryData?.data?.totalElements ||
          contentCategoryData?.data?.categories?.length ||
          0
        } Category(s)`}
        extra={[
          <Button type="dashed" shape="circle" key={1}>
            <SearchOutlined />
          </Button>,
          isAllowedService("Contents Page Create") && (
            <AddBtn
              onClick={() => {
                navigate("/contents/pages/create");
              }}
              key={2}
            />
          ),
        ]}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={`content-body`}>
              {confirmLoading && <LoaderFull />}
              {contentCategoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Page Title</th>
                      <th scope="col">Type</th>
                      <th scope="col">Position</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {contentCategoryData?.data?.categories?.length ? (
                      contentCategoryData?.data?.categories?.map(
                        (page: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(`/contents/pages/${page?.slug}`)
                            }
                          >
                            <td>
                              <Avatar size={40} src={page?.image} />
                              <span className="font-medium text-gray-500 ml-2">
                                {page?.title}
                              </span>
                            </td>

                            <td>
                              <span className="font-medium text-gray-500">
                                {page?.contentType}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {page?.position?.split("_").join(" ")}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={page?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(page?.id, val);
                                }}
                              />
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    navigate(
                                      `/contents/pages/${page?.slug}/edit`
                                    );
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...contentCategoryData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default ContentCategoriesList;
