import PropTypes from "prop-types";
import { ReactElement, useEffect, useState } from "react";

const SingleBanner = ({
  TimeSlotDetails,
}: {
  TimeSlotDetails: any;
}): ReactElement => {
  const [getTimeSlotDetails, setTimeSlotDetails] = useState<any>(undefined);

  useEffect(() => {
    setTimeSlotDetails(TimeSlotDetails);
  }, [TimeSlotDetails]);

  return (
    <>
      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">Service Location</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span>{getTimeSlotDetails?.serviceLocation}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">Available Slot</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span>{getTimeSlotDetails?.availableSlot}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">Already Occupied</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span>{getTimeSlotDetails?.alreadyOccupied || 0}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">Day</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span>{getTimeSlotDetails?.day}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">From Hour</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span>{getTimeSlotDetails?.fromHour}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">To Hour</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span>{getTimeSlotDetails?.toHour}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-no-wrap py-2">
        <div className="w-2/5 flex-none">
          <div className="text-gray-500">
            <span className="font-bold">Status</span>
          </div>
        </div>
        <div className="w-2/5 flex-none">
          <div className="text-gray-700">
            <span
              className={`radius-btn ${
                getTimeSlotDetails?.isActive ? "active" : "inactive"
              }-btn`}
            >
              {getTimeSlotDetails?.isActive ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
SingleBanner.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default SingleBanner;
