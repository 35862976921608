import MainLayout from "../../components/Layouts";
import MerchantDetails from "../../components/Merchant/Details";

const MerchantDetailsPage = () => {
  return (
    <MainLayout>
      <MerchantDetails />
    </MainLayout>
  );
};

export default MerchantDetailsPage;
