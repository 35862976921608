import MainLayout from "../../components/Layouts";
import RequestedProductList from "../../components/RequestedProduct";

const RequestedProductPage = () => {
  return (
    <MainLayout>
      <RequestedProductList />
    </MainLayout>
  );
};

export default RequestedProductPage;
