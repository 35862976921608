import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPage, getParamValue } from "../../../utils/index";
import { Loader, Pagination, Empty } from "../../common";
import moment from "moment";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import DriverLayout from "./Layout";
import styles from "../../../styles/tailwind/List.module.css";

const DriverTransactionList = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState<string>();
  const [limit, setLimit] = useState(16);

  const [usersData, setUsersData] = useState<any>({
    loading: false,
    data: null,
  });

  const [selectedUser, setSelectedUser] = useState(undefined);
  const route = useParams();

  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const getUserOrders = useCallback(async () => {
    setUsersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/driver/transaction-history?` +
          `&deliveryManId=${(route as any)?.id || ``}` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUsersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setUsersData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [route, page, limit]);

  const onCashCollect = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      amount: data.amount * 1,
      note: data.note,
      deliveryManId: (route as any)?.id as string,
    };

    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/transaction/${action}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Cash Recieved Successfully!!!", "success");
          setVisible(!visible);
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    getUserOrders();
  }, [getUserOrders]);

  useEffect(() => {
    if (action) {
      setVisible(true);
    }
  }, [action]);

  const onClose = () => {
    getUserOrders();
    setVisible(false);
    setAction(undefined);
  };

  return (
    <React.Fragment>
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Transaction History"
        extra={[
          <Button
            type="primary"
            shape="round"
            onClick={() => setAction("cash-collection")}
            className="bg-green-600 border-green-400"
          >
            Cash Collect
          </Button>,
          <Button
            type="primary"
            shape="round"
            danger
            onClick={() => setAction("cash-deposit-to-deliveryman")}
          >
            Pay to DM
          </Button>,
        ]}
      >
        <div className={`content-body`}>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-2 overflow-x-scroll">
            <table className={styles.mainTable}>
              <thead className="bg-white border-b">
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Description</th>
                  <th scope="col">Type</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>{" "}
              {usersData?.loading ? (
                <Loader />
              ) : (
                <tbody>
                  {usersData?.data?.transactions?.length ? (
                    usersData?.data?.transactions?.map(
                      (transaction: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedUser(transaction)}
                        >
                          <td>
                            <span className="font-medium text-gray-500">
                              {moment(transaction?.createdAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {transaction?.transactionId}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {transaction?.orderId}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {transaction?.description?.substr(0, 45)}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {transaction?.type?.split("_")?.join(" ")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              &#2547;{transaction?.amount}
                            </span>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            <Pagination
              {...usersData?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
        </div>
      </DriverLayout>

      <Modal
        title={action?.toUpperCase()?.split("-")?.join(" ")}
        visible={!!visible}
        confirmLoading={loading}
        destroyOnClose={true}
        onCancel={onClose}
        footer={false}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          onFinish={onCashCollect}
        >
          <Form.Item
            label="Enter Amount"
            name="amount"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (
                    action == "cash-collection" &&
                    value > driverInfo?.balance
                  ) {
                    return Promise.reject(
                      new Error("Amount Must be Equal or less than Balance")
                    );
                  }
                },
              },
            ]}
          >
            <Input type="number" placeholder="Enter Collected Amount" />
          </Form.Item>

          <Form.Item name="note" label="Note" rules={[{ required: true }]}>
            <Input.TextArea
              placeholder="Please write some note or description..."
              rows={5}
            />
          </Form.Item>

          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            danger={action == "cash-collection"}
          >
            {action?.toUpperCase()?.split("-")?.join(" ")}
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default DriverTransactionList;
