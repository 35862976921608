import BrandList from "../../components/Brands";
import MainLayout from "../../components/Layouts";

const BrandPage = () => {
  return (
    <MainLayout>
      <BrandList />
    </MainLayout>
  );
};

export default BrandPage;
