import { authenticateToken } from "../../utils/auth";
import { SearchOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import Empty from "../common/Empty";
import { AutoComplete, Button, Form, Input, Popconfirm, Spin } from "antd";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import DetailsBtn from "../common/DetailsBtn";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import styles from "../../styles/tailwind/List.module.css";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
const BrandList = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const [key, setKey] = useState("");
  const [visible, setVisible] = useState<any>(undefined);
  const { type } = useSelector((state) => (state as any)?.authReducer);
  //const [type, setType] = useState("JC_GROCERY");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [polygonOptions, setPolygonOptions] = useState<any>({
    loading: false,
    list: null,
  });
  const [neighborhoodData, setNeighborhoodData] = useState<any>({
    loading: false,
    data: null,
  });
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const reseAllFieldData = () => {
    setKey("");
    form.resetFields();
    navigate(window.location.pathname);
    window.location.reload();
  };

  const getNeighborhood = useCallback(async () => {
    let pageReseted = false;

    setNeighborhoodData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/neighborhood/all` +
          (type ? `?type=${type}` : ``) +
          (page ? `&page=${pageReseted ? 0 : page || 0}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setNeighborhoodData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setNeighborhoodData({ loading: false, data: [] });
      });
  }, [limit, page, type, key]);

  const getPolygonOptions = useCallback(async (key) => {
    setPolygonOptions({ loading: true, list: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/neighborhood/all` +
          (type ? `?type=${type}` : ``) +
          (key ? `&key=${key}` : ``) +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setPolygonOptions({
          loading: false,
          list: res.data?.neighborhoods?.map((neighborhood: any) => ({
            label: neighborhood.name,
            value: neighborhood.name,
          })),
        });
      })
      .catch((err) => {
        setPolygonOptions({ loading: false, list: [] });
      });
  }, []);

  useEffect(() => {
    getNeighborhood();
  }, [getNeighborhood]);

  const fetchRef = useRef(0);
  const polygonSearchDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      getPolygonOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getPolygonOptions]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisible(id);
  };
  const handleCancel = () => {
    setVisible(undefined);
  };
  const onDelete = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/neighborhood/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Neighborhood Deleted Successfully",
              "success"
            );
            getNeighborhood();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  return (
    <>
      <BreadCrumb
        title={`Neighborhood List`}
        subTitle={`${
          neighborhoodData?.data?.totalElements ||
          neighborhoodData?.data?.neighborhoods?.length ||
          0
        } Neighborhood(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn
            onClick={() => {
              navigate("/neighborhood/add");
            }}
          />,
          <DetailsBtn onClick={() => navigate(`/neighborhood/details`)} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search" initialValue={key}>
              <AutoComplete
                onSearch={getPolygonOptions}
                onSelect={(val) => setKey(val.toString())}
                options={polygonOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  polygonOptions?.loading ? <Spin size="small" /> : null
                }
                style={{ width: 300 }}
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={polygonOptions.loading}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {neighborhoodData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Neighborhood Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>

                  <tbody>
                    {neighborhoodData?.data?.neighborhoods?.length ? (
                      neighborhoodData?.data?.neighborhoods?.map(
                        (neighborhood: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              onClick={() =>
                                navigate(
                                  `/neighborhood/${neighborhood?.id}/${neighborhood?.type}/details`
                                )
                              }
                            >
                              <span className="name">{neighborhood?.name}</span>
                            </td>
                            <td
                              onClick={() =>
                                navigate(
                                  `/neighborhood/${neighborhood?.id}/${neighborhood?.type}/details`
                                )
                              }
                            >
                              <span className="name">
                                {neighborhood?.type?.replace("_", " ")}
                              </span>
                            </td>
                            <td>
                              <Link
                                className="btn btn-sm btn-warning mx-1"
                                to={`/neighborhood/${neighborhood?.id}/${neighborhood?.type}/details`}
                              >
                                <EyeOutlined />
                              </Link>
                              <Popconfirm
                                placement="left"
                                title="Are you sure to delete?"
                                visible={neighborhood?.id === visible}
                                onConfirm={() => onDelete(neighborhood?.id)}
                                okButtonProps={{ loading: confirmLoading }}
                                onCancel={handleCancel}
                              >
                                <button
                                  className="btn btn-sm btn-danger mx-1"
                                  onClick={() =>
                                    showPopconfirm(neighborhood?.id)
                                  }
                                >
                                  <DeleteOutlined />
                                </button>
                              </Popconfirm>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty data="Neighborhood" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...neighborhoodData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default BrandList;
