import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";

const AddEmployeeGroup = ({ visibleData, onCloseMethod }: any) => {
  const { corporateInfo } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("JC_MART");
  const [groupData, setGroupData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      companyId: corporateInfo?.id,
      groupName: data.groupName,
    };

    if (groupData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/company/admin`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          groupId: groupData?.id,
          // isActive: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/company/admin`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Created Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setGroupData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (groupData) {
      form?.resetFields(Object.keys(visibleData));
    }
    form?.resetFields();
  }, [groupData]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...groupData,
          }}
        >
          <Form.Item
            hasFeedback
            label="Group Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="groupName"
          >
            <Input id="groupName" type="text" placeholder="Enter Group Name" />
          </Form.Item>

          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => form?.resetFields()}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddEmployeeGroup;
