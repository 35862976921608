import { ReactElement } from "react";
import DriverDetails from "../../../components/Delivery/DeliveryHero/Details";
import MainLayout from "../../../components/Layouts";

const DriversDetailsPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverDetails />
    </MainLayout>
  );
};

export default DriversDetailsPage;
