import React, { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import SMSNotification from "../../components/Notification/sms";

const SMSNotificationPage = (): ReactElement => {
  return (
    <MainLayout>
      <SMSNotification />
    </MainLayout>
  );
};

export default SMSNotificationPage;
