import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import OrderCreate from "../../components/Orders/CreateOrder/OrderCreate";

const OrderCreatePage = (): ReactElement => {
  return (
    <MainLayout> 
      <OrderCreate />
    </MainLayout>
  );
};

export default OrderCreatePage;
