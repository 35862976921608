import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  TreeSelect,
  TreeSelectProps,
} from "antd";

import axios from "axios";
import type { DefaultOptionType } from "antd/es/select";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import SubmitResetBtn from "../common/SubmitBtn";
import { useParams } from "react-router";
import { QuestionCircleOutlined } from "@ant-design/icons";

const AddCategory = ({
  visibleData,
  onCloseMethod,
  parentCategoryId,
  changeParent = false,
  level,
}: {
  visibleData: any;
  onCloseMethod: any;
  parentCategoryId: string;
  changeParent?: boolean;
  level?: number;
}) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [singleCategoryData, setSingleCategoryData] = useState<any>();

  // ************************
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  // ************************

  const fetchCategoryDetails = async (visibleData: any) => {
    if (visibleData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/detail?categoryId=${visibleData}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            setSingleCategoryData(res?.category);
            setCategoryId(res?.category?.parent);
            setGeneratedImgUrl(res?.category?.image);
            setBannerImgUrl(res?.category?.bannerImage);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      fetchCategoryDetails(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (singleCategoryData) {
      form.resetFields();
    }
  }, [singleCategoryData]);

  // ************************
  const [categoryId, setCategoryId] = useState<any>();
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getParentCategory = useCallback(
    async (val: string) => {
      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/${
          changeParent
            ? `parent-by-type?type=${type}`
            : `child-category-by-parent?categoryId=${(route as any).categoryId}`
        }&page=0&limit=50` + (val ? `&key=${val}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setTreeData(
        res.data?.categories?.map(
          (category: Omit<DefaultOptionType, "label">) => ({
            ...category,
            id: category?.id,
            pId: 0,
            title: category?.title,
            value: category?.id,
            isLeaf: false,
          })
        )
      );
    },
    [parentCategoryId, changeParent]
  );

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
  };

  const filteredCategory = treeData.find((category) => {
    return category?.id === categoryId || singleCategoryData?.parent;
  });
  // ************************

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload, startUpload2]);

  const onSubmit = async (data: {
    title: any;
    slug: any;
    keyword: any;
    image: any;
    bannerImage: any;
    description: any;
    level: any;
    parent: any;
    commissionType: any;
    commissionAmount: any;
    position: any;
    type: any;
  }) => {
    if (bannerImgUrl) {
      setLoading(true);

      const readyData = data && {
        type: type,
        title: data.title,
        keyword: data.keyword,
        description: data.description,
        position: parseInt(data.position),
        level: parseInt(
          filteredCategory?.level + 1 ||
            singleCategoryData?.level ||
            (level as any) + 1 ||
            0
        ),
        parent: categoryId || parentCategoryId || null,
        commissionType: data.commissionType,
        commissionAmount: parseFloat(data.commissionAmount),
        bannerImage: bannerImgUrl ? bannerImgUrl : data?.bannerImage,
        image: generatedImgUrl ? generatedImgUrl : data?.image,
      };

      if (singleCategoryData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/category`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: singleCategoryData?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Category Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/category`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Category Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    getParentCategory(``);
  }, []);

  useEffect(() => {
    if (changeParent) {
      getParentCategory(``);
    }
  }, [changeParent]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setBannerImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  console.log("Level", level);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmit}
        initialValues={{
          ...singleCategoryData,
        }}
        form={form} // like ref
        layout="vertical"
        autoComplete="off"
      >
        <div className="grid grid-cols-2 xl:gap-x-4">
          {!!treeData?.length && (
            <div className="col-span-2">
              <Form.Item
                hasFeedback
                label="Category Parent (if any)"
                name="parent"
              >
                <TreeSelect
                  allowClear
                  treeDataSimpleMode
                  style={{ width: "100%" }}
                  value={categoryId}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  onChange={onChange}
                  loadData={onLoadData}
                  treeData={treeData}
                />
              </Form.Item>
            </div>
          )}

          <div>
            <Form.Item
              hasFeedback
              label="Category Title"
              rules={[
                {
                  required: true,
                  message: "Title is Required!",
                },
              ]}
              name="title"
            >
              <Input id="title" type="text" placeholder="Enter Title" />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="Position"
              rules={[
                {
                  required: false,
                  message: "Position is Required!",
                },
              ]}
              name="position"
            >
              <Input
                type="number"
                id="position"
                placeholder="Enter Position"
                min="0"
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="Commission Type"
              rules={[
                {
                  required: false,
                  message: "Commission Type is Required!",
                },
              ]}
              name="commissionType"
              initialValue="PERCENTAGE"
            >
              <Select
                options={[
                  { value: "PERCENTAGE", label: "PERCENTAGE" },
                  { value: "FLAT", label: "FLAT" },
                ]}
                placeholder="Enter Commission Type"
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="Commission Amount"
              rules={[
                {
                  required: false,
                  message: "Commission Amount is Required!",
                },
              ]}
              name="commissionAmount"
            >
              <Input
                id="commissionAmount"
                type="number"
                min={0}
                placeholder="Enter Commission Amount"
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="Keyword"
              rules={[
                {
                  required: false,
                  message: "Keyword is Required!",
                },
              ]}
              name="keyword"
            >
              <Input.TextArea
                rows={3}
                id="keyword"
                placeholder="Enter Keyword"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              hasFeedback
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Description is Required!",
                },
              ]}
              name="description"
            >
              <Input.TextArea
                rows={3}
                id="description"
                placeholder="Enter Description"
              />
            </Form.Item>
          </div>
        </div>

        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Icon Image
              <Tooltip
                placement="bottom"
                title={
                  "Icon Image should be square. We prefer height 200px and width 200px (Ratio 1)"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">
              Banner Image
              <Tooltip
                placement="bottom"
                title={"Banner image should 250px/1600px (Ratio 6.4)"}
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              // crop={6.4}
              imgUrl={bannerImgUrl || ""}
              startUpload={startUpload2}
              setStartUpload={(val: any) => setStartUpload2(val)}
              setGeneratedImgUrl={(url: any) => setBannerImgUrl(url)}
            />
          </Col>
        </Row>

        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
            setStartUpload2("Uploading");
          }}
          onClickReset={resetData}
          disabled={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
          loading={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
        />
      </Form>
    </>
  );
};

export default AddCategory;
