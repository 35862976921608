import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import JoditEditor from "jodit-react";

import ImageUploader from "../FileManager/upload";

// @ts-ignore
import builkcsv from "../../demo-files/mail.csv";
import * as d3 from "d3";
import BreadCrumb from "../Layouts/Breadcrumb";
import { SubmitResetBtn } from "../common";

type CSVData = {
  emailAddresses: string[];
};

const EmailNotification = () => {
  const editor = useRef(null);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [loading, setLoading] = useState(false);
  const [fetchedCSVData, setFetchedCSVdata] = useState<CSVData | any>();
  const [convertedText, setConvertedText] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      subject: data.subject,
      from: '"jachai.com ltd "<support@jachai.com.com>',
      // body: ``,
      body: `<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n<meta charset=\"UTF-8\">\n<title>\`${
        data.subject
      }\`</title>\n</head>\n<body>\`<div>${convertedText}</div>${
        generatedImgUrl
          ? `<img src=\"${generatedImgUrl}\" alt=\"${data.subject}\" width=\"500\" >`
          : ``
      }\`\n</body>\n</html>`,
      emailAddresses:
        fetchedCSVData?.emailAddresses?.filter((item: any) => item) || [],
    };

    await fetch(`${process.env.REACT_APP_NOTIFICATION_API}/email/bulk`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Mail Sent Successfully", "success");
          resetData();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
    setFetchedCSVdata(null);
    setConvertedText("");
  };

  const simpleFileDownload = () => {
    window.location.href = `${builkcsv}`;
  };

  const handleUpload = async (data: any) => {
    const filteredData: any = [];
    console.log("emails", data);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    data?.forEach((row: any) => {
      const email = row.emailAddresses;
      if (regex.test(email)) {
        filteredData.push(email);
      } else {
        filteredData.push(undefined);
      }
    });
    setFetchedCSVdata({
      emailAddresses: filteredData,
    });
  };

  const readUploadFile = (val: any) => {
    const mainFile = val.fileList[0].originFileObj;

    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        d3.csv(data).then((res) => {
          handleUpload(res);
          console.log("Contacts:", res);
        });
      };
      reader.readAsDataURL(mainFile);
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb
        title="Send Mail"
        extra={
          <Button onClick={simpleFileDownload} type="dashed" shape="round">
            <DownloadOutlined />
            Sample File
          </Button>
        }
      />
      <div>
        <Row gutter={25} align="middle" justify="center">
          <Col span={12}>
            <Card>
              <Form layout="vertical" onFinish={onSubmit} form={form}>
                <Form.Item
                  hasFeedback
                  label="Subject"
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Subject is required",
                    },
                  ]}
                >
                  <Input id="subject" placeholder="Enter Subject" />
                </Form.Item>

                <JoditEditor
                  ref={editor}
                  value={convertedText}
                  onBlur={(newContent) => setConvertedText(newContent)}
                  onChange={(newContent) => {}}
                />

                <h4 className="mt-6 mb-2 font-medium text-lg text-gray-600">
                  Image:
                </h4>
                <div className="">
                  <ImageUploader
                    imgUrl={generatedImgUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>

                <div className="my-4">
                  <Form.Item
                    hasFeedback
                    label=""
                    name="upload"
                    rules={[
                      {
                        required: true,
                        message: "File is required",
                      },
                    ]}
                  >
                    <Upload onChange={readUploadFile}>
                      <Button
                        type="dashed"
                        size="large"
                        shape="round"
                        danger
                        htmlType="button"
                        icon={<UploadOutlined />}
                      >
                        Upload Contacts (.csv)
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>

                {fetchedCSVData?.emailAddresses?.length >= 0 && (
                  <div className="mb-2 txt-red accent-txt">
                    Uploaded{" "}
                    {
                      fetchedCSVData?.emailAddresses?.filter(
                        (item: any) => item
                      )?.length
                    }{" "}
                    Valid Contacts
                  </div>
                )}
                {fetchedCSVData?.emailAddresses?.length !==
                  fetchedCSVData?.emailAddresses?.filter((item: any) => item)
                    ?.length && (
                  <div className="mb-2 txt-red accent-txt">
                    Error in{" "}
                    {fetchedCSVData?.emailAddresses?.length -
                      fetchedCSVData?.emailAddresses?.filter(
                        (item: any) => item
                      )?.length}{" "}
                    (row-
                    {fetchedCSVData?.emailAddresses?.map((item: any, i: any) =>
                      item === undefined ? i + 1 : undefined
                    )}
                    ) email addresses
                  </div>
                )}

                <div className="mt-10">
                  <SubmitResetBtn
                    onClickSubmit={(e) => {
                      e.preventDefault();
                      setStartUpload("Uploading");
                    }}
                    onClickReset={() => {
                      form?.resetFields();
                      setGeneratedImgUrl(undefined);
                      setStartUpload(undefined);
                      // setBannerUrl(undefined);
                    }}
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EmailNotification;
