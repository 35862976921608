import React from "react";
import MainLayout from "../../components/Layouts";
import FeedbackList from "../../components/Feedback";

const FeedbackPage = () => {
  return (
    <MainLayout>
      <FeedbackList />
    </MainLayout>
  );
};

export default FeedbackPage;
