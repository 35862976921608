import { authenticateToken } from "../../utils/auth";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
  Switch,
  Tag,
  TreeSelect,
  TreeSelectProps,
  DatePicker,
  Drawer,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { BarcodeOutlined } from "@ant-design/icons";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../common/LoaderFull";
import { debounce } from "lodash";
import { DefaultOptionType } from "antd/lib/select";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import EditProductKeyword from "./AddProduct";
const { RangePicker } = DatePicker;

const ProductList = (): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [limit, setLimit] = useState(50);
  const loc = useLocation();
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedKey, setSelectedKey] = useState(undefined);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState(undefined);

  const [productData, setProductData] = useState<any>({
    loading: false,
    item: [],
  });

  const [status, setStatus] = useState("");
  const [key, setKey] = useState("");
  const [barCode, setBarCode] = useState("");
  const [isDeleted, setIsDeleted] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState<any>(undefined);
  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [categoriesOptions, setCategoriesOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [categoryId, setCategoryId] = useState<any>();
  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [merchantId, setMerchantId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [shopId, setShopId] = useState<any>();

  const router = useLocation();
  const page = getParamValue(router?.search, "page");

  const [form] = Form.useForm();

  const fetchProducts = useCallback(() => {
    try {
      setProductData({ loading: true, item: [] });

      const url =
        `${process.env.REACT_APP_CATALOG_READER_API}/global-product?` +
        `page=${page || 0}` +
        `&limit=${limit || 16}` +
        (type ? `&type=${type}` : ``) +
        (key ? `&key=${key}` : ``) +
        (barCode ? `&barCode=${barCode}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``);

      fetch(url, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setProductData({ loading: false, item: data });
        })
        .catch(() => {
          setProductData({ loading: true, item: [] });
        });
    } catch (error) {
      setProductData({ loading: true, item: [] });
      console.log(error, "error");
    }
  }, [limit, page, categoryId, key, barCode, type]);

  const getProductByBarcodeOptions = useCallback(
    async (bCode) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/global-product?page=0&limit=20` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (key ? `&key=${key}` : ``) +
          (categoryId ? `&categoryId=${categoryId}` : ``) +
          (type ? `&type=${type}` : ``) +
          (isDeleted !== "" ? `&isDeleted=${isDeleted}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.products?.map(
          (product: { name: any; barCode: any }) => {
            return {
              value: product?.barCode,
              label: product?.name,
            };
          }
        ),
      });
    },
    [key, categoryId, type, isDeleted, status]
  );

  const getProductOptions = useCallback(
    async (val) => {
      setProductOptions({ loading: false, list: [] });

      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/global-product?page=0&limit=20` +
          (val ? `&key=${val}` : ``) +
          (barCode ? `&barCode=${barCode}` : ``) +
          (type ? `&type=${type}` : ``) +
          (categoryId ? `&categoryId=${categoryId}` : ``) +
          (isDeleted !== "" ? `&isDeleted=${isDeleted}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setProductOptions({
        loading: false,
        list: res?.data?.products?.map((product: { name: any }) => {
          return {
            value: product?.name,
            label: product?.name,
          };
        }),
      });
    },
    [barCode, type, categoryId, isDeleted, status]
  );

  const getCategoriesOptions = useCallback(
    async (val?: string) => {
      setCategoriesOptions({ loading: true, list: [] });
      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/category/search?page=0&limit=20` +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );

      setCategoriesOptions({
        loading: false,
        list: res?.data?.categories?.map((category) => {
          return {
            id: category?.id,
            title: category?.title,
          };
        }),
      });
    },
    [type]
  );

  const getBrandsOptions = useCallback(
    async (val?: string) => {
      setBrandsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (val ? `&key=${val}` : ``),

        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );

      setBrandsOptions({
        loading: false,
        list: res?.data?.brands?.map((brand) => {
          return {
            id: brand?.id,
            name: brand?.name,
          };
        }),
      });
    },
    [type]
  );

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/by-merchant?page=0&limit=20` +
          (merchantId ? `&merchantId=${merchantId}` : ``) +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type, merchantId]
  );

  const fetchRef = useRef(0);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "product") getProductOptions(value);
          else if (field === "productByBarcode")
            getProductByBarcodeOptions(value);
          else if (field === "category") getCategoriesOptions(value);
          else if (field === "brand") getBrandsOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getBrandsOptions,
    getCategoriesOptions,
    getProductOptions,
    getProductByBarcodeOptions,
    type,
  ]);

  useEffect(() => {
    setShopId("");
    setShopsOptions({ list: [], loading: false });
    setBrandId("");
    setIsDeleted("");
    setBrandsOptions({ list: [], loading: false });
    setCategoryId("");
    setCategoriesOptions({ list: [], loading: false });
    form.resetFields([
      "barcode_search",
      "product_search",
      "category_search",
      "brand_search",
      "shop_search",
    ]);
  }, [form, type]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (merchantId) {
      getShopsOptions();
    }
  }, [getShopsOptions, merchantId]);

  useEffect(() => {
    if (type) {
      getCategoriesOptions();
      getBrandsOptions();
    }
  }, [getBrandsOptions, getCategoriesOptions, type]);

  const reseAllFieldData = () => {
    setKey("");
    setBarCode("");
    setCategoryId("");
    setBrandId("");
    form.resetFields();
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    fetchProducts();
    setSelectedProductForEdit(undefined);
  };
  return (
    <>
      <BreadCrumb
        title="Product List"
        subTitle={`${productData?.data?.totalElements} Product(s)`}
        //childComponent={inShop ? true : false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            // onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />

      <div className={styles?.searchBox}>
        <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item name="category_search">
            <Select
              showSearch
              placeholder="Filter by Category"
              optionFilterProp="children"
              onChange={(val) => setCategoryId(val)}
              onSearch={(e) => handleSearch(e, "category")}
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {categoriesOptions.list.map((category) => (
                <Option value={category.id} key={category.id}>
                  {category.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="barcode_search" initialValue={barCode}>
            <AutoComplete
              onSearch={(e) => handleSearch(e, "productByBarcode")}
              onSelect={(val) => setBarCode(val)}
              options={productBarcodeOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                productBarcodeOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by BarCode"
                onSearch={(val) => {
                  setBarCode(val);
                }}
                enterButton
                loading={productBarcodeOptions.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="product_search" initialValue={key}>
            <AutoComplete
              onSearch={(e) => handleSearch(e, "product")}
              onSelect={(val) => setKey(val.toString())}
              options={productOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                productOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Name"
                onSearch={(val) => {
                  setKey(val);
                }}
                enterButton
                loading={productOptions.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="deleted_search" initialValue={isDeleted}>
            <Select
              showSearch
              placeholder="Filter by Type"
              onChange={(val) => setIsDeleted(val as string)}
            >
              <Select.Option value="">ACTIVE</Select.Option>
              <Select.Option value={"true"}>INACTIVE</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Button
          type="primary"
          danger
          size="large"
          htmlType="reset"
          onClick={reseAllFieldData}
        >
          Reset
        </Button>
      </div>

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Product Name</th>
                      <th scope="col">Barcode</th>
                      <th scope="col">Keywords</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.item?.products?.length ? (
                      productData?.item?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <Avatar
                                size={45}
                                src={product?.productImage}
                                shape="square"
                              />
                              <span className="font-medium text-gray-500 ml-2">
                                {product?.name?.slice(0, 40)}
                              </span>
                            </td>

                            <td>
                              <div className="d-flex_">
                                {product?.variations?.length
                                  ? product?.variations?.map(
                                      (variant, index) => (
                                        <React.Fragment key={index}>
                                          <div className="d-flex justify-content-start mb-1">
                                            {variant?.barCode ? (
                                              <Tag className="d-flex align-items-center">
                                                <BarcodeOutlined className="mr-1" />
                                                {variant?.barCode}
                                              </Tag>
                                            ) : product?.barCode ? (
                                              <Tag className="d-flex align-items-center">
                                                <BarcodeOutlined className="mr-1" />
                                                {product?.barCode}
                                              </Tag>
                                            ) : undefined}
                                            <Tag
                                              color={
                                                variant?.stock < 10
                                                  ? `red`
                                                  : `green`
                                              }
                                            >
                                              {variant?.variationName?.toUpperCase()}
                                              : <b>{variant?.stock || 0}</b>
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>
                            <td>
                              <span className="name">
                                <span className="font-medium text-gray-500 ml-2">
                                  {product?.keyword?.substring(0, 35)}...
                                </span>
                              </span>
                            </td>

                            <td>
                              <Button
                                onClick={() =>
                                  setSelectedProductForEdit(product)
                                }
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.item}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={selectedProductForEdit ? "Edit Keyword " : "Keyword Details "}
        width={selectedProductForEdit ? 500 : 600}
        onClose={onClose}
        visible={selectedKey || selectedProductForEdit}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        {
          selectedProductForEdit ? (
            <EditProductKeyword
              visibleData={selectedProductForEdit}
              onCloseMethod={onClose}
            />
          ) : undefined
          // <KeywordDetails KeywordDetails={selectedKey} />
        }
      </Drawer>
    </>
  );
};

export default ProductList;
