import React from "react";
import AddPage from "../../../components/Content/Page/AddPage";
import MainLayout from "../../../components/Layouts";

const ContentCategoryAddPage = () => {
  return (
    <MainLayout>
      <AddPage />
    </MainLayout>
  );
};

export default ContentCategoryAddPage;
