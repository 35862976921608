import { authenticateToken } from "../../../utils/auth";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Drawer, Empty, Modal, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { responseNotification } from "../../../utils/notify";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import SingleService from "./Details";
import AddService from "./AddService";
import { useSelector } from "react-redux";
const { confirm } = Modal;

const UserServiceList = (): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedService, setSelectedService] = useState(undefined);
  const [selectedServiceForEdit, setSelectedServiceForEdit] =
    useState(undefined);

  const [routerService, setRouterService] = useState<any>({
    loading: false,
    data: null,
  });

  const getRouterService = useCallback(async () => {
    setRouterService({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_AUTH_API}`;
    axios
      .get(`${encodedUrl}/service/by-type?Type=${type}`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
      .then((res) => {
        setRouterService({ loading: false, data: res.data });
      })
      .catch((err) => {
        setRouterService({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, []);

  //
  const onStatusChange = async (service: any, val: boolean) => {
    if (service) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: service?.id,
          serviceName: service?.name,
          type: service?.type,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getRouterService();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onDelete = async (serviceId: any) => {
    await fetch(`${process.env.REACT_APP_AUTH_API}/service/remove-service`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serviceNameId: serviceId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          getRouterService();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(serviceId: any) {
    confirm({
      title: "Do you confirm to delete this service?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onDelete(serviceId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Delete",
    });
  }

  useEffect(() => {
    getRouterService();
  }, [getRouterService]);

  const onClose = () => {
    getRouterService();
    setVisible(undefined);
    setSelectedService(undefined);
    setSelectedServiceForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Service List"
        subTitle={`${
          routerService?.data?.totalElements ||
          routerService?.data?.services?.length ||
          0
        } Service(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={showSearch ? `content-body` : `content-body`}>
              {routerService?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Service</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {routerService?.data?.services?.length ? (
                      routerService?.data?.services?.map(
                        (service: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>{service?.name}</td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={service?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(service, val);
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white mr-2"
                                onClick={() =>
                                  setSelectedServiceForEdit(service)
                                }
                              >
                                <EditOutlined />
                              </Button>
                              <Button
                                danger
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => showConfirm(service?.id)}
                              >
                                <DeleteOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...routerService?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Service"
            : selectedServiceForEdit
            ? "Edit Service"
            : "Service Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedService || selectedServiceForEdit}
        width={450}
      >
        {visible || selectedServiceForEdit ? (
          <AddService
            onCloseMethod={onClose}
            visibleData={selectedServiceForEdit}
            services={routerService?.data?.services || []}
          />
        ) : (
          <SingleService ServiceDetails={selectedService} />
        )}
      </Drawer>
    </>
  );
};

export default UserServiceList;
