import CategoryDetails from "../../components/Category/Details/index";
import MainLayout from "../../components/Layouts";

const CategoryDetailsPage = () => {
  return (
    <MainLayout>
      <CategoryDetails />
    </MainLayout>
  );
};

export default CategoryDetailsPage;
