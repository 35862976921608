import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import OrderVatTaxReport from "../../components/Reports/OrderVatTaxReport";
const OrderVatTaxPage = (): ReactElement => {
  return (
    <MainLayout>
      <OrderVatTaxReport />
    </MainLayout>
  );
};

export default OrderVatTaxPage;
