import React from "react";
import MainLayout from "../../components/Layouts";
import CorporateDetails from "../../components/Corporate/Details";

const CorporateDetailsPage = () => {
  return (
    <MainLayout>
      <CorporateDetails />
    </MainLayout>
  );
};

export default CorporateDetailsPage;
