import BreadCrumb from "../Layouts/Breadcrumb";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Line, DualAxes } from "@ant-design/plots";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { getParamValue } from "../../utils";
import axios from "axios";
import { authenticateToken } from "../../utils/auth";
import PropTypes from "prop-types";

const Dashboard = (): ReactElement => {
  const [data, setData] = useState([]);
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [seoMissingProductData, setSeoMissingProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [imageMissingProductData, setImageMissingProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const getTotalSEOMissingProducts = useCallback(async () => {
    setSeoMissingProductData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/admin/product/seo-missing?type=${type}&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setSeoMissingProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSeoMissingProductData({ loading: false, data: [] });
        console.error("Shop: Error", err);
      });
  }, []);

  const getTotalImageMissingProducts = useCallback(async () => {
    setImageMissingProductData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/admin/product/image-missing?type=${type}&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setImageMissingProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setImageMissingProductData({ loading: false, data: [] });
        console.error("Shop: Error", err);
      });
  }, []);

  // *******************************
  useEffect(() => {
    asyncFetch();
    getTotalSEOMissingProducts();
    getTotalImageMissingProducts();
  }, [getTotalSEOMissingProducts, getTotalImageMissingProducts]);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  const config = {
    data,
    padding: "auto",
    xField: "Date",
    yField: "scales",
    xAxis: {
      type: "timeCat",
      tickCount: 5,
    },
  };
  // *******************************

  // *******************************
  const uvBillData = [
    {
      time: "2022-07",
      value: 350,
      type: "uv",
    },
    {
      time: "2022-05",
      value: 900,
      type: "uv",
    },
    {
      time: "2022-05",
      value: 300,
      type: "uv",
    },
    {
      time: "2022-06",
      value: 450,
      type: "uv",
    },
    {
      time: "2022-07",
      value: 470,
      type: "uv",
    },
    {
      time: "2022-03",
      value: 220,
      type: "bill",
    },
    {
      time: "2022-04",
      value: 300,
      type: "bill",
    },
    {
      time: "2022-05",
      value: 250,
      type: "bill",
    },
    {
      time: "2022-06",
      value: 220,
      type: "bill",
    },
    {
      time: "2022-07",
      value: 362,
      type: "bill",
    },
  ];
  const transformData = [
    {
      time: "2022-03",
      count: 800,
    },
    {
      time: "2022-04",
      count: 600,
    },
    {
      time: "2022-05",
      count: 400,
    },
    {
      time: "2022-06",
      count: 380,
    },
    {
      time: "2022-07",
      count: 220,
    },
  ];
  const config2 = {
    data: [uvBillData, transformData],
    xField: "time",
    yField: ["value", "count"],
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField: "type",
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };
  // *******************************

  // *******************************
  const dataForPie = [
    {
      type: "分类一",
      value: 27,
    },
    {
      type: "分类二",
      value: 25,
    },
    {
      type: "分类三",
      value: 18,
    },
    {
      type: "分类四",
      value: 15,
    },
    {
      type: "分类五",
      value: 10,
    },
    {
      type: "其他",
      value: 5,
    },
  ];
  const configPie = {
    appendPadding: 10,
    dataForPie,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  // *******************************

  return (
    <>
      <BreadCrumb title="Dashboard" />

      <div className="">
        <div className="grid grid-cols-2 gap-6 mb-6 w-full xl:grid-cols-4 2xl:grid-cols-4">
          <Link to="/products?page-type=seo-missing">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900 sm:text-2xl">
                    {seoMissingProductData?.data?.totalElements}
                  </span>
                  <h3 className="text-base font-normal text-gray-500">
                    SEO Missing Product
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/products?page-type=image-missing">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {imageMissingProductData?.data?.totalElements}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Image Missing Product
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
            <div className="flex items-center">
              <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex-shrink-0 ml-3">
                <span className="text-2xl font-bold leading-none text-gray-900">
                  +3,462
                </span>
                <h3 className="text-base font-normal text-gray-500">
                  New Clients
                </h3>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
            <div className="flex items-center">
              <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                </svg>
              </div>
              <div className="flex-shrink-0 ml-3">
                <span className="text-2xl font-bold leading-none text-gray-900">
                  &#2547; 83,430
                </span>
                <h3 className="text-base font-normal text-gray-500">Sales</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-6 w-full xl:grid-cols-2">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="mb-2 text-xl font-bold text-gray-900">
                  Orders Flow
                </h3>
              </div>
              <div className="flex-shrink-0">
                <Link
                  to="/orders"
                  className="p-2 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  View all
                </Link>
              </div>
            </div>

            <div className="">
              <Line {...(config as any)} />
            </div>
          </div>

          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="mb-2 text-xl font-bold text-gray-900">
                  Delivery Report
                </h3>
              </div>
              <div className="flex-shrink-0">
                <Link
                  to="/delivery/drivers"
                  className="p-2 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  View all
                </Link>
              </div>
            </div>

            <div className="">
              <DualAxes {...config2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
