import {
  AutoComplete,
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  TreeSelect,
  TreeSelectProps,
} from "antd";
import {
  FileExcelFilled,
  SearchOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Loading from "../common/Loader";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import { authenticateToken } from "../../utils/auth";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, OrderTypeArray } from "../../utils";
import { debounce } from "lodash";
import { responseNotification } from "../../utils/notify";
import Pagination from "../common/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { Console } from "console";
import { DefaultOptionType } from "antd/lib/select";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const { RangePicker } = DatePicker;

const SalesReportList = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState("JC_MART");
  const [hubId, setHubId] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [salesData, setSalesData] = useState<any>({
    loading: false,
    data: null,
  });

  const [downloadData, setDownloadData] = useState<any>({
    loading: false,
    data: null,
  });

  const [hubOptions, setHubOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const getSalesReport = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setSalesData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/finance/sales` +
          (from ? `?fromDate=${from}` : ``) +
          (to ? `&toDate=${to}` : ``) +
          (hubId ? `&hubId=${hubId}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setSalesData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSalesData({ loading: false, data: [] });
        console.error("Data: Error", err);
      });
  }, [hubId, range.from, range.to, status]);

  const getSalesReportDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    fetch(
      `${encodedUri}/finance/sales/download?page=0` +
        (from ? `&fromDate=${from}` : ``) +
        (to ? `&toDate=${to}` : ``) +
        (hubId ? `&hubId=${hubId}` : ``) +
        (status ? `&status=${status}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((res) => {
        setDownloadData({ loading: false, data: res });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Sales_Report-${moment(range.from).format("YYYY_MM_DD")}-${moment(
            range.to
          ).format("YYYY_MM_DD")}.xlsx`
        );
        link.click();
      })
      .catch((err) => {
        console.error("Data: Error", err);
      });
  }, [hubId, range.from, range.to, status]);

  const fetchRef: any = useRef(null);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    getSalesReport();
  }, [getSalesReport]);

  const reseAllFieldData = () => {
    form.resetFields();
    setStatus("");
    setRange({
      from: null,
      to: null,
    });
  };

  const paymentMethods = useMemo(() => {
    let allPayments: any = {};

    salesData?.data?.sales?.find((shop: any) => {
      if (shop?.methodAmount?.length > 0) {
        shop?.methodAmount.forEach((method: any) => {
          allPayments[method?.paymentMethod] = 1;
        });
      }
    });
    return Object.keys(allPayments);
  }, [salesData]);

  const formatedData = useMemo(() => {
    const obj: any = {};
    salesData?.data?.sales?.map((shop: any) => {
      const method: any = {};
      shop?.methodAmount?.map((item: any) => {
        method[item?.paymentMethod] = item?.total;
      });
      obj[shop?.shopName] = method;
    });
    return obj;
  }, [salesData]);

  const totalData = useMemo(() => {
    return (
      salesData?.data?.sales?.reduce((obj: any, data: any) => {
        const newObj: any = {};
        console.log(1, obj, data?.methodAmount);

        data?.methodAmount?.map((item: any) => {
          newObj[item?.paymentMethod] =
            (obj[item?.paymentMethod] || 0) + (item?.total || 0);
        });

        const onlyMethods = { ...obj, ...newObj };
        let allMethodsTotal = 0;
        Object.keys(onlyMethods)?.forEach((item) => {
          if (item !== "total") {
            allMethodsTotal += onlyMethods?.[item];
          }
        });

        return {
          ...onlyMethods,
          total: allMethodsTotal,
        };
      }, {}) || {}
    );
  }, [salesData]);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  return (
    <React.Fragment>
      {/* <TopHeading
        {...salesData?.data?.totalElements}
        refetch={getSalesReport}
        title={"Sales Report"}
        download={() => getSalesReportDownload()}
        dissable={!salesData?.data?.sales?.length ? true : false}
        loading={downloadData?.loading}
      /> */}
      <BreadCrumb
        title="Sales Order Report"
        subTitle={`${salesData?.data?.totalElements} Order(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>

            <Form.Item name="status_search" initialValue={status}>
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <section className="product-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-list-items-inner-content">
                <div className="single-product-wrapper">
                  <div className="single-product-heading">
                    <span>Shop Name</span>
                  </div>
                  {paymentMethods.map((pm) => (
                    <div className="single-product-heading">
                      <span>{pm?.replace("_", " ")}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {salesData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12 product-table">
                <div className="product-table-area">
                  {salesData?.data?.sales?.length ? (
                    salesData?.data?.sales?.map(
                      (salesData: any, index: any) => (
                        <React.Fragment key={index}>
                          <div className="single-product-wrapper">
                            <div className="single-product">
                              <span className="name">
                                {salesData?.shopName}
                              </span>
                            </div>
                            {paymentMethods?.map((pm) => {
                              return (
                                <div className="single-product">
                                  <span className="name">
                                    {formatedData[salesData?.shopName][pm] || 0}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )
                    )
                  ) : (
                    // <Empty data="Data" />
                    <Empty />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            {salesData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="product-table-area ">
                  <div className="single-product-wrapper">
                    <div className="single-product">
                      <b className="name font-weight-bold">
                        Total (&#2547;{totalData["total"] || 0})
                      </b>
                    </div>
                    {paymentMethods?.map((pm) => {
                      return (
                        <div className="single-product">
                          <span className="name font-weight-bold">
                            &#2547;{totalData[pm] || 0}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SalesReportList;
