import MainLayout from "../../components/Layouts";
import CategoryProductList from "../../components/Category/Product/index";

const CategoryProductPage = () => {
  return (
    <MainLayout>
      <CategoryProductList />
    </MainLayout>
  );
};
export default CategoryProductPage;
