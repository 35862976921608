import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import HubLayout from "../Layout";
import HubHeader from "../Layout/topHeader";
import { getPage, getParamValue } from "../../../utils/index";
import Loading from "../../common/Loader";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Spin,
  Switch,
  Tabs,
  Upload,
} from "antd";
import AddEmployee from "./AddEmployee";
import PaginationTwo from "../../common/PaginationTwo";
import { authenticateToken } from "../../../utils/auth";
import axios from "axios";
import { useSelector } from "react-redux";
import EmployeeDetails from "./Details";
import { responseNotification } from "../../../utils/notify";
import styles from "../../../styles/tailwind/List.module.css";

// @ts-ignore
import builkcsv from "../../../demo-files/company-employees.csv";
import * as d3 from "d3";
import { debounce } from "lodash";
import TabPane from "antd/lib/tabs/TabPane";
import LoaderFull from "../../common/LoaderFull";
import Loader from "../../common/Loader";
import AddEmployeeGroup from "../EmployeeGroup/AddEmployeeGroup";
type CSVData = {
  employees: any[];
};
const { Option } = Select;

const CorporateEmployeeList = () => {
  const { corporateInfo } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(16);
  const loc = useLocation();
  const fetchRef = useRef<any>(null);
  const page = getParamValue(loc.search, "page");
  const [confirmLoading, setConfirmLoading] = useState<any>(undefined);
  const [showSearch, setShowSearch] = useState<any>(true);
  const [activeSearch, setActiveSearch] = useState<any>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<any>(false);
  const [refetchState, setRefetchState] = useState<any>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(undefined);
  const [loading, setLoading] = useState<any>(false);
  const [fetchedCSVData, setFetchedCSVdata] = useState<any>();
  const [groupId, setGroupId] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>(undefined);
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [selectedGroupForEdit, setSelectedGroupForEdit] =
    useState<any>(undefined);

  const [groupOptions, setGroupOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [selectedEmployeeForEdit, setSelectedEmployeeForEdit] =
    useState<any>(undefined);

  const [employeesData, setEmployeesData] = useState<any>({
    loading: false,
    data: null,
  });

  const [employeesOptions, setEmployeesOptions] = useState<any>({
    loading: false,
    list: null,
  });

  const getEmployee = useCallback(async () => {
    setEmployeesData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/employee/all?companyId=${corporateInfo?.id}` +
          (groupId ? `&groupId=${groupId}` : ``) +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
            : ``) +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setEmployeesData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setEmployeesData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [corporateInfo?.id, groupId, mobileNumber, page, limit]);

  const getEmployeesOptions = useCallback(
    async (mobileNumber) => {
      setEmployeesOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/employee/all?companyId=${corporateInfo?.id}` +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
              : ``) +
            `&page=${page || 0}` +
            `&limit=${limit || 16}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setEmployeesOptions({
            loading: false,
            list: res.data?.employees?.map((employee) => ({
              label: employee.name,
              value: employee.mobileNumber.replace("+88", ""),
            })),
          });
        })
        .catch((err) => {
          setEmployeesOptions({ loading: false, list: [] });
          console.error("Employees: Error", err);
        });
    },
    [corporateInfo?.id, limit, page]
  );

  const getGroupOptions = useCallback(
    async (val?: string) => {
      setGroupOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/company/admin/all?companyId=${corporateInfo?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setGroupOptions({
        loading: false,
        list: res?.data?.groups?.map((group) => {
          return {
            value: group?.id,
            label: group?.groupName,
          };
        }),
      });
    },
    [corporateInfo?.id]
  );

  // const getEmployeeOptions = useCallback(async () => {
  //   setEmployeesOptions({ loading: true, list: null });
  //   const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
  //   axios
  //     .get(`${encodedUri}/employee/all?companyId=${corporateInfo?.id}` + `page=${0}` + `&limit=${20}`, {
  //       headers: {
  //         Authorization: `Bearer ${authenticateToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       setEmployeesOptions({
  //         loading: false,
  //         list: res.data?.users?.map((user) => ({
  //           label: user.fullName,
  //           value: user.mobileNumber,
  //         })),
  //       });
  //     })
  //     .catch((err) => {
  //       setEmployeesOptions({ loading: false, list: [] });
  //       console.error("Users: Error", err);
  //     });
  // }, []);

  // *************************************
  // *************************************

  const onSubmit = async (data) => {
    setLoading(true);
    const readyData = data && {
      groupId: groupId,
      employees: fetchedCSVData?.employees?.map((employee, i) => ({
        name: employee?.name,
        emailAddress: employee?.emailAddress,
        mobileNumber: employee?.mobileNumber,
        designation: employee?.designation,
        employeeId: employee?.employeeId,
        creditLimit: parseFloat(employee?.creditLimit),
        discountPercentage: parseFloat(employee?.discountPercentage),
      })),
    };

    await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Employee Added Successfully", "success");
          resetData();
          getEmployee();
          setIsModalVisible(undefined);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onStatusChange = async (id, val: any) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getEmployee();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const resetData = () => {
    form?.resetFields();
    setFetchedCSVdata(null);
    setMobileNumber("");
  };

  const simpleFileDownload = () => {
    window.location.href = `${builkcsv}`;
  };

  const getApMobile = (mobileNumber) => {
    const mob = mobileNumber?.toString();
    if (mob) {
      return mob?.length === 10
        ? `+880${mob}`
        : mob?.length === 11
        ? `+88${mob}`
        : mob?.length === 13
        ? `+${mob}`
        : mob;
    }
  };

  const handleUpload = async (data: any) => {
    const filteredData = [];

    data?.forEach((row: any) => {
      filteredData.push({
        name: row.name?.trim(),
        mobileNumber: getApMobile(row.mobileNumber?.trim()),
        emailAddress: row.emailAddress?.trim(),
        designation: row.designation?.trim(),
        employeeId: row.employeeId?.trim(),
        discountPercentage: row.discountPercentage?.trim() * 1,
        creditLimit: row.creditLimit?.trim() * 1,
      });
    });
    setFetchedCSVdata({
      employees: filteredData,
    });
    console.log("data:", filteredData, data);
  };

  const readUploadFile = (val: any) => {
    const mainFile = val.fileList[0].originFileObj;

    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        d3.csv(data).then((res) => {
          handleUpload(res);
          console.log("Contacts:", res);
        });
      };
      reader.readAsDataURL(mainFile);
    }
  };
  // *************************************
  // *************************************

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "group") getGroupOptions();
        else if (field === "employee") getEmployeesOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getEmployeesOptions, getGroupOptions]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getEmployee();
    getGroupOptions();
  }, [getEmployee, getGroupOptions]);

  const showDrawer = () => {
    setVisible(true);
  };

  const reseAllFieldData = () => {
    form?.resetFields();
    setGroupId("");
    setMobileNumber("");
  };

  const onDrawerClose = () => {
    setVisible(undefined);
    setSelectedEmployeeForEdit(!setSelectedEmployeeForEdit);
    setSelectedEmployee(!setSelectedEmployee);
    form.resetFields();
    setRefetchState(true);
    setGroupId("");
    getEmployee();
  };

  return (
    <React.Fragment>
      <HubLayout
        corporateId={(route as any)?.corporateId as string}
        title="Employee List"
        subTitle="Employee List"
        extra={
          <>
            <Button type="dashed" onClick={showModal} className="mx-2">
              <UploadOutlined /> Upload
            </Button>
            <Button type="primary" danger onClick={showDrawer}>
              Add New
            </Button>
          </>
        }
      >
        {showSearch && (
          <div className={`${styles.searchBox}`}>
            <Form form={form} layout="inline" className={styles.formInline}>
              <Form.Item name="group_search">
                <Select
                  allowClear
                  autoClearSearchValue
                  showSearch
                  placeholder="Filter by Group"
                  optionFilterProp="children"
                  onChange={(val) => setGroupId(val)}
                  onSearch={(e) => handleSearch(e, "group")}
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {groupOptions?.list?.map((group) => (
                    <Option value={group.value}>{group.label}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="phone_search" initialValue={mobileNumber}>
                <AutoComplete
                  style={{ width: 250 }}
                  onSearch={(e) => handleSearch(e, "employee")}
                  onSelect={(val) => setMobileNumber(val.toString())}
                  options={employeesOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    employeesOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="large"
                    placeholder="Search by Phone"
                    onSearch={(val) => setMobileNumber(val)}
                    enterButton
                    loading={employeesOptions.loading}
                    maxLength={11}
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </AutoComplete>
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    {employeesData?.loading ? (
                      <Loader />
                    ) : (
                      <table className={styles.mainTable}>
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Contact Number</th>
                            <th scope="col">Email Address</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {employeesData?.data?.employees?.length ? (
                            employeesData?.data?.employees?.map(
                              (employee: any, index: any) => (
                                <tr
                                  className="border-t hover:bg-gray-100 cursor-pointer"
                                  key={index}
                                  onClick={() => setSelectedEmployee(employee)}
                                >
                                  <td>
                                    <span className="name">
                                      {employee?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="name">
                                      {employee?.mobileNumber?.split("+88")}
                                    </span>
                                  </td>

                                  <td>
                                    <span className="name">
                                      {employee?.emailAddress}
                                    </span>
                                  </td>

                                  <td>
                                    {" "}
                                    <Switch
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      defaultChecked={employee?.isActive}
                                      onChange={(val, e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onStatusChange(employee?.id, val);
                                      }}
                                      loading={
                                        confirmLoading &&
                                        employee?.id === confirmLoading
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      to=""
                                      className="btn btn-sm btn-warning mx-1"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedEmployeeForEdit(employee);
                                      }}
                                    >
                                      <EditOutlined />
                                    </Link>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <Empty />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <Pagination
                {...employeesData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </div>
          <Drawer
            destroyOnClose={true}
            title="Add Employee"
            width={600}
            onClose={onDrawerClose}
            visible={!!visible}
            bodyStyle={{ paddingBottom: 0 }}
            footer={
              <div
                style={{
                  textAlign: "left",
                }}
              ></div>
            }
          >
            <AddEmployee onCloseMethod={onDrawerClose} />
          </Drawer>

          <Drawer
            title={
              selectedEmployeeForEdit ? "Edit Employee" : "Employee Details"
            }
            width={500}
            onClose={onDrawerClose}
            visible={selectedEmployee || selectedEmployeeForEdit}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            {selectedEmployeeForEdit ? (
              <AddEmployee
                visibleData={selectedEmployeeForEdit}
                onCloseMethod={onDrawerClose}
              />
            ) : (
              <EmployeeDetails EmployeeDetails={selectedEmployee} />
            )}
          </Drawer>

          <Modal
            centered
            title="Upload Employee"
            visible={isModalVisible}
            okButtonProps={{
              disabled: loading || !fetchedCSVData?.employees?.length,
              loading: (loading ? "loading" : undefined) as any,
            }}
            onOk={form.submit}
            okText="Upload"
            onCancel={() => {
              handleCancel();
              resetData();
              setGroupId("");
            }}
          >
            <Form layout="vertical" onFinish={onSubmit} form={form}>
              <Form.Item
                hasFeedback
                label="Employee Group"
                // requiredMark="optional"
                name="groupId"
              >
                <Select
                  showSearch
                  placeholder="Select Group"
                  optionFilterProp="children"
                  onChange={(val) => setGroupId(val)}
                  onSearch={(e) => {
                    console.log(e);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {groupOptions?.list?.map((group) => (
                    <Option value={group.value}>{group.label}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                hasFeedback
                label=""
                name="upload"
                rules={[
                  {
                    required: true,
                    message: "File is required",
                  },
                ]}
              >
                <Upload onChange={readUploadFile}>
                  <Button
                    type="dashed"
                    size="large"
                    shape="round"
                    danger
                    htmlType="button"
                    icon={<UploadOutlined />}
                    disabled={!groupId}
                  >
                    Upload Employees (.csv)
                  </Button>
                </Upload>
              </Form.Item>

              {fetchedCSVData?.employees?.length >= 0 && (
                <div className="mb-2 mt-4 txt-red accent-txt">
                  Uploaded {fetchedCSVData?.employees?.length} Employees
                </div>
              )}
            </Form>

            <Button
              type="dashed"
              shape="round"
              danger
              onClick={simpleFileDownload}
            >
              <DownloadOutlined />
              Sample File
            </Button>
          </Modal>
        </div>
      </HubLayout>
    </React.Fragment>
  );
};

export default CorporateEmployeeList;
