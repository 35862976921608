import MainLayout from "../../components/Layouts";
import ContentList from "../../components/Content";

const ContentPage = () => {
  return (
    <MainLayout>
      <ContentList />
    </MainLayout>
  );
};

export default ContentPage;
