import ServiceLocationList from "../../components/Delivery/ServiceLocation";
import MainLayout from "../../components/Layouts";

function ServiceLocationPage() {
  return (
    <MainLayout>
      <ServiceLocationList />
    </MainLayout>
  );
}

export default ServiceLocationPage;
