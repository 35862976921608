import ServiceLocationList from "../../components/Delivery/ServiceLocation";
import ServiceZoneList from "../../components/Delivery/ServiceZone";
import MainLayout from "../../components/Layouts";

function ServiceZonePage() {
  return (
    <MainLayout>
      <ServiceZoneList />
    </MainLayout>
  );
}

export default ServiceZonePage;
