import { LeftCircleOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";

const DriverHeader = ({ rightarea, leftarea }: any): ReactElement => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="title d-flex justify-content-between border-bottom">
        <h5 className="mb-0">
          <Link
            to={``}
            className="mr-2 text-dark"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <LeftCircleOutlined />
          </Link>
          {leftarea}
        </h5>
        {rightarea}
      </div>
    </React.Fragment>
  );
};

export default DriverHeader;
