import { Avatar, Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { getImgUrl } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
const { Content } = Layout;

const CorporateLayout = ({
  children,
  corporateId,
  title,
  extra,
  subTitle,
}: {
  children?: ReactElement;
  corporateId: string;
  title: string;
  subTitle?: string;
  extra?: ReactElement;
}): ReactElement => {
  const navigate = useNavigate();
  const { corporateInfo } = useSelector((state) => (state as any)?.authReducer);

  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-3 px-2 pt-4 xl:grid-cols-3 gap-4 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={extra}
              />
              <nav className="flex mb-0" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                  <li className="inline-flex items-center">
                    <Link
                      to="/corporate"
                      className="inline-flex items-center text-gray-700 hover:text-gray-900"
                    >
                      Corporate
                    </Link>
                  </li>
                  {/* <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <Link
                        to="#"
                        className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2"
                      >
                        Driver
                      </Link>
                    </div>
                  </li> */}
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span
                        className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                        aria-current="page"
                      >
                        {subTitle}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-span-full xl:col-auto grid grid-cols-2 md:grid-cols-2 gap-6 xl:block">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl ">
                <div className="sm:flex__ xl:block sm:space-x-4 xl:space-x-0">
                  <img
                    src={getImgUrl(corporateInfo?.image)}
                    alt={corporateInfo?.name}
                    className="rounded-t-xl object-cover"
                  />
                  <div className="px-4 pb-2">
                    <div className="-mt-12 xl:-mt-14">
                      <Avatar
                        size={105}
                        shape="circle"
                        src={getImgUrl(corporateInfo?.image)}
                        className="mb-5 w-20 h-20 shadow-lg border-4 border-white bg-gray-50"
                      />
                    </div>
                    <div>
                      <h2 className="text-xl font-bold">
                        {corporateInfo?.name?.toUpperCase()}
                      </h2>
                      <div className="mb-4">
                        <span className="radius-btn active-btn">
                          &#2547; {corporateInfo?.balance}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {corporateInfo?.id && <Sidebar corporateId={corporateId} />}
            </div>
            <div className="col-span-3 xl:col-span-2">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>{" "}
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

CorporateLayout.propType = {
  children: PropTypes.element,
};

export default CorporateLayout;
