import { authenticateToken } from "../../utils/auth";
import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Select,
  Spin,
  Switch,
} from "antd";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddBrand from "./AddKeyword";
import { debounce } from "lodash";
import moment from "moment";
import AddKeyword from "./AddKeyword";
const PopularKeywordList = (): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState("");
  const [visible, setVisible] = useState<any>(undefined);
  const router = useLocation();
  const loc = useLocation();
  const page = getParamValue(router?.search, "page");
  const [showSearch, setShowSearch] = useState<any>(true);
  const [selectedKey, setSelectedKey] = useState<any>(undefined);
  const [selectedKeyForEdit, setSelectedKeyForEdit] = useState<any>(undefined);

  const [keywordData, setKeywordData] = useState<any>({
    loading: false,
    item: [],
  });
  const [keywordOptions, setKeywordOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getKeywords = useCallback(() => {
    try {
      setKeywordData({ loading: true, item: [] });

      const url =
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/keyword/popular/all?` +
        `page=${page || 0}` +
        `&limit=${limit || 16}` +
        (type ? `&type=${type}` : ``) +
        (key ? `&key=${key}` : ``);

      fetch(url, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setKeywordData({ loading: false, item: data });
        })
        .catch(() => {
          setKeywordData({ loading: true, item: [] });
        });
    } catch (error) {
      setKeywordData({ loading: true, item: [] });
      console.log(error, "error");
    }
  }, [limit, page, key, type]);

  const getKeywordOptions = useCallback(
    async (val) => {
      setKeywordOptions({ loading: false, list: [] });

      setKeywordOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/keyword/popular/all?page=0&limit=20` +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setKeywordOptions({
        loading: false,
        list: res?.data?.popularSearches?.map((keyword: { name: any }) => {
          return {
            value: keyword?.name,
            label: keyword?.name,
          };
        }),
      });
    },
    [status, type]
  );
  const showDrawer = () => {
    setVisible(true);
  };
  const fetchRef = useRef(0);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "keyword") getKeywordOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getKeywordOptions, type]);

  useEffect(() => {
    form.resetFields(["keyword"]);
  }, [form, type]);

  useEffect(() => {
    getKeywords();
  }, [getKeywords]);

  const reseAllFieldData = () => {
    setKey("");
    form.resetFields();
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedKeyForEdit(undefined);
    setSelectedKey(undefined);
    getKeywords();
  };
  return (
    <>
      <BreadCrumb
        title={`Popular Keyword List`}
        subTitle={`${
          keywordData?.item?.totalElements ||
          keywordData?.item?.popularSearches?.length ||
          0
        } Keyword(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <AddBtn onClick={showDrawer} />,
        ]}
      />
      <Drawer
        title="Add Popular Keyword"
        width={500}
        onClose={onClose}
        visible={!!visible}
        bodyStyle={{ paddingBottom: 0 }}
        footer={
          <div
            style={{
              textAlign: "left",
            }}
          ></div>
        }
      >
        <AddKeyword onCloseMethod={onClose} />
      </Drawer>

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="product_search" initialValue={key}>
              <AutoComplete
                onSearch={(e) => handleSearch(e, "keyword")}
                onSelect={(val) => setKey(val.toString())}
                options={keywordOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  keywordOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Keyword"
                  onSearch={(val) => {
                    setKey(val);
                  }}
                  enterButton
                  loading={keywordOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {keywordData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Keyword</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {keywordData?.item?.popularSearches?.length ? (
                      keywordData?.item?.popularSearches?.map(
                        (keyword: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">{keyword?.name}</span>
                            </td>

                            <td>
                              <span className="name">
                                {keyword?.type?.replace("_", " ")}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`radius-btn ${
                                  keyword?.isActive ? "active" : "inactive"
                                }-btn`}
                              >
                                {keyword?.isActive ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-warning mx-1"
                                onClick={() => setSelectedKeyForEdit(keyword)}
                              >
                                <EditOutlined />
                              </button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Drawer
          title={selectedKeyForEdit ? "Edit Keyword" : "Keyword Details"}
          width={selectedKeyForEdit ? 500 : 600}
          onClose={onClose}
          visible={selectedKey || selectedKeyForEdit}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            ></div>
          }
        >
          {selectedKeyForEdit ? (
            <AddKeyword
              visibleData={selectedKeyForEdit}
              onCloseMethod={onClose}
            />
          ) : (
            ""
          )}
        </Drawer>
        <Pagination
          {...keywordData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default PopularKeywordList;
