import { ReactElement } from "react";
import DriverLocationHistoryMap from "../../../components/Delivery/DeliveryHero/LocationHistory";
import MainLayout from "../../../components/Layouts";

const DriverLocationHistoryPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverLocationHistoryMap />
    </MainLayout>
  );
};

export default DriverLocationHistoryPage;
