import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import VrHistoryList from "../../components/Reports/VRHistory";
const VRHistoryPage = (): ReactElement => {
  return (
    <MainLayout>
      <VrHistoryList />
    </MainLayout>
  );
};

export default VRHistoryPage;
