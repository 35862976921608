import React, { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import EmailNotification from "../../components/Notification/email";

const EmailNotificationPage = (): ReactElement => {
  return (
    <MainLayout>
      <EmailNotification />
    </MainLayout>
  );
};

export default EmailNotificationPage;
