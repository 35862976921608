import { authenticateToken } from "../../utils/auth";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Drawer, Empty, Modal, Switch, Tree } from "antd";
import { responseNotification } from "../../utils/notify";
import AddCategory from "./AddCategory";
import SingleCategory from "./Details/SingleCategory";
import {
  ApartmentOutlined,
  BarsOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { confirm } = Modal;

interface DataNode {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: DataNode[];
}

// It's just a simple demo. You can use tree map to optimize update perf.
const updateTreeData = (
  list: DataNode[],
  key: DataNode["key"],
  children: DataNode[]
): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

const SubCategoryTreeList = ({
  parentCategoryId,
  onCloseMethod,
}: any): ReactElement => {
  const [changeParent, setChangeParent] = useState(false);
  const [key, setKey] = useState("");
  const [visible, setVisible] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryIdForEidt, setSelectedCategoryForEdit] =
    useState<any>(undefined);
  const [selectedCategoryParentId, setSelectedCategoryParentId] =
    useState<any>();

  const [selectedCategoryId, setSelectedCategoryId] = useState<any>();
  const [categoryName, setCategoryName] = useState<any>();

  const [treeData, setTreeData] = useState<any>();

  const getCategories = useCallback(async () => {
    setLoading(true);

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/category/child-category-by-parent?categoryId=${parentCategoryId}` +
          (key ? `&key=${key}` : ``),
        { 
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setTreeData(
          res.data?.categories?.map((category: any) => ({
            ...category,
            key: category?.id,
          }))
        );
      })
      .catch((err) => {
        setLoading(true);
        console.error("Category: Error", err);
      });
  }, [parentCategoryId, key, onCloseMethod]);

  const onLoadData = ({ key, children }: any) => {
    return new Promise<void>((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${key}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            if (children) {
              resolve();
              return;
            }

            setTimeout(() => {
              setTreeData((origin: DataNode[]) => {
                return updateTreeData(
                  origin,
                  key,
                  data?.data?.categories?.map((category: any) => ({
                    title: category?.title,
                    key: category?.id,
                  }))
                );
              });
              resolve();
            }, 1000);
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onConvertAsParent = async (status: any) => {
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/category`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedCategoryId,
        parent: null,
        level: 0,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Category Updated Successfully", "success");
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: `Do you want to convert this category to the Parent Category?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onConvertAsParent(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Convert",
      cancelText: "Not Sure",
    });
  }

  useEffect(() => {
    if (parentCategoryId) {
      getCategories();
    }
  }, [getCategories, parentCategoryId]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (parentCategoryId) {
      setSelectedCategoryParentId(parentCategoryId);
    }
  }, [parentCategoryId]);

  const onClose = () => {
    getCategories();
    setVisible(undefined);
    setSelectedCategoryForEdit(undefined);
    setSelectedCategoryId(undefined);
  };

  return (
    <>
      <div className="content-body-withSearch">
        {treeData?.length ? (
          <>
            <Tree
              loadData={onLoadData}
              treeData={treeData}
              onRightClick={(val) => {
                setSelectedCategoryForEdit(val?.node?.key);
                setCategoryName(val?.node?.title);
                // setVisible(val?.node?.key);
              }}
              onSelect={(val: any) => {
                setSelectedCategoryId(val[0]);
              }}
            />

            <div className="mt-8 text-red-500 text-xs">
              <span className="font-bold">NB:</span>{" "}
              <i>To Edit Category Please "Right Click" on Category Name.</i>
            </div>
          </>
        ) : (
          <Empty />
        )}
      </div>

      <Drawer
        title={
          visible
            ? "Add Category"
            : selectedCategoryIdForEidt
            ? "Edit Category"
            : "Category Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedCategoryIdForEidt || selectedCategoryId}
        width={500}
        extra={[
          selectedCategoryIdForEidt && (
            <Switch
              checkedChildren={<ApartmentOutlined />}
              unCheckedChildren={<BarsOutlined />}
              onChange={(val, e) => {
                e.preventDefault();
                e.stopPropagation();
                setChangeParent(val);
              }}
            />
          ),
          selectedCategoryId && (
            <Button
              danger
              type="primary"
              shape="round"
              onClick={(val) => showConfirm(val)}
            >
              Convert as Parent
            </Button>
          ),
        ]}
      >
        {visible || selectedCategoryIdForEidt ? (
          <AddCategory
            visibleData={selectedCategoryIdForEidt}
            onCloseMethod={onClose}
            parentCategoryId={selectedCategoryParentId}
            changeParent={changeParent}
          />
        ) : (
          <SingleCategory selectedCategoryId={selectedCategoryId} />
        )}
      </Drawer>
    </>
  );
};

export default SubCategoryTreeList;
