import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ShopLayout from "../Layout";
import ShopList from "../../../Shop";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Form, Modal, Select } from "antd";
import axios from "axios";
import { authenticateToken } from "../../../../utils/auth";
import { debounce } from "lodash";
import { responseNotification } from "../../../../utils/notify";

const CampaignBrandsList = (): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const route = useParams();
  const [loading, setLoading] = useState(false);
  const [shopId, setShopId] = useState();
  const [refetch, setRefetch] = useState(false);

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const campaignId = (route as any)?.campaignId;
  const [open, setOpen] = useState(false);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map(
              (shop: { name: string; id: string }) => ({
                label: shop.name,
                value: shop.id,
              })
            ),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopOptions]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      campaignDisplayOrder: {
        campaignId: campaignId,
        displayOrder: 0,
      },
      shopIdList: shopId || data.shopId,
    };
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign-shop`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Shop Assigned Successfully", "success");
          form.resetFields();
          setOpen(false);
          setRefetch(true);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (open) {
      getShopOptions(``);
    }
  }, [open]);

  return (
    <React.Fragment>
      <ShopLayout
        campaignId={(route as any)?.campaignId}
        title="Campaign Details"
        subTitle="Shop List"
        extra={
          <>
            <Button
              type="dashed"
              shape="round"
              className="bg-white"
              onClick={() => setOpen(true)}
            >
              Assign Shop
            </Button>
          </>
        }
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ShopList
              refetch={refetch}
              inCampaign={true}
              getCampaignId={(route as any)?.campaignId}
            />
          </div>
        </div>
      </ShopLayout>

      <Modal
        title="Assign Shop"
        centered
        open={open}
        okText="Assign Shop"
        onCancel={() => setOpen(!open)}
        width={500}
        // okButtonProps={{ htmlType: "submit" }}
        onOk={onSubmit}
      >
        <div>
          <Form
            onFinish={onSubmit}
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
            size="large"
          >
            <Form.Item name="search">
              <Select
                filterOption={() => {
                  return true;
                }}
                showSearch
                onSearch={(e) => handleSearch(e, "shop")}
                onChange={(val) => setShopId(val)}
                options={shopOptions?.list}
                loading={shopOptions?.loading}
                placeholder="Select Shop"
                mode="multiple"
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

CampaignBrandsList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignBrandsList;
