import { Button, Drawer, Empty, Form, Select, Switch } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddTimeSlot from "./AddTimeSlot";
import TimeSlotDetails from "./Details";
import moment from "moment";
import { debounce } from "lodash";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { getPage, getParamValue } from "../../../utils";
import styles from "../../../styles/tailwind/List.module.css";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
const { Option } = Select;

const TimeSlotList = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const fetchRef = useRef(0);
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [locationId, setLocationId] = useState<any>();
  const [selectedSlot, setSelectedSlot] = useState(undefined);
  const [selectedSlotForEdit, setSelectedSlotForEdit] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [slotData, setSlotData] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const reseAllFieldData = () => {
    setLocationId("");
    form.resetFields();
  };

  const getTimeSlot = useCallback(async () => {
    setSlotData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/dm-time-slot/all?` +
          (locationId ? `&serviceLocation=${locationId}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setSlotData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSlotData({ loading: false, data: null });
        console.error("Slot: Error", err);
      });
  }, [locationId, page, limit]);
  //

  const getLocationOptions = useCallback(async (val?: string) => {
    setLocationOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/service-location/all` +
        (val ? `?name=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );
    setLocationOptions({
      loading: false,
      list: res?.data?.serviceLocations?.map((location: { name: any }) => {
        return {
          value: location?.name,
          label: location?.name,
        };
      }),
    });
  }, []);

  const onDelete = async (id: string, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/dm-time-slot`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getTimeSlot();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "locations") getLocationOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getLocationOptions]);

  useEffect(() => {
    if (visible) {
      setSelectedSlot(undefined);
      setSelectedSlotForEdit(undefined);
    }
  }, [selectedSlot, selectedSlotForEdit, visible]);

  useEffect(() => {
    setSelectedSlot(undefined);
    setSelectedSlotForEdit(undefined);
  }, [visible]);

  useEffect(() => {
    getTimeSlot();
    getLocationOptions();
  }, [getTimeSlot, getLocationOptions]);

  const onClose = () => {
    setSelectedSlot(undefined);
    setSelectedSlotForEdit(undefined);
    setVisible(false);
    getTimeSlot();
  };

  return (
    <React.Fragment>
      <>
        <BreadCrumb
          title="Time Slot"
          subTitle={`${slotData?.data?.totalElements} Data(s)`}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
        />
        {showSearch && (
          <div className={styles.searchBox}>
            <Form form={form} layout="inline" className={styles.formInline}>
              <Form.Item name="location_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Location"
                  optionFilterProp="children"
                  onChange={(val) => setLocationId(val)}
                  onSearch={(e) => handleSearch(e, "locations")}
                  filterOption={() => {
                    return true;
                  }}
                >
                  {locationOptions.list.length ? (
                    <Option value={""}>All Location</Option>
                  ) : undefined}
                  {locationOptions.list.map(
                    (
                      location: {
                        value: any;
                        label:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      i: React.Key | null | undefined
                    ) => (
                      <Option value={location.value} key={i}>
                        {location.label}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}

        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {slotData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Slot</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {slotData?.data?.timeSlots?.length ? (
                        slotData?.data?.timeSlots?.map(
                          (slot: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedSlot(slot)}
                            >
                              <td>{slot?.serviceLocation}</td>
                              <td>{moment(slot?.date).format("ll")}</td>
                              <td>
                                {slot?.fromHour} - {slot?.toHour}
                              </td>

                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={slot?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDelete(slot?.id, val);
                                  }}
                                  loading={
                                    confirmLoading &&
                                    slot?.id === confirmLoading
                                  }
                                />
                              </td>
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(slot?.updatedAt, "minutes") >=
                                    60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(slot?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(slot?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <Button
                                  type="dashed"
                                  shape="round"
                                  onClick={() => setSelectedSlotForEdit(slot)}
                                >
                                  <EditOutlined />
                                </Button>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <Pagination
            {...slotData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>

        <Drawer
          title={
            visible
              ? "Add Slot"
              : selectedSlotForEdit
              ? "Edit Slot"
              : "Slot Details"
          }
          placement="right"
          onClose={onClose}
          visible={visible || selectedSlot || selectedSlotForEdit}
          width={450}
        >
          {visible || selectedSlotForEdit ? (
            <AddTimeSlot
              visibleData={selectedSlotForEdit}
              onCloseMethod={onClose}
            />
          ) : (
            <TimeSlotDetails TimeSlotDetails={selectedSlot} />
          )}
        </Drawer>
      </>
    </React.Fragment>
  );
};

export default TimeSlotList;
