import { Button, Form, Input, Select, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../FileManager/upload";
import { regions } from "../common/Region";
import { useSelector } from "react-redux";

const AddCorporate = ({ visibleData, onCloseMethod }: any) => {
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >(undefined);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(undefined);
  const [singleCorporateInfo, setSingleCorporateInfo] =
    useState<any>(undefined);

  // ******************************
  const fetchCorporateDetails = async (visibleData: any) => {
    if (visibleData) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/company/details?companyId=${visibleData}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            setSingleCorporateInfo(res?.company);
            setGeneratedImgUrl(res?.company?.image);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onSubmit = useCallback(
    async (data) => {
      if (generatedImgUrl) {
        setLoading(true);

        const readyData = data && {
          name: data.name,
          type: type,
          city: data.city,
          address: data.address,
          street: data.street,
          industry: data.industry,
          password: data.password || singleCorporateInfo?.password,
          reasonForChoosing: data.reasonForChoosing,
          spokesmanEmail: data.spokesmanEmail,
          spokesmanMobileNumber: `+88${data.spokesmanMobileNumber}`,
          spokesmanName: data.spokesmanName,
          numberOfEmployee: parseInt(data.numberOfEmployee) || 0,
          isActive: data.isActive || false,
          image: generatedImgUrl ? generatedImgUrl : data?.image,
        };

        if (singleCorporateInfo) {
          await fetch(`${process.env.REACT_APP_RIDER_API}/company`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: singleCorporateInfo?.id,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              setLoading(false);

              if (res.statusCode === 200) {
                responseNotification("Company Updated Successfully", "success");
                form.resetFields();
                if (onCloseMethod) {
                  onCloseMethod();
                }
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(
                  res.message || "something wrong",
                  "warning"
                );
              }
            })
            .catch((err) => {
              setLoading(false);
              responseNotification(
                `${"Internal server error"} ${err}`,
                "error"
              );
              console.error("err", err);
            });
        } else {
          await fetch(
            `${process.env.REACT_APP_RIDER_API}/company/registration`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(readyData),
            }
          )
            .then((res) => res.json())
            .then((res) => {
              setLoading(false);

              if (res.statusCode === 200) {
                responseNotification("Company Create Successfully", "success");
                form.resetFields();
                if (onCloseMethod) {
                  onCloseMethod();
                }
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(
                  res.message || "something wrong",
                  "warning"
                );
              }
            })
            .catch((err) => {
              setLoading(false);
              responseNotification(
                `${"Internal server error"} ${err}`,
                "error"
              );
              console.error("err", err);
            });
        }
      } else {
        responseNotification(`Image required`, "error");
      }
    },
    [generatedImgUrl, form]
  );

  useEffect(() => {
    if (singleCorporateInfo) {
      setGeneratedImgUrl(singleCorporateInfo?.image);
      form.resetFields();
    }
  }, [form, singleCorporateInfo]);

  useEffect(() => {
    if (visibleData) {
      fetchCorporateDetails(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);
  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...singleCorporateInfo,
            spokesmanMobileNumber:
              singleCorporateInfo?.spokesmanMobileNumber?.replace("+88", ""),
            type: singleCorporateInfo?.type,
          }}
        >
          <Form.Item
            hasFeedback
            label="Industry"
            rules={[
              {
                required: true,
                message: "Industry is Required!",
              },
            ]}
            name="industry"
          >
            <Input id="industry" type="text" placeholder="Enter Industry" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Company Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Company Name" />
          </Form.Item>

          {!singleCorporateInfo && (
            <Form.Item
              hasFeedback
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is Required!",
                },
              ]}
              name="password"
            >
              <Input
                id="password"
                type="password"
                placeholder="Enter Password"
              />
            </Form.Item>
          )}

          <Form.Item
            hasFeedback
            label="Number Of Employee"
            rules={[
              {
                required: true,
                message: "Number Of Employee is Required!",
              },
              {
                validator: async (_, name) => {
                  if (name && !(parseFloat(name) > 0)) {
                    return Promise.reject(new Error("Invalid Number"));
                  }
                },
              },
            ]}
            name="numberOfEmployee"
          >
            <Input
              id="numberOfEmployee"
              type="number"
              placeholder="Enter Number Of Employee"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="City"
            rules={[
              {
                required: true,
                message: "City is required!",
              },
            ]}
            name="city"
          >
            <Select
              showSearch
              placeholder="Filter by Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.values(regions.City)?.map((city, i) => (
                <Select.Option value={city} key={i}>
                  {city}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Address"
            rules={[
              {
                required: true,
                message: "Company Address is Required!",
              },
            ]}
            name="address"
          >
            <Input
              id="address"
              type="text"
              placeholder="Enter Company Address"
            />
          </Form.Item>

          <Form.Item hasFeedback label="Street" name="street">
            <Input id="street" type="text" placeholder="Enter Street" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Reason For Choosing"
            name="reasonForChoosing"
          >
            <Input
              id="reasonForChoosing"
              type="text"
              placeholder="Reason For Choosing"
            />
          </Form.Item>

          <Form.Item hasFeedback label="Spokes Man Name" name="spokesmanName">
            <Input
              id="spokesmanName"
              type="text"
              placeholder="Spokes Man Name"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Spokes Man Email"
            rules={[
              {
                required: true,
                message: "Email is required!",
              },
              {
                validator: async (_, email) => {
                  if (
                    email &&
                    !String(email)
                      .toLowerCase()
                      ?.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                  ) {
                    return Promise.reject(new Error("Enter valid email"));
                  }
                },
              },
            ]}
            name="spokesmanEmail"
          >
            <Input
              id="spokesmanEmail"
              type="email"
              placeholder="Spokes Man Email"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Spokesman Mobile Number"
            rules={[
              {
                required: true,
                message: "Mobile Number is required!",
              },
              {
                validator: async (_, names) => {
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid mobile number")
                    );
                  }
                },
              },
            ]}
            name="spokesmanMobileNumber"
            initialValue={"01"}
          >
            <Input
              disabled={singleCorporateInfo}
              id="spokesmanMobileNumber"
              type="contact"
              addonBefore={"+88"}
              placeholder="Enter Mobile Number"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Status"
            name="isActive"
            style={{ minWidth: "100%" }}
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={singleCorporateInfo?.isActive}
              onChange={setStatus}
            />
          </Form.Item>

          <h4 className="title" style={{ maxWidth: "99%" }}>
            Image:
          </h4>
          <div className="">
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            />
          </div>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
              onClick={(e) => {
                e.preventDefault();
                setStartUpload("Uploading");
              }}
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
                setGeneratedImgUrl(undefined);
                setStartUpload(undefined);
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddCorporate;
