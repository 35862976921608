import MainLayout from "../../components/Layouts";
import AddProduct from "../../components/Product/AddProduct";

const ProductAddPage = () => {
  return (
    <MainLayout>
      <AddProduct />
    </MainLayout>
  );
};

export default ProductAddPage;
