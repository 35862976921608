import VehicleTypeList from "../../components/Delivery/VehicleType";
import MainLayout from "../../components/Layouts";

function VehicleTypePage() {
  return (
    <MainLayout>
      <VehicleTypeList />
    </MainLayout>
  );
}

export default VehicleTypePage;
