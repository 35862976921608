import React from "react";
import MainLayout from "../../components/Layouts";
import NeighborhoodViewAll from "../../components/Neighborhood/NieghborhoodViewAll";

const NeighorhoodViewAllPage = () => {
  return (
    <MainLayout>
      <NeighborhoodViewAll />
    </MainLayout>
  );
};

export default NeighorhoodViewAllPage;
