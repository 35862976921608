import React from "react";
import MainLayout from "../../components/Layouts";
import ProductKeywordList from "../../components/ProductKeyword";

const ProductKeywordPage = () => {
  return (
    <MainLayout>
      <ProductKeywordList />
    </MainLayout>
  );
};

export default ProductKeywordPage;
