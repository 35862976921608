import React from "react";
import MainLayout from "../../components/Layouts";
import NeighborhoodDetailsView from "../../components/Neighborhood/NeighborhoodDetailsView";

const NeighorhoodViewPage = () => {
  return (
    <MainLayout>
      <NeighborhoodDetailsView />
    </MainLayout>
  );
};

export default NeighorhoodViewPage;
