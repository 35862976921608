import React, { useState, useCallback, useEffect } from "react";
import { Button, Form, Input, Select, Checkbox } from "antd";
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Polygon,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { SubmitResetBtn } from "../common";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { authenticateToken } from "../../utils/auth";
import {
  getParamValue,
  checkStatesAndPushToHistory,
  jcTypeArray,
} from "../../utils/index";
import DriverMarker from "../../images/icon/DriverMarker.svg";
import OrderMarker from "../../images/icon/OrderMarker.svg";
import styles from "../../styles/tailwind/List.module.css";
import ShopMarker from "../../images/icon/ShopMarker.svg";

export default function AreaMap() {
  const navigate = useNavigate();
  const [path, setPath] = useState([]);
  const { neighborhoodId } = useParams();
  const { shopType } = useParams();
  const { type } = useSelector((state) => state?.authReducer);
  const [processingOrder, setProcessingOrder] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [shopShow, setShopShow] = useState(true);
  const [onlineOrderShow, setOnlineOrderShow] = useState(false);
  const [allOnlineVehicle, setAllOnlineVehicle] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [draw, setDraw] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [sendPoints, setSendPoints] = useState();
  const [onlineVehicle, setOnlineVehicle] = useState([]);
  const [neighborhoodTitle, setNeighborhoodTitle] = useState([]);
  const router = useLocation();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [neighborhoodData, setNeighborhoodData] = useState({
    loading: false,
    data: null,
  });

  console.log("get all neighborhood ::", path);
  const APIKey = "AIzaSyDYp1te-bQEhWE9P9yehRE3biB7LpSEh4U";
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKey,
    libraries: ["drawing"],
  });
  const center = {
    lat: 23.773414356724892,
    lng: 90.41340705259854,
  };
  const containerStyle = {
    width: "100%",
    height: "70vh",
  };

  const coordTest = [
    {
      lat: 23.836597966262254,
      lng: 90.44670935972748,
    },
    {
      lat: 23.785713851052254,
      lng: 90.50541755064545,
    },
    {
      lat: 23.772204225879477,
      lng: 90.45494910582123,
    },
    {
      lat: 23.83094516066324,
      lng: 90.40825721128998,
    },
    {
      lat: 23.836597966262254,
      lng: 90.44670935972748,
    },
  ];
  const shopActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const orderActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const deliveryBoyActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  setTimeout(() => {
    if (isLoaded === true) {
      setDraw(true);
    }
  }, 1000);

  const getNeighborhood = useCallback(async () => {
    let pageReseted = false;
    setNeighborhoodData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/neighborhood/internal?neighborhoodId=${neighborhoodId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setNeighborhoodData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setNeighborhoodData({ loading: false, data: [] });
      });
  }, []);
  useEffect(() => {
    getNeighborhood();
  }, [getNeighborhood]);
  const fetchPolygons = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_CATALOG_READER_API}/neighborhood/internal?neighborhoodId=${neighborhoodId}`,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    const response = await data.json();
    setPath(
      response.neighborhood.points.map((point) => ({
        lat: point.latitude,
        lng: point.longitude,
      }))
    );
    setNeighborhoodTitle(response.neighborhood);
  };
  const neighborhoodLastItem = path[path.length - 4];
  const position = {
    lat: neighborhoodLastItem?.lat,
    lng: neighborhoodLastItem?.lng,
  };
  const shopListWithNeighborhood = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/by-neighborhood?neighborhoodId=${neighborhoodId}&type=${shopType}`,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    const shopResponse = await data.json();
    setShopList(shopResponse.shops);
  };
  const orderProcessingWithNeighborhood = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/order/processing-order-by-neighborhood?neighborhoodId=${neighborhoodId}&type=${shopType}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );
    const orderResponse = await data.json();
    setProcessingOrder(orderResponse.orders);
  };
  const onlineVehicleWithNeighborhood = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_RIDER_API}/vehicle/all-online?neighborhoodId=${neighborhoodId}`,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    const vehicleResponse = await data.json();
    setOnlineVehicle(vehicleResponse.vehicleLocationList);
  };
  useEffect(() => {
    fetchPolygons();
    shopListWithNeighborhood();
    orderProcessingWithNeighborhood();
    onlineVehicleWithNeighborhood();
  }, []);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        {showSearch && (
          <div className={styles?.searchBox}>
            <div className="float-right">
              <Checkbox
                type="checkbox"
                defaultChecked={true}
                onChange={(val) => setShopShow(val.target.checked)}
              >
                Shop List
              </Checkbox>
              <Checkbox
                onChange={(val) => setOnlineOrderShow(val.target.checked)}
              >
                Processing Order
              </Checkbox>
              <Checkbox
                onChange={(val) => setAllOnlineVehicle(val.target.checked)}
              >
                Online Vehicle
              </Checkbox>
            </div>
          </div>
        )}

        <div className="bg-white p-3">
          <div className="details-area" style={{ minHeight: 650 }}>
            <div className="mb-4">
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={12}
                  onClick={(e) => console.log(e)}
                >
                  <Polygon
                    path={neighborhoodData?.polygon?.points?.map((point) => ({
                      lat: point?.latitude,
                      lng: point?.longitude,
                    }))}
                    options={{
                      strokeColor: "red",
                      strokeOpacity: 0.75,
                      strokeWeight: 2,
                      fillColor: "rgb(185 153 255 / 44%)",
                      fillOpacity: 0.6,
                      icons: [
                        {
                          // icon: lineSymbol,
                          offset: "0",
                          repeat: "20px",
                        },
                      ],
                    }}
                  />

                  {shopShow &&
                    shopList?.map((shop) => (
                      <Marker
                        icon={ShopMarker}
                        title={shop?.name}
                        position={{
                          lat: shop?.location?.y,
                          lng: shop?.location?.x,
                        }}
                        onClick={(e) => {
                          window.open(`/shops/${shop?.id}/details`, `_blank`);
                        }}
                      ></Marker>
                    ))}
                  {onlineOrderShow &&
                    processingOrder?.map((order) => (
                      <Marker
                        icon={OrderMarker}
                        title={order?.status}
                        position={{
                          lat: order?.shippingLocation?.latitude,
                          lng: order?.shippingLocation?.longitude,
                        }}
                        onClick={() => orderActiveMarker(order?.orderId)}
                      >
                        {activeMarker === order?.orderId ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div className="inner-body-wrapper">
                              <div className="inner-body">
                                <div className="single">
                                  <div className="info-name">
                                    <span>Order ID</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.orderId}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Base Order ID</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.baseOrderId}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Status</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.status}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Sub Total</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.subTotal}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Total</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.total}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Payment Method</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.paymentMethod}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>TokenNumber</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.tokenNumber}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Delivery Man ID</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.deliveryMan?.id}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Delivery Man Name</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{order?.deliveryMan?.name}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Delivery Man Mobile Number</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>
                                      <a href="tel:{order?.deliveryMan?.mobileNumber}">
                                        {order?.deliveryMan?.mobileNumber}
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    ))}
                  {allOnlineVehicle &&
                    onlineVehicle?.map((vehicle) => (
                      <Marker
                        icon={DriverMarker}
                        title={vehicle?.driver?.mobileNumber}
                        position={{
                          lat: vehicle?.location?.y,
                          lng: vehicle?.location?.x,
                        }}
                        onClick={() => deliveryBoyActiveMarker(vehicle?.id)}
                      >
                        {activeMarker === vehicle?.id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div className="inner-body-wrapper">
                              <div className="inner-body">
                                <div className="single">
                                  <div className="info-name">
                                    <span>Driver ID</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{vehicle?.driverId}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Driver Name</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{vehicle?.driver?.name}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Driver Mobile Number</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>
                                      <a href="tel:{vehicle?.driver?.mobileNumber}">
                                        {vehicle?.driver?.mobileNumber}
                                      </a>
                                    </span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Status</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{vehicle?.status}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Vehicle Type</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{vehicle?.vehicleType}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    ))}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
