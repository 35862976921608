import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import moment from "moment";
import SubmitResetBtn from "../../common/SubmitBtn";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const AddCampaign = ({ visibleData, onCloseMethod }: any) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [appsImgUrl, setAppsImgUrl] = useState<string>();
  const [webImgUrl, setWebImgUrl] = useState<string>();
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [isFlashSale, setIsFlashSale] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [error, setError] = useState("");
  const [campaignData, setCampaignData] = useState<any>(undefined);

  const [campaignOptions, setCampainsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getCampaignsOptions = useCallback(async () => {
    setCampainsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/campaign/all` +
        (type ? `?type=${type}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );

    setCampainsOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: { id: any; name: any }) => {
        return {
          value: campaign?.id,
          label: campaign?.name,
        };
      }),
    });
  }, [type]);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (appsImgUrl || webImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.name,
        banner: appsImgUrl ? appsImgUrl : data?.banner,
        bannerWeb: webImgUrl ? webImgUrl : data?.bannerWeb,
        description: data.description,
        campaign: data.campaign,
        colorCode: data.colorCode,
        displayOrder: data.displayOrder,
        startAt: data.startAt,
        endAt: data.endAt,
        status: data.status,
        minimumDiscount: parseFloat(data.minimumDiscount),
        minimumDiscountType: data.minimumDiscountType,
        type: type || data?.type,
        isFlashSale: isFlashSale || data.isFlashSale,
      };

      if (campaignData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: campaignData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Campaign Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
            status: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("campaign Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    getCampaignsOptions();
  }, [getCampaignsOptions]);

  useEffect(() => {
    if (visibleData) {
      setCampaignData(visibleData);
      setAppsImgUrl(visibleData?.banner);
      setWebImgUrl(visibleData?.bannerWeb);
      setFromDate(visibleData?.startAt);
    }
  }, [form, visibleData]);

  useEffect(() => {
    if (campaignData) {
      form.resetFields(Object.keys(visibleData || ""));
      setAppsImgUrl(visibleData?.banner);
      setWebImgUrl(visibleData?.bannerWeb);
    }
  }, [campaignData, visibleData]);

  const resetData = () => {
    form?.resetFields();
    setAppsImgUrl(undefined);
    setWebImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...campaignData,
            startAt: moment(campaignData?.startAt),
            endAt: moment(campaignData?.endAt),
            type: type || campaignData?.type,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div>
              <Form.Item
                hasFeedback
                label="Campaign Type"
                rules={[
                  {
                    required: true,
                    message: "Type is Required!",
                  },
                ]}
                name="isFlashSale"
                initialValue={isFlashSale || false}
              >
                <Radio.Group
                  onChange={(val) => setIsFlashSale((val as any).target.value)}
                  value={isFlashSale || false}
                  defaultValue={isFlashSale || false}
                >
                  <Space direction="horizontal">
                    <Radio defaultChecked value={false}>
                      Regular
                    </Radio>
                    <Radio value={true}>Flash Sale</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Campaign Type"
                rules={[
                  {
                    required: false,
                    message: "Campaign Type is Required!",
                  },
                ]}
                name="campaignType"
                initialValue="DISCOUNT"
              >
                <Select
                  options={[{ value: "DISCOUNT", label: "DISCOUNT" }]}
                  placeholder="Enter Campaign Type"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item hasFeedback label="Color Code" name="colorCode">
                <Input
                  id="colorCode"
                  type="color"
                  placeholder="Enter Color Code"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Campaign Title is Required!",
                  },
                ]}
                name="name"
              >
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter Campaign Title"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item hasFeedback label="Position" name="displayOrder">
                <Input
                  id="displayOrder"
                  type="number"
                  placeholder="Enter Campaign Position"
                  min={0}
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Starting Date"
                rules={[
                  {
                    required: true,
                    message: "Starting Date is Required!",
                  },
                ]}
                name="startAt"
              >
                <DatePicker
                  showTime
                  id="startAt"
                  placeholder="Enter Starting Date"
                  style={{ minWidth: "100%" }}
                  onChange={(val: any) => setFromDate(val?.format("lll"))}
                  onSelect={(val) => setFromDate(val?.format("lll"))}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Ending Date"
                rules={[
                  {
                    required: true,
                    message: "Ending Date is Required!",
                  },
                ]}
                name="endAt"
              >
                <DatePicker
                  showTime
                  id="endAt"
                  placeholder="Enter Ending Date"
                  style={{ minWidth: "100%" }}
                  onChange={(val: any) => setToDate(val?.format("lll"))}
                  disabled={!fromDate}
                  disabledDate={(currentDate) =>
                    currentDate.isBefore(moment(fromDate))
                  }
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Discount Type"
                rules={[
                  {
                    required: false,
                    message: "Discount Type is Required!",
                  },
                ]}
                name="minimumDiscountType"
                initialValue="PERCENTAGE"
              >
                <Select
                  options={[
                    { value: "PERCENTAGE", label: "PERCENTAGE" },
                    { value: "FLAT", label: "FLAT" },
                  ]}
                  placeholder="Enter Discount Type"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Minimum Discount"
                rules={[
                  {
                    required: false,
                    message: "Minimum Discount is Required!",
                  },
                ]}
                name="minimumDiscount"
              >
                <Input
                  id="minimumDiscount"
                  type="number"
                  min={0}
                  placeholder="Enter Minimum Discount"
                />
              </Form.Item>
            </div>

            <div className="col-span-2">
              <Form.Item
                hasFeedback
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Description is Required!",
                  },
                ]}
                name="description"
              >
                <TextArea
                  id="description"
                  placeholder="Enter Campaign Description"
                  rows={3}
                />
              </Form.Item>
            </div>
            <div></div>
          </div>

          <Row gutter={20} className="mb-6">
            <Col span={12}>
              <Divider orientation="left">
                Apps Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 500px/180px (Ratio 2.8)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={appsImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setAppsImgUrl(url)}
                // crop={2.8}
              />
            </Col>
            <Col span={12}>
              <Divider orientation="left">
                Web Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 1200px/750px (Ratio 1.6)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                // crop={1.6}
                imgUrl={webImgUrl || ""}
                startUpload={startUpload2}
                setStartUpload={(val: any) => setStartUpload2(val)}
                setGeneratedImgUrl={(url: any) => setWebImgUrl(url)}
              />
            </Col>
          </Row>

          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        </Form>
      </div>
    </div>
  );
};

export default AddCampaign;
