import React from "react";
import MainLayout from "../../components/Layouts";
import FeedbackDetails from "../../components/Feedback/Details";

const FeedbackDetailsPage = () => {
  return (
    <MainLayout>
      <FeedbackDetails />
    </MainLayout>
  );
};

export default FeedbackDetailsPage;
