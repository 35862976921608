import { authenticateToken } from "../../utils/auth";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleBanner from "./Details";
import AddBanner from "./AddTag"; 
import moment from "moment";
import React from "react";
const BannerList = (): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  //const [type, setType] = useState("JC_FOOD");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [selectedTagForEdit, setSelectedTagForEdit] = useState(undefined);

  const [tagData, setTagData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTags = useCallback(async () => {
    setTagData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(`${encodedUri}/tag?type=${type}`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
      .then((res) => {
        setTagData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTagData({ loading: false, data: [] });
        console.error("Tags: Error", err);
      });
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const onClose = () => {
    getTags();
    setVisible(undefined);
    setSelectedTag(undefined);
    setSelectedTagForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Tag List"
        subTitle={`${
          tagData?.data?.totalElements || tagData?.data?.tags?.length || 0
        } Tag(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {tagData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tagData?.data?.tags?.length ? (
                      tagData?.data?.tags?.map((tag: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedTag(tag)}
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {tag?.name}
                            </span>
                          </td>

                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(tag?.updatedAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(tag?.updatedAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(tag?.updatedAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {tag?.updatedBy?.name ||
                                tag?.updatedBy?.mobileNumber}
                            </span>
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={() => setSelectedTagForEdit(tag)}
                            >
                              <EditOutlined />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...tagData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible ? "Add Tag" : selectedTagForEdit ? "Edit Tag" : "Tag Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedTag || selectedTagForEdit}
        width={450}
      >
        {visible || selectedTagForEdit ? (
          <AddBanner onCloseMethod={onClose} visibleData={selectedTagForEdit} />
        ) : (
          <SingleBanner BannerDetails={selectedTag} />
        )}
      </Drawer>
    </>
  );
};

export default BannerList;
