import React from "react";
import MainLayout from "../../components/Layouts";
import Neighborhood from "../../components/Neighborhood";

const NeighborhoodPage = () => {
  return (
    <MainLayout>
      <Neighborhood />
    </MainLayout>
  );
};

export default NeighborhoodPage;
