import { Rate, Avatar, Tabs, Empty, Divider, Steps, Image } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { getImgUrl } from "../../../utils";
import moment from "moment";
const { TabPane } = Tabs;
const { Step } = Steps;

const SingleRequestedProduct = ({
  ProductDetails,
  inShop = false,
}: {
  ProductDetails: any;
  inShop?: boolean;
}): ReactElement => {
  return (
    <>
      <div>
        <div>
          <h2 className="text-lg font-bold mb-4">
            {ProductDetails?.name?.toUpperCase()}
          </h2>
          <div className="">
            <div className="flex gap-4">
              <div className="">
                <h3 className="text-md font-medium mb-4">Thumbnail</h3>
                <Avatar
                  src={<Image src={getImgUrl(ProductDetails?.productImage)} />}
                  size={100}
                  shape="square"
                  className="rounded-lg shadow-md"
                />
              </div>

              {!!ProductDetails?.images?.length && (
                <div className="flow-root">
                  <h3 className="text-md font-medium mb-4">Images</h3>

                  <div className="flex flex-wrap gap-4">
                    {ProductDetails?.images?.map(
                      (image: any, index: string) => (
                        <div key={index}>
                          <Avatar
                            src={image}
                            size={100}
                            shape="square"
                            className="rounded-lg shadow-md"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="bg-white p-4 mb-6">
            <Tabs tabPosition="top">
              <TabPane tab="General information" key="General">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="col-span-1">
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Product Name
                    </dt>
                    <dd className="text-sm font-medium text-gray-800">
                      {ProductDetails?.name?.trim()}
                    </dd>
                  </div>
                  <div className="">
                    <dt className="text-sm font-medium text-gray-900 mb-1">
                      Slug
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      {ProductDetails?.slug}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Product ID
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      {ProductDetails?.id}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Barcode
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      {ProductDetails?.barCode}
                    </dd>
                  </div>
                  {ProductDetails?.sku && (
                    <div>
                      <dt className="text-sm font-medium text-gray-800 mb-1">
                        SKU
                      </dt>
                      <dd className="text-sm font-medium text-gray-500">
                        {ProductDetails?.sku}
                      </dd>
                    </div>
                  )}
                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Category:
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <Avatar src={ProductDetails?.category?.image} />{" "}
                      {ProductDetails?.category?.title}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Category Slug:
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      {ProductDetails?.category?.slug}
                    </dd>
                  </div>

                  {ProductDetails?.brand && (
                    <div>
                      <dt className="text-sm font-medium text-gray-800 mb-1">
                        Brand:
                      </dt>
                      <dd className="text-sm font-medium text-gray-500">
                        <Avatar src={ProductDetails?.brand?.logo} />{" "}
                        {ProductDetails?.brand?.name}
                      </dd>
                    </div>
                  )}

                  {ProductDetails?.campaign && (
                    <div>
                      <dt className="text-sm font-medium text-gray-800 mb-1">
                        Campaign:
                      </dt>
                      <dd className="text-sm font-medium text-gray-500">
                        <Avatar src={ProductDetails?.campaign?.logo} />{" "}
                        {ProductDetails?.campaign?.name}
                      </dd>
                    </div>
                  )}

                  {ProductDetails?.city && (
                    <div>
                      <dt className="text-sm font-medium text-gray-800 mb-1">
                        City
                      </dt>
                      <dd className="text-sm font-medium text-gray-500">
                        {ProductDetails?.city}
                      </dd>
                    </div>
                  )}

                  {ProductDetails?.zone && (
                    <div>
                      <dt className="text-sm font-medium text-gray-800 mb-1">
                        Zone
                      </dt>
                      <dd className="text-sm font-medium text-gray-500">
                        {ProductDetails?.zone}
                      </dd>
                    </div>
                  )}

                  {ProductDetails?.type && (
                    <div>
                      <dt className="text-sm font-medium text-gray-800 mb-1">
                        Type
                      </dt>
                      <dd className="text-sm font-medium text-gray-500">
                        {ProductDetails?.type}
                      </dd>
                    </div>
                  )}

                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Rattings
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <Rate value={ProductDetails?.rating || 1} disabled />
                      <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                        {ProductDetails?.numberOfRating || 0}
                      </span>
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Allow Warranty?
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full bg-${
                            ProductDetails?.isAllowWarranty ? "green" : "red"
                          }-500 mr-2`}
                        ></div>{" "}
                        {ProductDetails?.isAllowWarranty
                          ? "Active"
                          : "Inactive"}
                      </div>
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Is Returnable?
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full bg-${
                            ProductDetails?.isReturnable ? "green" : "red"
                          }-500 mr-2`}
                        ></div>{" "}
                        {ProductDetails?.isReturnable ? "Active" : "Inactive"}
                      </div>
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Exclude From Promo
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full bg-${
                            ProductDetails?.excludeFromPromo ? "green" : "red"
                          }-500 mr-2`}
                        ></div>{" "}
                        {ProductDetails?.excludeFromPromo
                          ? "Active"
                          : "Inactive"}
                      </div>
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      Status
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full bg-${
                            ProductDetails?.isActive ? "green" : "red"
                          }-500 mr-2`}
                        ></div>{" "}
                        {ProductDetails?.isActive ? "Active" : "Inactive"}
                      </div>
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-800 mb-1">
                      isPopular
                    </dt>
                    <dd className="text-sm font-medium text-gray-500">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full bg-${
                            ProductDetails?.isPopular ? "green" : "red"
                          }-500 mr-2`}
                        ></div>{" "}
                        {ProductDetails?.isPopular ? "Active" : "Inactive"}
                      </div>
                    </dd>
                  </div>
                </dl>
              </TabPane>

              <TabPane tab="Variations" key="Variations">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                  {ProductDetails?.variations?.length ? (
                    ProductDetails?.variations?.map(
                      (variant: any, index: string) => (
                        <>
                          <div
                            key={index}
                            className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50"
                          >
                            <div className="flex justify-start items- ncenter pb-4">
                              <Avatar
                                size={100}
                                className="bg-contain rounded-2xl"
                                src={variant?.imageUrl}
                                alt="attachment"
                              />
                            </div>

                            <div className="flex justify-between py-1">
                              <div className="font-medium">Variation Name:</div>
                              <div className="text-sm font-normal text-gray-700">
                                {variant?.variationName}
                              </div>
                            </div>

                            {variant?.barCode && (
                              <div className="flex justify-between py-1">
                                <div className="font-medium">Barcode</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {variant?.barCode}
                                </div>
                              </div>
                            )}

                            <div className="flex justify-between py-1">
                              <span className="font-medium">Variation Id</span>
                              <span className="text-sm font-normal text-gray-700">
                                {variant?.variationId}
                              </span>
                            </div>

                            <div className="flex justify-between py-1">
                              <span className="font-medium">MRP</span>
                              <span className="text-sm font-normal text-gray-700">
                                {variant?.price?.mrp}{" "}
                                {variant?.price?.currencyCode}
                              </span>
                            </div>

                            <div className="flex justify-between py-1">
                              <span className="font-medium">TP</span>
                              <span className="text-sm font-normal text-gray-700">
                                {variant?.price?.tp}{" "}
                                {variant?.price?.currencyCode}
                              </span>
                            </div>

                            <div className="flex justify-between py-1">
                              <span className="font-medium">Stock</span>
                              <span className="text-sm font-normal text-gray-700">
                                <span
                                  className={`font-bold bg-white border rounded-full py-1 px-3 ${
                                    variant?.stock < 10
                                      ? `text-red-600`
                                      : `text-green-600`
                                  }`}
                                >
                                  {variant?.stock}
                                </span>
                              </span>
                            </div>

                            {variant?.price?.discountedPrice && (
                              <div className="flex justify-between py-1">
                                <span className="font-medium">
                                  Discounted Price
                                </span>
                                <span className="text-sm font-normal text-gray-700">
                                  {variant?.price?.discountedPrice}{" "}
                                  {variant?.price?.currencyCode}
                                </span>
                              </div>
                            )}

                            {variant?.maximumOrderLimit && (
                              <div className="flex justify-between py-1">
                                <span className="font-medium">
                                  Maximum Order Limit
                                </span>
                                <span className="text-sm font-normal text-gray-700">
                                  {variant?.maximumOrderLimit}
                                </span>
                              </div>
                            )}

                            <Divider orientation="left">
                              Product Discount
                            </Divider>
                            <div className="flex justify-between py-1">
                              <span className="font-medium">
                                Discount in Amount
                              </span>
                              <span className="text-sm font-normal text-gray-700">
                                {variant?.productDiscount?.flat || 0}{" "}
                                {variant?.price?.currencyCode}
                              </span>
                            </div>
                            <div className="flex justify-between py-1">
                              <span className="font-medium">
                                Discount in Percentage
                              </span>
                              <span className="text-sm font-normal text-gray-700">
                                {variant?.productDiscount?.percentage || 0}{" "}
                                {"%"}
                              </span>
                            </div>
                            {
                              // data.item?.products?.length ?
                              variant?.quantitativeProductDiscount
                                ?.freeProduct ? (
                                <div className="">
                                  <h6 className="mt-5 border-bottom pb-2">
                                    <b>Quantitative Product Discount:</b>
                                  </h6>
                                  <div className="flex justify-between py-1">
                                    <span className="font-medium">
                                      Free Product
                                    </span>
                                    <span className="text-sm font-normal text-gray-700">
                                      {
                                        variant?.quantitativeProductDiscount
                                          ?.freeProduct
                                      }
                                    </span>
                                  </div>
                                  <div className="flex justify-between py-1">
                                    <span className="font-medium">
                                      Minimum Quantity
                                    </span>
                                    <span className="text-sm font-normal text-gray-700">
                                      {
                                        variant?.quantitativeProductDiscount
                                          ?.minimumQuantity
                                      }
                                    </span>
                                  </div>
                                  <div className="flex justify-between py-1">
                                    <span className="font-medium">
                                      Product Discount in Amount
                                    </span>
                                    <span className="text-sm font-normal text-gray-700">
                                      {
                                        variant?.quantitativeProductDiscount
                                          ?.productDiscount?.flat
                                      }
                                    </span>
                                  </div>
                                  <div className="flex justify-between py-1">
                                    <span className="font-medium">
                                      Product Discount in Percent
                                    </span>
                                    <span className="text-sm font-normal text-gray-700">
                                      {
                                        variant?.quantitativeProductDiscount
                                          ?.productDiscount?.percentage
                                      }
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            }
                          </div>
                        </>
                      )
                    )
                  ) : (
                    <Empty />
                  )}
                </div>
              </TabPane>

              {ProductDetails?.description && (
                <TabPane tab="Description" key="Description">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                    <div>
                      <dt className="text-md font-bold text-gray-700">
                        Description
                      </dt>
                      <dd
                        className="mt-1 space-y-3 text-sm text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: ProductDetails?.description,
                        }}
                      ></dd>
                    </div>
                  </dl>
                </TabPane>
              )}

              {ProductDetails?.faq && (
                <TabPane tab="FAQ" key="FAQ">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                    <div>
                      <dt className="text-md font-bold text-gray-700">FAQ's</dt>
                      <dd
                        className="mt-1 space-y-3 text-sm text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: ProductDetails?.faq,
                        }}
                      ></dd>
                    </div>
                  </dl>
                </TabPane>
              )}

              <TabPane tab="SEO" key="SEO">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                  {ProductDetails?.metaTitle && (
                    <div>
                      <dt className="text-md font-bold text-gray-700">
                        Meta Title
                      </dt>
                      <dd className="text-sm font-light text-gray-500">
                        {ProductDetails?.metaTitle}
                      </dd>
                    </div>
                  )}
                  <div>
                    <dt className="text-md font-bold text-gray-700">
                      Meta Keyword
                    </dt>
                    <dd className="text-sm font-light text-gray-500">
                      {ProductDetails?.keyword}
                    </dd>
                  </div>
                  {ProductDetails?.metaDescription && (
                    <div>
                      <dt className="text-md font-bold text-gray-700">
                        Meta Description
                      </dt>
                      <dd className="text-sm font-light text-gray-500">
                        {ProductDetails?.metaDescription}
                      </dd>
                    </div>
                  )}
                </dl>
              </TabPane>

              {!inShop && (
                <TabPane tab="Shop Info" key="ShopInfo">
                  <div className="flex flex-col max-w-md bg-white rounded-2xl shadow-sm transform cursor-move">
                    <div className="flex justify-center items- ncenter pb-4">
                      <img
                        className="bg-contain rounded-2xl"
                        src={ProductDetails?.shop?.banner}
                        alt="attachment"
                      />
                    </div>
                    <div className="p-4">
                      <div className="flex flex-col-1 -mt-20">
                        <Avatar
                          src={ProductDetails?.shop?.logo}
                          size={100}
                          className="shadow-lg border-4 border-white"
                        />{" "}
                        <div className="pb-4 text-lg font-bold text-gray-700 mt-14 mb-8 ml-4 text-">
                          {ProductDetails?.shop?.name?.toUpperCase()}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Shop Slug:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.slug}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Shop ID:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.id}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Contact Number:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.contactNumber}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Address:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.address}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Description:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.description}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Prepare Time:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.prepareTime}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Base Delivery Charge:</div>
                        <div className="text-sm font-normal text-gray-700">
                          &#2547;
                          {ProductDetails?.shop?.baseDeliveryCharge}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Delivery Condition:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.isFreeDelivery ? (
                            <div>Free Delivert</div>
                          ) : (
                            <div>Paid Delivert</div>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Status:</div>
                        <div className="text-sm font-normal text-gray-700">
                          {ProductDetails?.shop?.status}
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="font-medium">Rattings:</div>
                        <div className="text-sm font-normal text-gray-700">
                          <Rate
                            value={ProductDetails?.rating || 1}
                            className="mt-1"
                            disabled
                          />
                          <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                            {ProductDetails?.numberOfRating || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              )}

              <TabPane tab="Logs" key="Logs">
                <div className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mb-10">
                  {ProductDetails?.createdBy && (
                    <div className="rounded-md border text-center p-4 max-w-5xl">
                      <div className="text-lg font-bold text-gray-700">
                        Created By
                      </div>
                      <Avatar
                        src={ProductDetails?.createdBy?.profilePicture}
                        size={80}
                        className="shadow-md border my-4"
                      />
                      <div className="text-sm font-semibold text-gray-500">
                        {ProductDetails?.createdBy?.name}
                      </div>
                      <div className="text-sm font-light text-gray-500">
                        {ProductDetails?.createdBy?.mobileNumber?.replace(
                          "+88",
                          ""
                        )}
                      </div>
                    </div>
                  )}

                  {ProductDetails?.updatedBy && (
                    <div className="rounded-md border text-center p-4 max-w-5xl">
                      <div className="text-lg font-bold text-gray-700">
                        Updated By
                      </div>
                      <Avatar
                        src={ProductDetails?.updatedBy?.profilePicture}
                        size={80}
                        className="shadow-md border my-4"
                      />
                      <div className="text-sm font-semibold text-gray-500">
                        {ProductDetails?.updatedBy?.name}
                      </div>
                      <div className="text-sm font-light text-gray-500">
                        {ProductDetails?.updatedBy?.mobileNumber}
                      </div>
                    </div>
                  )}

                  {ProductDetails?.approvedBy && (
                    <div className="rounded-md border text-center p-4 max-w-5xl">
                      <div className="text-lg font-bold text-gray-700">
                        Approved By
                      </div>
                      <Avatar
                        src={ProductDetails?.approvedBy?.profilePicture}
                        size={80}
                        className="shadow-md border my-4"
                      />
                      <div className="text-sm font-semibold text-gray-500">
                        {ProductDetails?.approvedBy?.name}
                      </div>
                      <div className="text-sm font-light text-gray-500">
                        {ProductDetails?.approvedBy?.mobileNumber}
                      </div>
                    </div>
                  )}
                </div>

                {ProductDetails?.updatedLogs && (
                  <>
                    <h4 className="mb-4 font-bold text-lg text-sky-900 border-b pb-2 ">
                      Updated Logs
                    </h4>
                    <Steps direction="vertical" size="default">
                      {ProductDetails?.updatedLogs?.map(
                        (data: any, i: string) => (
                          <Step
                            key={i}
                            icon={
                              <Avatar
                                src={data?.updatedBy?.profilePicture}
                                className="border shadow-md"
                              />
                            }
                            title={data?.updatedBy?.name}
                            subTitle={moment(data?.updatedAt).format("lll")}
                            description={data?.updatedBy?.mobileNumber}
                          />
                        )
                      )}
                    </Steps>
                  </>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

SingleRequestedProduct.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default SingleRequestedProduct;
