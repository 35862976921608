import MainLayout from "../../components/Layouts";
import SingleProduct from "../../components/Product/Details";

const ProductDetailsPage = () => {
  return (
    <MainLayout>
      <SingleProduct inShop={false} />
    </MainLayout>
  );
};

export default ProductDetailsPage;
