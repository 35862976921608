import {
  IdcardOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

const CustomerSidebar = ({ userId }: any): ReactElement => {
  const pathname = window.location?.pathname;

  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              className={`hover:text-white hover:bg-red-100 ${
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/customer/${userId}/details`}
                className="hover:text-white"
              >
                Basic Information
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/orders"
              icon={<ShoppingCartOutlined />}
              className={pathname.includes("/orders") ? "active" : ""}
            >
              <Link to="#">Order History</Link>
            </Menu.Item>

            {/* <Menu.Item
              key="/transactions"
              icon={<SolutionOutlined />}
              className={pathname.includes("/transactions") ? "active" : ""}
            >
              <Link to="#">Transaction History</Link>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default CustomerSidebar;
