import GlobalProductDetails from "../../components/GlobalProduct/Details";
import MainLayout from "../../components/Layouts";

const GlobalProductDetailsPage = () => {
  return (
    <MainLayout>
      <GlobalProductDetails />
    </MainLayout>
  );
};

export default GlobalProductDetailsPage;
