import { Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import BreadCrumb from "../Layouts/Breadcrumb";

const PushNotifications = () => {
  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [form] = Form.useForm();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      title: data.title,
      message: data.message,
      image: generatedImgUrl || data.image,
      // token: `Bearer ${authenticateToken()}`,
      // topic: data.topic,
      // type: data.type,
      // typeId: data.typeId,
    };

    await fetch(
      `${process.env.REACT_APP_NOTIFICATION_API}/notification/topic`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Notification Sent Successfully", "success");
          form.resetFields();
          setGeneratedImgUrl(undefined);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Send Notification" />
      <div>
        <Row
          gutter={25}
          align="middle"
          justify="center"
          className="product-table"
        >
          <Col span={12}>
            <Card>
              <Form layout="vertical" onFinish={onSubmit} form={form}>
                <Form.Item
                  hasFeedback
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input
                    id="title"
                    type="text"
                    placeholder="Notification Title"
                  />
                </Form.Item>
                {/* <Form.Item
                  hasFeedback
                  label="Topic"
                  name="topic"
                  rules={[
                    {
                      required: true,
                      message: "Topic is required",
                    },
                  ]}
                >
                  <Input
                    id="topic"
                    type="text"
                    placeholder="Notification Topic"
                  />
                </Form.Item> */}
                <Form.Item
                  hasFeedback
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Message is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    id="message"
                    placeholder="Notification Message"
                  />
                </Form.Item>

                <h4 className="mt-6 mb-2 font-medium text-lg text-gray-600">
                  Image:
                </h4>
                <div className="">
                  <ImageUploader
                    imgUrl={generatedImgUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>

                <div className="mt-10">
                  <SubmitResetBtn
                    onClickSubmit={(e) => {
                      e.preventDefault();
                      setStartUpload("Uploading");
                    }}
                    onClickReset={() => {
                      form?.resetFields();
                      setGeneratedImgUrl(undefined);
                      setStartUpload(undefined);
                    }}
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PushNotifications;
