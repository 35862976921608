import React from "react";
import MainLayout from "../../components/Layouts";
import FeedbackTypeList from "../../components/FeedbackType";

const FeedbackTypePage = () => {
  return (
    <MainLayout>
      <FeedbackTypeList />
    </MainLayout>
  );
};

export default FeedbackTypePage;
