import MainLayout from "../../components/Layouts";
import React from "react";
import SingleContent from "../../components/Content/Details";

const ContentDetailsPage = () => {
  return (
    <MainLayout>
      <SingleContent />
    </MainLayout>
  );
};

export default ContentDetailsPage;
