import { Image } from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";

const SingleContent = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();

  const [singleContentInfo, setSingleContentInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchContentDetails = useCallback((productSlug: any) => {
    if (productSlug) {
      try {
        setSingleContentInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/web-content/details?slug=${productSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleContentInfo({
                loading: false,
                data: data?.data?.webContentResponse,
              });
            } else {
              setSingleContentInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleContentInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleContentInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchContentDetails((route as any)?.slug);
  }, [fetchContentDetails, route]);

  const content = singleContentInfo?.data;

  return (
    <>
      <BreadCrumb title="Content Details" />
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="flex gap-4">
          <Image
            height={140}
            width="auto"
            src={content?.thumbnail}
            className="rounded-lg shadow-lg mr-4"
          />{" "}
          <Image
            height={140}
            width="auto"
            src={content?.bannerImage}
            className="rounded-lg shadow-lg"
          />
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{content?.title}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Slug</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{content?.slug}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Content Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{content?.contentType}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Category</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{content?.category?.title}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{content?.type?.replace("_", " ")}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap gap-8 py-2 mt-6">
          {content?.shortDescription && (
            <div className="w-2/5 flex-none">
              <div className="text-gray-700 mb-2">
                <span className="font-bold text-xl">Short Description</span>
              </div>
              <div className="text-gray-700">{content?.shortDescription}</div>
            </div>
          )}

          {content?.keyword && (
            <div className="w-2/5 flex-none">
              <div className="text-gray-700 mb-2">
                <span className="font-bold text-xl">Keyword</span>
              </div>
              <div className="text-gray-700">{content?.keyword}</div>
            </div>
          )}
        </div>

        {content?.description !== "<p></p>" && (
          <div className="flex_flex-no-wrap py-2 mt-8">
            <div className="bg-gray-100 p-2 rounded-lg mb-4 shadow-md">
              <div className="text-gray-600">
                <span className="font-bold text-xl">Description</span>
              </div>
            </div>
            <div className="">
              <div className="text-gray-700">
                <span
                  className="mt-1 space-y-3 text-sm text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: content?.description,
                  }}
                ></span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleContent;
