import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import SubmitResetBtn from "../../common/SubmitBtn";

const AddDriver = ({ visibleData, onCloseMethod }: any) => {
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      mobileNumber: `+88${data.mobileNumber?.replace("+88", "")}`,
      drivingLicense: data.drivingLicense,
      carRegistrationNumber: data.carRegistrationNumber,
    };

    if (driverData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/driver`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: driverData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Type Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/driver`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Driver Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setDriverData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (driverData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [driverData, form, visibleData]);

  return (
    <div className="">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...driverData,
          mobileNumber: driverData?.mobileNumber?.replace("+88", ""),
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input
            id="name"
            type="text"
            placeholder="Enter Name"
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Mobile Number"
          rules={[
            {
              required: true,
              message: "Mobile Number is Required!",
            },
            {
              validator: async (_, names) => {
                if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                  return Promise.reject(new Error("Enter valid mobile number"));
                }
              },
            },
          ]}
          name="mobileNumber"
          initialValue={"01"}
        >
          <Input addonBefore={"+88"} size="large" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="City"
          rules={[
            {
              required: false,
              message: "City is Required!",
            },
          ]}
          name="city"
        >
          <Select size="large" placeholder="Select City">
            <Select.Option value="Dhaka">Dhaka</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item hasFeedback label="Driving License" name="drivingLicense">
          <Input
            id="drivingLicense"
            type="text"
            placeholder="Driving License"
            size="large"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Registration Number"
          name="carRegistrationNumber"
        >
          <Input
            id="carRegistrationNumber"
            type="text"
            placeholder="Registration Number"
            size="large"
          />
        </Form.Item>

        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default AddDriver;
