import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPage, getParamValue } from "../../../utils/index";
import Empty from "../../common/Empty";
import moment from "moment";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import DriverLayout from "./Layout";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import styles from "../../../styles/tailwind/List.module.css";

const DriverUnsettleOrderList = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [limit, setLimit] = useState(16);

  const [usersData, setUsersData] = useState<any>({
    loading: false,
    data: null,
  });

  const [selectedUser, setSelectedUser] = useState(undefined);
  const route = useParams();

  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const getUnsettledOrders = useCallback(async () => {
    setUsersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/order/unsettle-order-for-delivery-man` +
          `?deliveryManId=${(route as any)?.id || ``}` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUsersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setUsersData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [route, page, limit]);

  const onCashCollect = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      amount: data.amount * 1,
      note: data.note,
      deliveryManId: (route as any)?.id as string,
    };

    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/transaction/cash-collection`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Cash Recieved Successfully!!!", "success");
          setVisible(!visible);
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    getUnsettledOrders();
  }, [getUnsettledOrders]);

  return (
    <React.Fragment>
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Unsettled Orders"
      >
        <div className={`content-body`}>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-2">
            <table className={styles.mainTable}>
              <thead className="bg-white border-b">
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              {usersData?.loading ? (
                <Loader />
              ) : (
                <tbody>
                  {usersData?.data?.orders?.length ? (
                    usersData?.data?.orders?.map((order: any, index: any) => (
                      <tr
                        className="border-t hover:bg-gray-100"
                        key={index}
                        onClick={() => setSelectedUser(order)}
                      >
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {moment(order?.createdAt).format("lll")}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.orderId}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            &#2547;{order?.total}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            <Pagination
              {...usersData?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
        </div>
      </DriverLayout>

      <Modal
        title="Cash Collect"
        visible={!!visible}
        // okText="Recieve Cash"
        // onOk={onCashCollect}
        confirmLoading={loading}
        destroyOnClose={true}
        onCancel={() => {
          setVisible(!visible);
        }}
        footer={false}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          onFinish={onCashCollect}
        >
          <Form.Item
            label="Enter Amount"
            name="amount"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (value > driverInfo?.balance) {
                    return Promise.reject(
                      new Error("Amount Must be Equal or less than Balance")
                    );
                  }
                },
              },
            ]}
          >
            <Input type="number" placeholder="Enter Collected Amount" />
          </Form.Item>

          <Form.Item name="note" label="Note" rules={[{ required: true }]}>
            <Input.TextArea
              placeholder="Please write some note or description..."
              rows={5}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Recieve Cash
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default DriverUnsettleOrderList;
