import React from "react";
import MainLayout from "../../components/Layouts";
import CorporateEmployeeList from "../../components/Corporate/Employee";

const CorporateEmployeepage = () => {
  return (
    <MainLayout>
      <CorporateEmployeeList />
    </MainLayout>
  );
};

export default CorporateEmployeepage;
 