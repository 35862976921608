import { TreeSelect, TreeSelectProps } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { DefaultOptionType } from "antd/lib/select";
import { responseNotification } from "../../utils/notify";

const TreeSelectOption = (props: any): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [categoryId, setCategoryId] = useState<any>();
  const [targetCategoryId, setTargetCategoryId] = useState<any>();

  // ************************
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getCategories = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/category?type=${type}&page=0&limit=50` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Category", res.data?.categories);
    setTreeData(
      res.data?.categories?.map((item: Omit<DefaultOptionType, "label">) => ({
        ...item?.category,
        id: item?.category?.id,
        pId: 0,
        title: item?.category?.title,
        value: item?.category?.id,
        isLeaf: item?.subCategories,
      }))
    );
  }, []);

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
    setTargetCategoryId(newValue);
  };

  useEffect(() => {
    getCategories(``);
  }, []);

  return (
    <TreeSelect
      allowClear
      treeDataSimpleMode
      style={{ width: "100%" }}
      value={categoryId}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Filter by Category"
      onChange={onChange}
      loadData={onLoadData}
      treeData={treeData}
      size="large"
      className="rounded-md"
    />
  );
};
TreeSelectOption.propTypes = {
  onClick: PropTypes.func,
};

export default TreeSelectOption;
