import MainLayout from "../../components/Layouts";
import ProductList from "../../components/Product/index";

const ProductPage = () => {
  return (
    <MainLayout>
      <ProductList />
    </MainLayout>
  );
};

export default ProductPage;
