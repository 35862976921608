import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../../utils/auth";
import { responseNotification } from "../../../../utils/notify";
import { useParams } from "react-router";
import CampaignLayout from "../Layout";
import { useDispatch } from "react-redux";
import { SET_CAMPAIGN_INFO } from "../../../../redux/auth/authType";
import Loader from "../../../common/Loader";
import CampaignProductList from "../Product";
import AssignCampaignProductList from "../Product";
import { Button, Modal } from "antd";

const CampaignDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(false);
  const [open, setOpen] = useState(false);
  const route = useParams();
  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null, 
  });

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
              dispatch({
                type: SET_CAMPAIGN_INFO,
                payload: {
                  campaignInfo: {
                    id: data?.data?.campaign?.id,
                    name: data?.data?.campaign?.name,
                    appsImg: data?.data?.campaign?.banner,
                    webImg: data?.data?.campaign?.bannerWeb,
                    status: data?.data?.campaign?.status,
                    description: data?.data?.campaign?.description,
                  },
                },
              }); 
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  const campaign = singleCampaignInfo?.data;

  if (singleCampaignInfo.loading) return <Loader />;

  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaign?.id}
        title={`Campaign Details`}
        subTitle={`Details`}
        extra={
          <>
            <Button
              type="dashed"
              shape="round"
              className="bg-white"
              onClick={() => setOpen(true)}
            >
              Assign Product 
            </Button>
          </> 
        }
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white">
            <CampaignProductList />

            <Modal
              title="Campaign Product Assign List"
              centered
              open={open}
              onOk={() => {
                setSubmitForm(true);
                // setOpen(!open);
              }}
              okText="Assign Product"
              onCancel={() => setOpen(!open)}
              width={`80%`}
            >
              <AssignCampaignProductList
                submitForm={submitForm}
                onAssignModal={true}
              />
            </Modal>
          </div>
        </div>
      </CampaignLayout>
    </React.Fragment>
  );
};

CampaignDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignDetails;
