import { Alert, Divider, Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import SubmitResetBtn from "../common/SubmitBtn";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
const { TextArea } = Input;

const AddShoppingMall = ({ visibleData, onCloseMethod }: any) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);

  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [mallData, setMallData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [cropImage, setCropImage] = useState(true);
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);
  const onSubmit = async (data: any) => {
    if (generatedImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.name,
        address: data.address,
        image: generatedImgUrl ? generatedImgUrl : data?.image,
      };

      if (mallData) {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shopping-mall`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: mallData?.id,
              deleted: false,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification(
                "Shopping Mall Updated Successfully",
                "success"
              );
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shopping-mall`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(readyData),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification(
                "Shopping Mall Create Successfully",
                "success"
              );
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    if (visibleData) {
      setMallData(visibleData);
      setGeneratedImgUrl(visibleData?.image);
    }
  }, [visibleData]);

  useEffect(() => {
    if (mallData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.image);
    }
  }, [mallData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...mallData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Shopping Mall Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Shopping Mall Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="address"
          name="address"
          rules={[
            {
              required: true,
              message: "Address is Required!",
            },
          ]}
          style={{ minWidth: "100%" }}
        >
          <TextArea
            id="address"
            placeholder="Enter shopping Mall Address"
            rows={4}
          />
        </Form.Item>
        <Divider orientation="left">Banner Image</Divider>
        <div className="flex items-center pb-4">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={cropImage ? 2.5 : 0}
          />
          <div className=" pr-8">
            <Switch
              onChange={() => setCropImage(!cropImage)}
              defaultChecked={cropImage}
              checkedChildren={<FontAwesomeIcon icon={faCropAlt} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            />
          </div>
        </div>
        {cropImage && (
          <Alert
            message="Image ratio must be 2.5 (1600/640 PX)"
            type="info"
            showIcon
            className="mb-4 rounded-md shadow-sm"
            mall
          />
        )}
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
            // setBannerUrl(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddShoppingMall;
