import AddGlobalProduct from "../../components/GlobalProduct/AddProduct";
import MainLayout from "../../components/Layouts";

const GlobalProductAddPage = () => {
  return (
    <MainLayout>
      <AddGlobalProduct />
    </MainLayout>
  );
};

export default GlobalProductAddPage;
