import CategoryList from "../../components/Category";
import MainLayout from "../../components/Layouts";

const CategoryPage = () => {
  return (
    <MainLayout>
      <CategoryList />
    </MainLayout>
  );
};

export default CategoryPage;
