import { authenticateToken } from "../../../utils/auth";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Select,
  Skeleton,
  Switch,
} from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { responseNotification } from "../../../utils/notify";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { getPage, getParamValue } from "../../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import LoaderFull from "../../common/LoaderFull";
import _ from "lodash";
import moment from "moment";
import AddCampaign from "./AddCampaign";
import { isAllowedService } from "../../../utils/services";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
const { Option } = Select;

const CampaignList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [selectedCampaignForEdit, setSelectedCampaignForEdit] =
    useState(undefined);
  const [isFlashSale, setIsFlashSale] = useState<any>();

  const [campaignData, setCampaignData] = useState<any>({
    loading: false,
    data: null,
  });

  const getCampaigns = useCallback(async () => {
    setCampaignData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/campaign?type=${type}` +
          (isFlashSale ? `&isFlashSale=${isFlashSale}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setCampaignData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCampaignData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [isFlashSale]);
  //
  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    campaignData?.data?.campaigns?.find((campaign: any, i: string) => {
      if (campaign?.id === node?.key) {
        curr = i;
        item = campaign;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? campaignData?.data?.campaigns
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : campaignData?.data?.campaigns?.slice(0, pos),
        item,
        curr > pos
          ? campaignData?.data?.campaigns
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, campaignData?.data?.campaigns?.length - 1)
          : campaignData?.data?.campaigns?.slice(
              pos,
              campaignData?.data?.campaigns?.length
            )
      )?.map((item, i) => ({
        id: item?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/bulk`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignUpdateRequestRestList: formated,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getCampaigns();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onStatusChange = async (id: number, val: boolean) => {
    setLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCampaigns();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  const onClose = () => {
    getCampaigns();
    setVisible(undefined);
    setSelectedCampaign(undefined);
    setSelectedCampaignForEdit(undefined);
    setReArrangeDrawer(undefined);
  };

  const reseAllFieldData = () => {
    form.resetFields();
    setIsFlashSale(false);
  };

  return (
    <>
      <BreadCrumb
        title="Campaign List"
        subTitle={`${
          campaignData?.data?.totalElements ||
          campaignData?.data?.campaigns?.length
        } Campaign(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <Button
            shape="round"
            type="primary"
            className="bg-blue-600"
            onClick={() => setReArrangeDrawer(true)}
          >
            <SwapOutlined />
          </Button>,
          isAllowedService(`Promotion Campaign`) && (
            <AddBtn onClick={() => setVisible(true)} key={2} />
          ),
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item initialValue={null}>
              <Select
                placeholder="Status"
                onChange={(val) => setIsFlashSale(val)}
                value={isFlashSale}
              >
                <Option value={""}>ALL</Option>
                <Option value={"false"}>General Campaign</Option>
                <Option value={"true"}>FlahSale Campaign</Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {campaignData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Campaign</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      {isAllowedService(`Promotion Campaign`) && (
                        <>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {campaignData?.data?.campaigns?.length ? (
                      campaignData?.data?.campaigns?.map(
                        (campaign: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(`/promotion/campaign/${campaign?.id}`)
                            }
                          >
                            <td>
                              <div className="flex items-center">
                                <Avatar.Group
                                  maxCount={3}
                                  size="large"
                                  maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                  }}
                                >
                                  <Avatar
                                    style={{
                                      backgroundColor:
                                        campaign?.colorCode || "#f56a00",
                                    }}
                                  />
                                  <Avatar src={campaign?.bannerWeb} />
                                  <Avatar src={campaign?.banner} />
                                </Avatar.Group>
                                <span className="font-medium text-gray-500 ml-2">
                                  {campaign?.name}
                                </span>
                              </div>
                            </td>

                            <td>{moment(campaign?.startAt).format("lll")}</td>
                            <td>{moment(campaign?.endAt).format("lll")}</td>
                            {isAllowedService(`Promotion Campaign`) && (
                              <>
                                <td>
                                  <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={campaign?.isActive}
                                    onChange={(val, e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onStatusChange(campaign?.id, val);
                                    }}
                                    loading={
                                      loading && campaign?.id === loading
                                    }
                                    title="Active/Inactive"
                                  />
                                </td>
                                <td>
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedCampaignForEdit(campaign);
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...campaignData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Re Arrange Campaign"
        placement="right"
        onClose={onClose}
        visible={reArrangeDrawer}
        width={450}
      >
        {campaignData?.loading ? (
          <Skeleton />
        ) : campaignData?.data?.campaigns?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={campaignData?.data?.campaigns.map((campaign: any) => ({
              title: campaign?.name,
              key: campaign?.id,
            }))}
            onDrop={(e: any) => {
              changeOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>

      <Drawer
        title={
          visible
            ? "Add Campaign"
            : selectedCampaignForEdit
            ? "Edit Campaign"
            : "Campaign Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedCampaign || selectedCampaignForEdit}
        width={600}
      >
        {visible || selectedCampaignForEdit ? (
          <AddCampaign
            onCloseMethod={onClose}
            visibleData={selectedCampaignForEdit}
          />
        ) : undefined}
      </Drawer>
    </>
  );
};

export default CampaignList;
