import ThirdLevelCategoryList from "../../components/Category/ThirdLevel";
import MainLayout from "../../components/Layouts";

const ThirdLevelCategoryPage = () => {
  return (
    <MainLayout>
      <ThirdLevelCategoryList />
    </MainLayout>
  );
};

export default ThirdLevelCategoryPage;
