import React from "react";
import MainLayout from "../../components/Layouts";
import ShoppingMallList from "../../components/ShoppingMalls";

const ShoppingMallPage = () => {
  return (
    <MainLayout>
      <ShoppingMallList />
    </MainLayout>
  );
};

export default ShoppingMallPage;
