import { Modal, Layout, Tabs, Button, Drawer } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import CategoryLayout from "../Layout";
import { useDispatch } from "react-redux";
import { SET_CATEGORY_INFO } from "../../../redux/auth/authType";
import SubCategoriesTree from "../SubCategoriesTree";
import CategoryReArrangeList from "../ReArrange";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AddBtn from "../../common/AddBtn";
import AddCategory from "../AddCategory";
const { Content } = Layout;
const { confirm } = Modal;

const CategoryDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const route = useParams();
  const [visible, setVisible] = useState<any>(undefined);
  const [singleCategoryInfo, setSingleCategoryInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchCategoryDetails = useCallback((getCategoryId: any) => {
    if (getCategoryId) {
      try {
        setSingleCategoryInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/detail?categoryId=${getCategoryId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCategoryInfo({
                loading: false,
                data: data?.data?.category,
              });
              dispatch({
                type: SET_CATEGORY_INFO,
                payload: {
                  categoryInfo: {
                    id: data?.data?.category?.id,
                    type: data?.data?.category?.type,
                    title: data?.data?.category?.title,
                    slug: data?.data?.category?.slug,
                    image: data?.data?.category?.image,
                    parent: data?.data?.category?.parent,
                    level: data?.data?.category?.level,
                    description: data?.data?.category?.description,
                    position: data?.data?.category?.position,
                    status: data?.data?.category?.status,
                    deleted: data?.data?.category?.deleted,
                    bannerImage: data?.data?.category?.bannerImage,
                    keyword: data?.data?.category?.keyword,
                  },
                },
              });
            } else {
              setSingleCategoryInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCategoryInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleCategoryInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  const onStatusChange = async (status: any) => {
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: singleCategoryInfo?.data?.id,
        status: status,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          dispatch({
            type: SET_CATEGORY_INFO,
            payload: {
              shopInfo: {
                status: status,
              },
            },
          });
          fetchCategoryDetails((route as any)?.categoryId);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: `Do you Want to change Status "${status}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  useEffect(() => {
    fetchCategoryDetails((route as any)?.categoryId);
  }, [fetchCategoryDetails]);

  const onClose = () => {
    setVisible(undefined);
  };
  const category = singleCategoryInfo?.data;

  return (
    <React.Fragment>
      <CategoryLayout
        categoryId={category?.id}
        title={`Category Details`}
        subTitle={`Details`}
        extra={<></>}
      >
        <div className="col-span-2">
          <Layout>
            <Content className="main-content-layout">
              <div className="content-body_ rounded-2xl">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 mb-6">
                  <Tabs
                    tabPosition="top"
                    tabBarExtraContent={
                      category?.level < 2 && (
                        <AddBtn onClick={() => setVisible(true)} />
                      )
                    }
                    items={[
                      {
                        label: `Sub Category`,
                        key: "SubCategory",
                        children: (
                          <SubCategoriesTree
                            parentCategoryId={category?.id}
                            onCloseMethod={onClose}
                          />
                        ),
                      },
                      {
                        label: `Re-arrange`,
                        key: "ReArrange",
                        children: (
                          <CategoryReArrangeList
                            parentCategoryId={category?.id}
                            level={category?.level}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      </CategoryLayout>

      <Drawer
        title={visible ? "Add Category" : undefined}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={500}
      >
        <AddCategory
          visibleData={null}
          onCloseMethod={onClose}
          parentCategoryId={category?.id}
          level={category?.level}
        />
      </Drawer>
    </React.Fragment>
  );
};

CategoryDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CategoryDetails;
