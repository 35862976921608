import React from "react";
import { authenticateToken } from "../../utils/auth";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";
import { AutoComplete, Avatar, Button, Empty, Form, Input, Spin } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
const FeedbackList = () => {
  const navigate = useNavigate();
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [limit, setLimit] = useState(16);
  const [baseOrderId, setBaseOrderId] = useState<any>();

  const [FeedbackData, setFeedbackData] = useState<any>({
    loading: false,
    data: null,
  });

  const [orderOptions, setOrderOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getFeedback = useCallback(async () => {
    setFeedbackData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/feedback/admin/all?page=${page || 0}&limit=${
          limit || 16
        }` +
          (status ? `?status=${status}` : ``) +
          (baseOrderId ? `&orderId=${baseOrderId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setFeedbackData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setFeedbackData({ loading: false, data: [] });
        console.error("Feedback Type: Error", err);
      });
  }, [page, limit, status, baseOrderId]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?` +
            (getOrderId
              ? `baseOrderId=${getOrderId}`
              : `` + getOrderId
              ? `orderId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            (type ? `&type=${type}` : ``) +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.baseOrderId,
              value: order.baseOrderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status, type]
  );

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  useEffect(() => {
    getFeedback();
  }, [getFeedback]);

  const reseAllFieldData = () => {
    setStatus("");
    setBaseOrderId("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title={`Feedback List`}
        subTitle={`${
          FeedbackData?.data?.totalElements ||
          FeedbackData?.data?.feedbacks?.length ||
          0
        } Feedback(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="baseOrderIdSearch" initialValue={baseOrderId}>
              <AutoComplete
                dropdownMatchSelectWidth={250}
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val) => {
                  setBaseOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Base Order ID"
                  onSearch={(val) => {
                    setBaseOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {FeedbackData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">User Contact</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {FeedbackData?.data?.feedbacks?.length ? (
                      FeedbackData?.data?.feedbacks?.map(
                        (feedback: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              navigate(`/feedbacks/${feedback?.id}`)
                            }
                          >
                            <td>
                              <span className="name">
                                <Avatar
                                  src={feedback?.profilePicture}
                                  className="mr-2"
                                />
                                {feedback?.customer?.name}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {feedback?.customer?.mobileNumber?.split("+88")}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                <small
                                  className={`text-capitalize ${
                                    moment().diff(
                                      feedback?.createdAt,
                                      "minutes"
                                    ) >= 60
                                      ? "font-weight-bold text-danger"
                                      : "text-dark"
                                  }`}
                                >
                                  {moment(feedback?.createdAt).fromNow()}
                                </small>{" "}
                                <br />
                                {moment(feedback?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="name">{feedback?.orderId}</span>
                            </td>
                            <td>
                              <span
                                className={`radius-btn ${feedback?.status?.toLowerCase()}-btn`}
                              >
                                {feedback?.status}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...FeedbackData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default FeedbackList;
