import React from "react";
import MainLayout from "../../components/Layouts";
import CorporateEmployeeGroupList from "../../components/Corporate/EmployeeGroup";

const CorporateEmployeeGroupPage = () => {
  return (
    <MainLayout>
      <CorporateEmployeeGroupList />
    </MainLayout>
  );
};

export default CorporateEmployeeGroupPage;
