import GlobalProductList from "../../components/GlobalProduct";
import MainLayout from "../../components/Layouts";

const GlobalProductPage = () => {
  return (
    <MainLayout>
      <GlobalProductList />
    </MainLayout>
  );
};

export default GlobalProductPage;
