import React from "react";
import MainLayout from "../../../components/Layouts";
import CorporateTopupHistoryList from "../../../components/Corporate/TopUp";

const CorporateTopupHistoryPage = () => {
  return (
    <MainLayout>
      <CorporateTopupHistoryList />
    </MainLayout>
  );
};

export default CorporateTopupHistoryPage;
