import { Checkbox, Form, Input, Radio, Divider, Row, Col } from "antd";
import React, { Key, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import axios from "axios";
import { useSelector } from "react-redux";
import SubmitResetBtn from "../../common/SubmitBtn";
import Loader from "../../common/Loader";
const CheckboxGroup = Checkbox.Group;

const AddGroup = ({ visibleData, onCloseMethod }: any) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState<any>(undefined);

  // --------------------------------
  // --------------------------------
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  // --------------------------------
  // --------------------------------

  const [serviceOptions, setServiceOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      type: type || data.type,
      serviceActions: Object.keys(checkedList)?.map((key) => ({
        action: checkedList?.[key],
        serviceId: key,
      })),
    };

    if (groupData) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: groupData?.id,
          isActive: groupData?.isActive,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getServiceOptions = useCallback(
    async (key?: string) => {
      setServiceOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(
          `${encodedUri}/service/by-type?Type=${type}&IsActive=true` +
            (key ? `&name=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setServiceOptions({
            loading: false,
            list: res.data?.services?.map(
              (service: { name: any; id: any }) => ({
                label: service.name,
                value: service.id,
              })
            ),
          });
        })
        .catch((err) => {
          setServiceOptions({ loading: false, list: [] });
          console.error("Services: Error", err);
        });
    },
    [type]
  );

  const getServicesByGroup = useCallback(async (groupId?: string) => {
    setLoading(true);
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    return axios
      .get(
        `${encodedUri}/service-group/services-by-group?groupId=${groupId}&serviceType=${type}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res: any) => {
        const temp = {} as any;
        res?.data?.serviceGroups?.forEach((service: any) => {
          if (service?.serviceNameId) {
            temp[service?.serviceNameId] = service?.id;
          }
        });
        console.log("temp", temp);
        // setCheckedList(
        //   res.data.serviceGroups.map((service: any) => service?.id)
        // );
        setCheckedList(temp);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error("Services: Error", err);
      });
  }, []);

  const onChange = (list: any) => {
    // console.log("list", list);
    // setCheckedList(list);
    // setIndeterminate(
    //   !!list.length && list.length < serviceOptions?.list?.length
    // );
    // setCheckAll(list.length === serviceOptions?.list?.length);
    try {
      const temp = {} as any;
      list?.forEach((id: any) => {
        if (checkedList?.[id]) {
          temp[id] = checkedList?.[id];
        } else {
          temp[id] = "all";
        }
      });

      setCheckedList(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeAction = (id: any, value: any) => {
    const temp = { ...checkedList };
    temp[id] = value;
    setCheckedList(temp);
  };

  useEffect(() => {
    if (visibleData) {
      setGroupData(visibleData);
      getServicesByGroup(visibleData?.id);
    }
  }, [getServicesByGroup, visibleData]);

  useEffect(() => {
    if (groupData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [form, groupData, visibleData]);

  useEffect(() => {
    getServiceOptions();
  }, [getServiceOptions]);

  // --------------------------------
  // --------------------------------
  const onChangeCheckbox = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal?.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  console.log(
    "selectedProductBarcode",
    selectedProductBarcode,
    serviceOptions.list?.length
  );
  // --------------------------------
  // --------------------------------

  const groupedMap = serviceOptions?.list?.reduce((entryMap, e) => {
    const key = e.label.split(" ").shift();

    entryMap.set(key, entryMap.get(key) ? [...entryMap.get(key), e] : [e]);
    return entryMap;
  }, new Map());

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...groupData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Group Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Type Name" />
        </Form.Item>

        <>
          {serviceOptions?.loading ? (
            <Loader />
          ) : (
            <>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={
                  checkAll ||
                  selectedProductBarcode?.length === checkedList?.length
                }
                className="mr-3"
                // disabled={!serviceOptions.list?.services?.length}
              >
                Check all
              </Checkbox>
              <Divider />

              <div className="">
                {[...groupedMap]?.map(([key, value]: any) => (
                  <div key={key} className="mb-6">
                    <div className="w-full font-bold uppercase">
                      <Checkbox value={key}>{key}</Checkbox>
                    </div>
                    <div className="w-full">
                      <CheckboxGroup className="w-full">
                        <div className="w-full">
                          {value?.map((service) => (
                            <div className="w-full pl-6" key={service?.value}>
                              <Row>
                                <Col span="14">
                                  <Checkbox
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onChangeCheckbox(service?.value);
                                    }}
                                    // checked={selectedProductBarcode?.find(
                                    //   (item: any) => serviceGroup?.value === item
                                    // )}
                                    checked={!!checkedList?.[service.value]}
                                    className="mr-3"
                                    value={service?.value}
                                  >
                                    {service?.label}
                                  </Checkbox>
                                </Col>
                                <Col span="10">
                                  <Radio.Group
                                    onChange={(e) =>
                                      onChangeAction(
                                        service?.value,
                                        e.target?.value
                                      )
                                    }
                                    value={checkedList[service?.value]}
                                  >
                                    <Radio value={"all"}>All</Radio>
                                    <Radio value={"view"}>View</Radio>
                                  </Radio.Group>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </CheckboxGroup>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>

        <Divider />
        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddGroup;
