import React from "react";
import MainLayout from "../../components/Layouts";
import KeywordList from "../../components/Keywords";

const KeywordPage = () => {
  return (
    <MainLayout>
      <KeywordList />
    </MainLayout>
  );
};

export default KeywordPage;
