import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import FeedbackLayout from "../Layout";
import DriverHeader from "../Layout/topHeader";
import FeedbackDetailsData from "./details";

const FeedbackDetails = (): ReactElement => {
  const route = useParams();

  return (
    <React.Fragment>
      <FeedbackLayout feedbackId={(route as any)?.id as string}>
        <div className="bg-white">
          <DriverHeader leftarea={"Feedback Details"} rightarea={""} />
          <FeedbackDetailsData inOrder={false} orderId={null} />
        </div>
      </FeedbackLayout>
    </React.Fragment>
  );
};
FeedbackDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default FeedbackDetails;
