import { Button, Input, DatePicker, Form, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { responseNotification } from "../../../utils/notify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getPage, getParamValue } from "../../../utils/index";
import Pagination from "../../common/Pagination";
import BreadCrumb from "../../Layouts/Breadcrumb";
import DriverLayout from "./Layout";
import Empty from "../../common/Empty";
import Loader from "../../common/Loader";
const { RangePicker } = DatePicker;

const DriverCashCollect = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const route = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [transactionData, setTransactionData] = useState<any>({
    loading: false,
    data: null,
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const from = moment(range.from)?.toISOString();
  const to = moment(range.to)?.toISOString();

  const onCashCollect = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      amount: data.amount * 1,
      note: data.note,
      deliveryManId: (route as any)?.id as string,
    };

    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/transaction/cash-collection`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Cash Recieved Successfully!!!", "success");
          // fetchDriverDetails((route as any)?.id);
          handleOk();
          navigate(`/drivers/${(route as any)?.id as string}/transactions`);
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getTransactions = useCallback(async () => {
    setTransactionData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/transaction/history/delivery-man` +
          `?page=${page || 0}` +
          `&limit=${limit || 16}` +
          `&deliveryManId=${(route as any)?.id}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTransactionData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTransactionData({ loading: false, data: [] });
        console.error("Drivers: Error", err);
      });
  }, [page, limit, route, from, to]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const reseAllFieldData = () => {
    form.resetFields();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <BreadCrumb
        title=""
        extra={[
          <Button type="primary" danger onClick={() => setVisible(true)}>
            Collect Cash
          </Button>,
        ]}
      />
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Collected History"
      >
        <div className="bg-white" style={{ minHeight: 750 }}>
          <section className="search_area">
            <Form form={form} className="search_form">
              {/* <Form.Item name="search">
            <AutoComplete
              style={{ width: 300 }}
              // onSearch={getDriversOptionsDebounce}
              onSelect={(val) => setMobileNumber(val)}
              options={driversOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                driversOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Phone (01...)"
                onSearch={(val) => setMobileNumber(val)}
                enterButton
                loading={driversOptions.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item> */}

              <Form.Item name="range">
                <RangePicker
                  onChange={(e, f) => {
                    console.log("momnet", moment(f[0])?.toISOString());
                    setRange({ from: f?.[0], to: f?.[1] });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  inputReadOnly
                  allowClear
                />
              </Form.Item>
            </Form>

            <div className="search_btn">
              <Button
                type="primary"
                danger
                size="large"
                onClick={reseAllFieldData}
              >
                Reset
              </Button>
            </div>
          </section>
          <div className="product-list-item-area white-bg section-padding-bottom">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-list-items-inner-content">
                    <div className="single-product-wrapper">
                      <div className="single-product-heading">
                        <span>Transaction Id</span>
                      </div>
                      <div className="single-product-heading">
                        <span>Date & Time</span>
                      </div>
                      <div className="single-product-heading">
                        <span>Amount</span>
                      </div>
                      <div className="single-product-heading">
                        <span>Status</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {transactionData?.loading ? (
                  <Loader />
                ) : (
                  <div className="col-lg-12 product-table">
                    <div className="product-table-area">
                      {transactionData?.data?.transactions?.length ? (
                        transactionData?.data?.transactions?.map(
                          (transaction: any, index: number) => (
                            <React.Fragment key={index}>
                              <div
                                className="single-product-wrapper"
                                // onClick={() => setSelectedDriver(transaction)}
                              >
                                <div className="single-product">
                                  <span className="name">
                                    {transaction?.transactionId}
                                  </span>
                                </div>
                                <div className="single-product">
                                  <span className="name text-capitalize">
                                    {moment(transaction?.createdAt).format(
                                      "lll"
                                    )}
                                  </span>
                                </div>
                                <div className="single-product">
                                  <span className="name">
                                    &#2547; {transaction?.amount}
                                  </span>
                                </div>

                                <div className="single-product">
                                  <span
                                    className={`radius-btn ${transaction?.type?.toLowerCase()}-btn`}
                                  >
                                    {transaction?.type?.split("-").join(" ")}
                                  </span>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        )
                      ) : (
                        <Empty data="Data" />
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* <Drawer
            title={
              selecteDriverForEdit
                ? "Edit Delivery Hero"
                : "Delivery Hero Details"
            }
            width={selecteDriverForEdit ? 600 : 800}
            onClose={onClose}
            visible={selectedDriver || selecteDriverForEdit}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            {
              selecteDriverForEdit ? (
                <AddDriver
                  visibleData={selecteDriverForEdit}
                  onCloseMethod={onClose}
                />
              ) : undefined
              // (
              //   <DriverDetails
              //     driverDetails={selectedDriver}
              //     onCloseMethod={onClose}
              //     refetch
              //   />
              // )
            }
          </Drawer> */}

              <Pagination
                {...transactionData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </div>
        </div>
      </DriverLayout>

      <Modal
        title="Cash Collect"
        visible={!!visible}
        // okText="Recieve Cash"
        // onOk={onCashCollect}
        confirmLoading={loading}
        destroyOnClose={true}
        onCancel={() => {
          setVisible(!visible);
        }}
        footer={false}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          onFinish={onCashCollect}
        >
          <Form.Item
            label="Select Shop"
            name="amount"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (value > driverInfo?.balance) {
                    return Promise.reject(
                      new Error("Amount Must be Equal or less than Balance")
                    );
                  }
                },
              },
            ]}
          >
            <Input type="number" placeholder="Enter Collected Amount" />
          </Form.Item>

          <Form.Item name="note" label="Note" rules={[{ required: true }]}>
            <Input.TextArea
              placeholder="Please write some note or description..."
              rows={5}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Recieve Cash
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default DriverCashCollect;
