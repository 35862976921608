import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Tag,
} from "antd";

import {
  BarcodeOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { debounce } from "lodash";
import { authenticateToken } from "../../../utils/auth";
import { getImgUrl, getParamValue } from "../../../utils/index";
import Loading from "../../common/Loader";
import { useLocation } from "react-router";
import { responseNotification } from "../../../utils/notify";
import { Link } from "react-router-dom";
const { Option } = Select;

const OrderProductList = ({
  orderType,
  selectedProducts,
  setSelectedProducts,
}: any): ReactElement => {
  const router = useLocation();
  const page = getParamValue(router?.search, "page");

  const [form] = Form.useForm();
  const [limit, setLimit] = useState(20);
  const [type, setType] = useState(orderType);
  const [shopId, setShopId] = useState<any>();
  const [supplierId, setSupplierId] = useState();
  const [barCode, setBarCode] = useState<any>();
  const [parentCatId, setParentCatId] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVisibleProduct, setSelectedVisibleProduct] = useState<any>();

  // const [productVariation, setProductVariation] = useState<any>([]);

  const [productData, setProductData] = useState<any>({
    loading: false,
    item: [],
  });

  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState(undefined);

  const [key, setKey] = useState("");
  const [visible, setVisible] = useState(undefined);
  const [selectedProductId, setSelectedProductId] = useState(undefined);

  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [parentCatOptions, setParentCatOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [categoriesOptions, setCategoriesOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [suppliersOptions, setSuppliersOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const reseAllFieldData = () => {
    setKey("");
    setShopId("");
    setParentCatId("");
    setCategoryId("");
    setBrandId("");
    setBarCode("");
    form.resetFields();
  };

  const fetchProducts = useCallback(() => {
    try {
      setProductData({ loading: true, item: [] });
      const url =
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/product?` +
        `page=${page || 0}` +
        `&limit=${limit || 16}` +
        `&isActive=${true}` +
        (key ? `&key=${key}` : ``) +
        (barCode ? `&barCode=${barCode}` : ``) +
        (categoryId
          ? `&categoryId=${categoryId}`
          : parentCatId
          ? `&categoryId=${parentCatId}`
          : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (type ? `&type=${type}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (supplierId ? `&supplierId=${supplierId}` : ``);
      // (`&isDeleted=${false}`);

      fetch(url, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setProductData({ loading: false, item: data });
        })
        .catch(() => {
          setProductData({ loading: true, item: [] });
        });
    } catch (error) {
      setProductData({ loading: true, item: [] });
      console.log(error, "error");
    }
  }, [
    limit,
    page,
    categoryId,
    brandId,
    shopId,
    supplierId,
    key,
    type,
    barCode,
  ]);

  const getProductOptions = useCallback(
    async (val: any) => {
      setProductOptions({ loading: false, list: [] });

      if (val) {
        setProductOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

        const res = await axios.get(
          `${encodedUri}/admin/product/search?page=0&limit=20` +
            `&isActive=${true}` +
            (val ? `&key=${val}` : ``) +
            (type ? `&type=${type}` : ``) +
            (shopId ? `&shopId=${shopId}` : ``) +
            (supplierId ? `&supplierId=${supplierId}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        );
        setProductOptions({
          loading: false,
          list: res?.data?.keys?.map((product: { name: any }) => {
            return {
              value: product?.name,
              label: product?.name,
            };
          }),
        });
      }
    },
    [type, shopId, supplierId]
  );

  const getProductByBarcodeOptions = useCallback(
    async (bCode: string) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/product/search?page=0&limit=20` +
          `&isActive=${true}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (shopId ? `&shopId=${shopId}` : ``) +
          (key ? `&key=${key}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [type, key, shopId]
  );

  const getParentCatOptions = useCallback(
    async (val?: string) => {
      setParentCatOptions({ loading: true, list: [] });
      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/search?page=0&limit=20&isParent=false` +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );

      setParentCatOptions({
        loading: false,
        list: res?.data?.categories?.map((category: any) => {
          return {
            value: category?.id,
            label: category?.title,
          };
        }),
      });
    },
    [type]
  );

  const getCategoriesOptions = useCallback(
    async (val?: string) => {
      setCategoriesOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/categories?shopId=${shopId}&page=0&limit=20` +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setCategoriesOptions({
        loading: false,
        list: res?.data?.categories?.map((category: any) => {
          return {
            value: category?.category?.id,
            label: category?.category?.title,
          };
        }),
      });
    },
    [parentCatId, type, shopId]
  );

  const getBrandsOptions = useCallback(
    async (val?: string) => {
      setBrandsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (val ? `&key=${val}` : ``),

        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );

      setBrandsOptions({
        loading: false,
        list: res?.data?.brands?.map((brand: any) => {
          return {
            id: brand?.id,
            name: brand?.name,
          };
        }),
      });
    },
    [type]
  );

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (val ? `&key=${val}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: any) => {
          return {
            id: shop?.id,
            name: shop?.name,
          };
        }),
      });
    },
    [type]
  );

  const getSupplierOptions = useCallback(
    async (val?: string) => {
      setSuppliersOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/supplier/by-shop` +
          (shopId ? `?shopId=${shopId}` : ``) +
          (val ? `&key=${val}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setSuppliersOptions({
        loading: false,
        list: res?.data?.suppliers?.map((supplier: any) => {
          return {
            value: supplier?.id,
            label: supplier?.name,
          };
        }),
      });
    },
    [shopId, page, limit, type]
  );

  const hasProduct = (id: string) =>
    selectedProducts?.find((item: any) => item?.id === id);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (type) {
        if (value) {
          if (field === "product") getProductOptions(value);
          else if (field === "parentCategory") getParentCatOptions(value);
          else if (field === "category") getCategoriesOptions(value);
          else if (field === "brand") getBrandsOptions(value);
          else if (field === "shop") getShopsOptions(value);
          else if (field === "productByBarcode")
            getProductByBarcodeOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getBrandsOptions,
    getParentCatOptions,
    getCategoriesOptions,
    getProductOptions,
    getShopsOptions,
    getProductByBarcodeOptions,
    type,
  ]);

  useEffect(() => {
    setShopId("");
    setShopsOptions({ list: [], loading: false });
    setBrandId("");
    setBrandsOptions({ list: [], loading: false });
    setCategoryId("");
    setCategoriesOptions({ list: [], loading: false });
    form.resetFields([
      "product_search",
      "parentCat_search",
      "category_search",
      "brand_search",
      "shop_search",
    ]);
  }, [form, type]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (type) {
      getShopsOptions();
      getParentCatOptions();
      getCategoriesOptions();
      getBrandsOptions();
    }
  }, [
    type,
    getShopsOptions,
    getParentCatOptions,
    getCategoriesOptions,
    getBrandsOptions,
  ]);

  useEffect(() => {
    if (shopId) {
      getSupplierOptions();
    }
  }, [getSupplierOptions, shopId]);

  useEffect(() => {
    if (parentCatId) {
      getCategoriesOptions();
      setCategoryId(undefined);
      form.resetFields(["category_search"]);
    }
  }, [getCategoriesOptions, parentCatId]);

  useEffect(() => {
    if (visible) {
      setSelectedProductId(undefined);
      setSelectedProductForEdit(undefined);
    }
  }, [setSelectedProductId]);

  useEffect(() => {
    setSelectedProductId(undefined);
    setSelectedProductForEdit(undefined);
  }, [visible]);

  return (
    <div className="product-lists">
      <section className="search_area">
        <Form form={form} className="search_form">
          <Form.Item name="shop_search">
            <Select
              allowClear
              showSearch
              placeholder="Filter by Shop"
              optionFilterProp="children"
              onChange={(val) => setShopId(val)}
              onSearch={(e) => handleSearch(e, "shop")}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions.list}
            ></Select>
          </Form.Item>

          {suppliersOptions?.list?.length ? (
            <Form.Item name="supplierId">
              <Select
                allowClear
                autoClearSearchValue
                onSearch={(e) => handleSearch(e, "supplier")}
                onChange={(val) => setSupplierId(val)}
                options={suppliersOptions?.list}
                maxTagTextLength={20}
                disabled={!shopId}
                placeholder="Select Supplier if Needed"
              ></Select>
            </Form.Item>
          ) : (
            ``
          )}

          <Form.Item name="barcode_search" initialValue={barCode}>
            <AutoComplete
              onSearch={(e) => handleSearch(e, "productByBarcode")}
              onSelect={(val: string) => setBarCode(val)}
              options={productBarcodeOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                productBarcodeOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                style={{ width: "calc(100% - 46px)" }}
                size="large"
                placeholder="Search by BarCode"
                onSearch={(val) => {
                  setBarCode(val);
                }}
                enterButton
                loading={productBarcodeOptions.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item name="product_search" initialValue={key}>
            <AutoComplete
              onSearch={(e) => handleSearch(e, "product")}
              onSelect={(val: string) => setKey(val.toString())}
              options={productOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                productOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                style={{ width: "calc(100% - 46px)" }}
                size="large"
                placeholder="Search by Name"
                onSearch={(val) => {
                  setKey(val);
                }}
                enterButton
                loading={productOptions.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="parentCat_search" initialValue={parentCatId}>
            <Select
              allowClear
              showSearch
              placeholder="Parent Category"
              optionFilterProp="children"
              onChange={(val) => setParentCatId(val)}
              onSearch={(e) => {
                handleSearch(e, "parentCategory");
              }}
              filterOption={() => {
                return true;
              }}
              options={parentCatOptions?.list}
            ></Select>
          </Form.Item>

          <Form.Item name="category_search" initialValue={categoryId}>
            <Select
              allowClear
              showSearch
              placeholder="Filter by Category"
              optionFilterProp="children"
              onChange={(val) => setCategoryId(val)}
              onSearch={(e) => handleSearch(e, "category")}
              filterOption={() => {
                return true;
              }}
              options={categoriesOptions?.list}
            ></Select>
          </Form.Item>

          {/* <Form.Item name="brand_search">
            <Select
              showSearch
              placeholder="Filter by Brand"
              optionFilterProp="children"
              onChange={(val) => setBrandId(val)}
              onSearch={(e) => handleSearch(e, "brand")}
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {brandsOptions.list.length ? (
                <Option value={""}>All Brands</Option>
              ) : undefined}{" "}
              {brandsOptions.list.map((brand, i) => (
                <Option value={brand.id} key={i}>
                  {brand.name}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
        </Form>
        <div className="search_btn">
          <Button type="primary" size="large" danger onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      </section>

      <div className="product-list-item-area white-bg section-padding-bottom">
        <Row gutter={10}>
          <Col span={18} className="">
            {productData.loading ? (
              <Loading />
            ) : (
              <div className="product-table">
                <div className="product-table-area">
                  {productData.item?.products?.length ? (
                    productData?.item?.products?.map(
                      (product: any, index: number) => (
                        <React.Fragment key={index}>
                          <div className="single-product-wrapper">
                            <div className="single-product major">
                              <Avatar
                                shape="square"
                                size={50}
                                src={
                                  <Image
                                    src={getImgUrl(product?.productImage)}
                                    height={50}
                                  />
                                }
                                className="mr-3"
                              />
                              <span className="name">
                                {product?.name?.substring(0, 60)}
                              </span>
                            </div>
                            <div className="single-product major">
                              <div className="">
                                {product?.variations?.length
                                  ? product?.variations?.map(
                                      (variant: any, index: string) => (
                                        <React.Fragment key={index}>
                                          <div className="d-flex _text-center mb-1">
                                            {variant?.barCode ? (
                                              <Tag className="d-flex align-items-center">
                                                <BarcodeOutlined className="mr-1" />{" "}
                                                {variant?.barCode}
                                              </Tag>
                                            ) : product?.barCode ? (
                                              <Tag className="d-flex align-items-center">
                                                <BarcodeOutlined className="mr-1" />{" "}
                                                {product?.barCode}
                                              </Tag>
                                            ) : undefined}
                                            <Tag
                                              color={
                                                variant?.stock < 10
                                                  ? `red`
                                                  : `green`
                                              }
                                            >
                                              {variant?.variationName?.toUpperCase()}{" "}
                                              : {variant?.stock || 0}
                                            </Tag>
                                            <Tag>
                                              MRP{" "}
                                              {variant?.price?.currencyCode ||
                                                "Tk"}
                                              {variant?.price
                                                ?.discountedPrice ||
                                                variant?.price?.mrp}
                                              {variant?.price
                                                ?.discountedPrice &&
                                                variant?.price
                                                  ?.discountedPrice !==
                                                  variant?.price?.mrp && (
                                                  <span className="text-danger">
                                                    {" "}
                                                    <del>
                                                      {variant?.price
                                                        ?.currencyCode || "Tk"}
                                                      {variant?.price?.mrp}
                                                    </del>
                                                  </span>
                                                )}
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </div>

                            <div className="single-product text-right">
                              <Button
                                type="primary"
                                danger
                                style={{ minWidth: 105 }}
                                onClick={async () => {
                                  product?.variations?.length > 1
                                    ? setSelectedVisibleProduct(product)
                                    : await setSelectedProducts(
                                        (prevProducts: any) => {
                                          let arr = Array.from(prevProducts);
                                          arr?.find(
                                            (item: any) =>
                                              item?.id === product?.id
                                          )
                                            ? (arr = arr.filter(
                                                (item: any) =>
                                                  item?.id !== product?.id
                                              ))
                                            : arr.push({
                                                ...product,
                                                quantity: 1,
                                                variation:
                                                  product?.variations?.[0],
                                              });
                                          return arr;
                                        }
                                      );
                                }}
                                disabled={product?.deleted}
                              >
                                {selectedProducts?.find(
                                  (item: any) => item?.id === product?.id
                                ) ? (
                                  product?.variations?.length > 1 ? (
                                    <>
                                      <PlusCircleOutlined /> Change
                                    </>
                                  ) : (
                                    <>
                                      <DeleteOutlined /> Remove
                                    </>
                                  )
                                ) : product?.variations?.length > 1 ? (
                                  <>
                                    <PlusCircleOutlined /> Add
                                  </>
                                ) : (
                                  <>
                                    <PlusOutlined /> Add
                                  </>
                                )}
                              </Button>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <Empty />
                  )}
                </div>
              </div>
            )}
            <Pagination
              {...productData.item}
              limit={limit}
              page={productData?.item?.currentPageNumber || 0}
            />
          </Col>

          <Col span={6}>
            <div className="cart-rigth-sidebar">
              {selectedProducts.length ? (
                selectedProducts?.map((product: any) => (
                  <div className="cart-box" key={product?.id}>
                    <Avatar
                      size={55}
                      shape="square"
                      src={product?.productImage}
                    />
                    <div className="ml-2">
                      <small>{product?.name?.substring(0, 40)}</small>
                      <br />
                      <div className="d-flex align-items-center justify-content-between">
                        <b>{product?.variation?.variationName}</b>
                        <small>
                          &#2547;
                          {product?.variation?.price?.discountedPrice ||
                            product?.variation?.price?.mrp}{" "}
                          {product?.variation?.price?.discountedPrice && (
                            <del className="text-danger">
                              &#2547; {product?.variation?.price?.mrp}
                            </del>
                          )}
                        </small>
                      </div>

                      {hasProduct(product.id) && (
                        <span className="cart-action">
                          {product?.quantity !== 1 ? (
                            <Button
                              className="dec-btn"
                              onClick={() => {
                                setSelectedProducts((prevProducts: any) => {
                                  const arr = Array.from(prevProducts);
                                  const newArr = arr.map((item: any) => {
                                    if (item?.id === product?.id) {
                                      if (item && (item as any).quantity > 1) {
                                        return {
                                          ...item,
                                          quantity:
                                            ((item as any).quantity || 0) - 1,
                                        };
                                      } else {
                                        return item;
                                      }
                                    }
                                    return item;
                                  });

                                  return newArr;
                                });
                              }}
                              disabled={product?.quantity < 2}
                            >
                              <MinusOutlined />
                            </Button>
                          ) : (
                            <Button
                              className="close-btn"
                              onClick={async () => {
                                await setSelectedProducts(
                                  (prevProducts: any) => {
                                    const arr = Array.from(prevProducts);
                                    const newArr = arr.filter(
                                      (item: any) => item?.id !== product?.id
                                    );
                                    return newArr;
                                  }
                                );
                              }}
                            >
                              <DeleteOutlined />
                            </Button>
                          )}
                          <Input
                            name="quantity"
                            type="number"
                            value={hasProduct(product.id)?.quantity}
                            onChange={(e) => {
                              const qty = parseInt(e.target.value) || 0;
                              setSelectedProducts((prevProducts: any) => {
                                const arr = Array.from(prevProducts);
                                const newArr = arr.map((item: any) => {
                                  if (item?.id === product?.id) {
                                    if (qty > 0) {
                                      return {
                                        ...item,
                                        quantity: qty,
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                  return item;
                                });

                                return newArr;
                              });
                            }}
                            min={1}
                            minLength={1}
                            disabled={
                              product?.quantity >=
                                product?.variations?.[0]?.stock ||
                              (product?.variations?.[0]?.maximumOrderLimit &&
                                product?.quantity >=
                                  product?.variations?.[0]?.maximumOrderLimit)
                            }
                          />

                          <Button
                            className="inc-btn"
                            onClick={() => {
                              setSelectedProducts((prevProducts: any) => {
                                const arr = Array.from(prevProducts);
                                const newArr = arr.map((item: any) => {
                                  if (item?.id === product?.id) {
                                    if (item && (item as any).quantity > 0) {
                                      return {
                                        ...item,
                                        quantity:
                                          ((item as any).quantity || 0) + 1,
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                  return item;
                                });

                                return newArr;
                              });
                            }}
                            disabled={
                              product?.quantity >=
                                product?.variations?.[0]?.stock ||
                              (product?.variations?.[0]?.maximumOrderLimit &&
                                product?.quantity >=
                                  product?.variations?.[0]?.maximumOrderLimit)
                            }
                          >
                            <PlusOutlined />
                          </Button>
                        </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        open={selectedVisibleProduct}
        onCancel={() => setSelectedVisibleProduct(undefined)}
        footer={false}
        bodyStyle={{ padding: 0, borderRadius: 15 }}
        keyboard
        mask={true}
      >
        <div className="multiple_variation_modal">
          <div className="content_area">
            <Avatar
              size={150}
              shape="square"
              src={selectedVisibleProduct?.productImage}
            />
            <p>{selectedVisibleProduct?.name}</p>
            <Link
              to={`/shops/${
                selectedVisibleProduct?.shop?.id as string
              }/products/${selectedVisibleProduct?.slug}/details`}
              title="View Details"
              // target="_blank"
            >
              View Details
            </Link>
          </div>
          {selectedVisibleProduct?.variations?.length
            ? selectedVisibleProduct?.variations?.map(
                (variant: any, index: string) => (
                  <React.Fragment key={index}>
                    <div className="variation_row">
                      <div className="content">
                        <p>{variant?.variationName?.toUpperCase()}</p>

                        <p>
                          {variant?.price?.currencyCode || "Tk"}{" "}
                          {variant?.price?.discountedPrice ||
                            variant?.price?.mrp}{" "}
                          {variant?.price?.discountedPrice &&
                            variant?.price?.discountedPrice !==
                              variant?.price?.mrp && (
                              <del>
                                {variant?.price?.currencyCode || "Tk"}{" "}
                                {variant?.price?.mrp}
                              </del>
                            )}
                        </p>
                      </div>
                      <Button
                        shape="round"
                        onClick={async () => {
                          await setSelectedProducts((prevProducts: any) => {
                            let arr = Array.from(prevProducts);
                            arr?.find(
                              (item: any) =>
                                item?.id === selectedVisibleProduct?.id &&
                                variant?.variationId ===
                                  item?.variation?.variationId
                            )
                              ? (arr = arr.filter((item: any) => {
                                  if (
                                    !(
                                      item?.id === selectedVisibleProduct?.id &&
                                      variant?.variationId ===
                                        item?.variation?.variationId
                                    )
                                  ) {
                                    return {
                                      ...item,
                                    };
                                  }
                                }))
                              : arr.push({
                                  ...selectedVisibleProduct,
                                  quantity: 1,
                                  variation: variant,
                                });
                            return arr;
                          });
                        }}
                      >
                        {selectedProducts?.find(
                          (item: any) =>
                            item?.variation?.variationId ===
                            variant?.variationId
                        ) ? (
                          <>
                            <DeleteOutlined />{" "}
                            <span className="text-danger">REMOVE</span>
                          </>
                        ) : (
                          <>
                            <ShoppingCartOutlined /> ADD
                          </>
                        )}
                      </Button>
                    </div>
                  </React.Fragment>
                )
              )
            : undefined}
        </div>
      </Modal>
    </div>
  );
};

export default OrderProductList;
