import { authenticateToken } from "../../utils/auth";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleMall from "./Details";
import AddShoppingMall from "./AddShoppingMall";
import moment from "moment";
import React from "react";
const ShoppingMallList = (): ReactElement => {
  const navigate = useNavigate();
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(18);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedMall, setSelectedMall] = useState(undefined);
  const [selectedMallForEdit, setSelectedMallForEdit] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [shoppingMallData, setShoppingMallData] = useState<any>({
    loading: false,
    data: null,
  });
  const withShop = false;
  const getShoppingMall = useCallback(async () => {
    setShoppingMallData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/shopping-mall/?withShop=${withShop}` +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setShoppingMallData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setShoppingMallData({ loading: false, data: [] });
        console.error("Malls: Error", err);
      });
  }, []);

  // const onStatusChange = async (id: any, val: any) => {
  //   setConfirmLoading(true);
  //   if (id) {
  //     await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shopping-mall`, {
  //       method: "PUT",
  //       headers: {
  //         Authorization: `Bearer ${authenticateToken()}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: id,
  //         isActive: val,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.statusCode === 200) {
  //           responseNotification("Status Changed Successfully", "success");
  //           getShoppingMall();
  //           setVisible(false);
  //         } else if (res.status === 500) {
  //           responseNotification("Internal server error", "error");
  //         } else {
  //           responseNotification(res.message || "something wrong", "warning");
  //         }
  //       })
  //       .catch((err) => {
  //         responseNotification(`${"Internal server error"} ${err}`, "error");
  //         console.error("err", err);
  //       });
  //   }
  // };
  //

  useEffect(() => {
    getShoppingMall();
  }, [getShoppingMall]);

  const onClose = () => {
    getShoppingMall();
    setVisible(undefined);
    setSelectedMall(undefined);
    setSelectedMallForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Shopping Mall List"
        subTitle={`${
          shoppingMallData?.data?.totalElements ||
          shoppingMallData?.data?.shoppingMalls?.length ||
          0
        } Mall(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {shoppingMallData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Mall</th>
                      <th scope="col">Address</th>
                      {/* <th scope="col">Status</th> */}
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shoppingMallData?.data?.shoppingMalls?.length ? (
                      shoppingMallData?.data?.shoppingMalls?.map(
                        (mall: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              onClick={() =>
                                navigate(`/shopping-mall/${mall?.id}/details`)
                              }
                            >
                              <Avatar size={40} src={mall?.image} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {mall?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {mall?.address}
                              </span>
                            </td>
                            {/* <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={mall?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(mall?.id, val);
                                }}
                                loading={
                                  confirmLoading && mall?.id === confirmLoading
                                }
                              />
                            </td> */}
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(mall?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(mall?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(mall?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {mall?.updatedBy?.name ||
                                  mall?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => setSelectedMallForEdit(mall)}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...shoppingMallData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Mall"
            : selectedMallForEdit
            ? "Edit Mall"
            : "Mall Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedMall || selectedMallForEdit}
        width={450}
      >
        {visible || selectedMallForEdit ? (
          <AddShoppingMall
            onCloseMethod={onClose}
            visibleData={selectedMallForEdit}
          />
        ) : (
          <SingleMall MallDetails={selectedMall} />
        )}
      </Drawer>
    </>
  );
};

export default ShoppingMallList;
