import MainLayout from "../../components/Layouts";
import UserList from "../../components/Users";

const UserPage = () => {
  return (
    <MainLayout>
      <UserList />
    </MainLayout>
  );
};

export default UserPage;
