import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
const { Option } = Select;

const AddEmployee = ({ visibleData, onCloseMethod }: any) => {
  const { corporateInfo } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState<any>();
  const [employeeData, setEmployeeData] = useState(undefined);

  const [groupOptions, setGroupOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getgroupOptions = useCallback(
    async (val?: string) => {
      setGroupOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/company/admin/all?companyId=${corporateInfo?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setGroupOptions({
        loading: false,
        list: res?.data?.groups?.map((group) => {
          return {
            value: group?.id,
            label: group?.groupName,
          };
        }),
      });
    },
    [corporateInfo?.id]
  );

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      employees: [
        {
          creditLimit: parseFloat(data.creditLimit),
          discountPercentage: parseFloat(data.discountPercentage),
          emailAddress: data.emailAddress,
          mobileNumber: `+88${data.mobileNumber}`,
          name: data.name,
          employeeId: data.employeeId,
          designation: data.designation,
          isActive: true,
        },
      ],
      groupId: groupId,
      // companyId: corporateInfo?.id,
    };

    if (employeeData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          // id: employeeData?.id,
          // isActive: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Employee Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Employee Created Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setEmployeeData(visibleData);
      setGroupId(visibleData?.groupId);
    }
  }, [visibleData]);

  useEffect(() => {
    if (employeeData) {
      form?.resetFields(Object.keys(visibleData));
    }
    form?.resetFields();
  }, [employeeData, form, visibleData]);

  useEffect(() => {
    getgroupOptions();
  }, [getgroupOptions]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...employeeData,
            mobileNumber: employeeData?.mobileNumber?.replace("+88", ""),
          }}
        >
          <Form.Item
            hasFeedback
            label="Employee Group"
            // requiredMark="optional"
            name="groupId"
          >
            <Select
              showSearch
              placeholder="Select Group"
              optionFilterProp="children"
              onChange={(val) => {
                console.log("Val:", val);
                setGroupId(val);
              }}
              onSearch={(e) => {
                console.log(e);
              }}
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {groupOptions?.list?.map((group) => (
                <Option value={group.value}>{group.label}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Employee Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Employee Name" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Employee ID"
            rules={[
              {
                required: true,
                message: "Employee Id is Required!",
              },
            ]}
            name="employeeId"
          >
            <Input
              id="employeeId"
              type="text"
              placeholder="Enter Employee ID"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Designation"
            rules={[
              {
                required: true,
                message: "Designation is Required!",
              },
            ]}
            name="designation"
          >
            <Input
              id="designation"
              type="text"
              placeholder="Enter Employee Designation"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Email is required!",
              },
              // { type: 'string', max: 11, min: 11 },
              {
                validator: async (_, email) => {
                  if (
                    email &&
                    !String(email)
                      .toLowerCase()
                      ?.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                  ) {
                    return Promise.reject(new Error("Enter valid email"));
                  }
                },
              },
            ]}
            name="emailAddress"
          >
            <Input id="emailAddress" type="email" placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Mobile Number"
            rules={[
              {
                required: true,
                message: "Mobile Number is required!",
              },
              {
                validator: async (_, names) => {
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid mobile number")
                    );
                  }
                },
              },
            ]}
            name="mobileNumber"
            initialValue={"01"}
          >
            <Input
              disabled={employeeData?.data}
              id="mobileNumber"
              type="contact"
              addonBefore={"+88"}
              placeholder="Enter Mobile Number"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Credit Limit"
            rules={[
              {
                required: true,
                message: "Credit Limit is required!",
              },
            ]}
            name="creditLimit"
          >
            <Input
              id="creditLimit"
              type="number"
              placeholder="Enter Credi tLimit"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Discount Percentage"
            name="discountPercentage"
          >
            <Input
              id="discountPercentage"
              type="number"
              placeholder="Enter Discount Percentage"
              min={0}
              max={50}
            />
          </Form.Item>

          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => form?.resetFields()}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddEmployee;
