import React from "react";
import MainLayout from "../../components/Layouts";
import MallDetails from "../../components/ShoppingMalls/Details";

const MallDetailsPage = () => {
  return (
    <MainLayout>
      <MallDetails />
    </MainLayout>
  );
};

export default MallDetailsPage;
