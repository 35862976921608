import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Image, Col, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
//import "react-responsive-carousel/lib/styles/carousel.min.css";

const SingleBanner = ({
  EmployeeDetails,
}: {
  EmployeeDetails: any;
}): ReactElement => {
  const [getEmployeeDetails, setEmployeeDetails] = useState(undefined);

  useEffect(() => {
    // EmployeeDetails();
    setEmployeeDetails(EmployeeDetails);
  }, [EmployeeDetails]);

  return (
    <React.Fragment>
      <Row gutter={5}>
        <Col span={24} style={{ background: "#fff" }}>
          <div className="inner-body-wrapper">
            <div className="inner-body">
              <div className="single">
                <div className="info-name">
                  <span>Employee Name</span>
                </div>
                <div className="info-desc">
                  <span>{getEmployeeDetails?.name}</span>
                </div>
              </div>
              <div className="single">
                <div className="info-name">
                  <span>Email Address</span>
                </div>
                <div className="info-desc">
                  <span>{getEmployeeDetails?.emailAddress}</span>
                </div>
              </div>
              <div className="single">
                <div className="info-name">
                  <span>Mobile Number</span>
                </div>
                <div className="info-desc">
                  <span>{getEmployeeDetails?.mobileNumber?.split("+88")}</span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Group</span>
                </div>
                <div className="info-desc">
                  <span>{getEmployeeDetails?.group?.groupName}</span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Credit Limit</span>
                </div>
                <div className="info-desc">
                  <span>&#2547;{getEmployeeDetails?.creditLimit}</span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Discount Percentage</span>
                </div>
                <div className="info-desc">
                  <span>{getEmployeeDetails?.discountPercentage}%</span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Created Date</span>
                </div>
                <div className="info-desc">
                  <span>
                    {moment(getEmployeeDetails?.createdAt).format("lll")}
                  </span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Status</span>
                </div>

                <div className="info-desc">
                  <span
                    className={`radius-btn text-uppercase ${
                      getEmployeeDetails?.isActive === true
                        ? "active"
                        : "inactive"
                    }-btn`}
                  >
                    {getEmployeeDetails?.isActive === true ? (
                      <>
                        <CheckOutlined className="mr-1" /> Active
                      </>
                    ) : (
                      <>
                        {" "}
                        <CloseOutlined className="mr-1" /> Inactive
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
SingleBanner.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default SingleBanner;
