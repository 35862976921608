import SecondLevelCategoryList from "../../components/Category/SecondLevel";
import MainLayout from "../../components/Layouts";

const SecondLevelCategoryPage = () => {
  return (
    <MainLayout>
      <SecondLevelCategoryList />
    </MainLayout>
  );
};

export default SecondLevelCategoryPage;
