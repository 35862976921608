import { Empty, Modal, Tree } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticateToken } from "../../utils/auth";
import Loading from "../common/Loader";
import { responseNotification } from "../../utils/notify";
import _ from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { DirectoryTree } = Tree;

const CategoryReArrangeList = ({ parentCategoryId, level }: any) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [visible, setVisible] = useState(undefined);
  const [categoryId, setCategoryId] = useState<any>();
  const [categoryName, setCategoryName] = useState<any>();
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] =
    useState(undefined);

  const [categoryData, setCategoryData] = useState<any>({
    loading: false,
    data: null,
  });

  // Category List By Parent ID
  const getSubCategory = useCallback(() => {
    setCategoryData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/child-category-by-parent?categoryId=${parentCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setCategoryData({
            loading: false,
            data: data?.data,
          });
        } else {
          setCategoryData({ loading: false, data: null });
          responseNotification(
            data.statusText || "something went wrong",
            "error"
          );
        }
      })
      .catch((err) => {
        setCategoryData({ loading: false, data: null });

        console.log(err);
      });
  }, [parentCategoryId]);

  useEffect(() => {
    getSubCategory();
  }, [getSubCategory]);

  const changePosition = async (pos: any, node: any) => {
    setLoading(true);

    let curr: any;
    let item;

    categoryData?.data?.categories?.find((category: any, i: string) => {
      if (category?.id === node?.key) {
        curr = i;
        item = category;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? categoryData?.data?.categories
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : categoryData?.data?.categories?.slice(0, pos),
        item,
        curr > pos
          ? categoryData?.data?.categories
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, categoryData?.data?.categories?.length - 1)
          : categoryData?.data?.categories?.slice(
              pos,
              categoryData?.data?.categories?.length
            )
      )?.map((item, i) => ({
        categoryId: item?.id,
        position: i + 1,
      }));

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/category/position`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categories: formated,
            level: level + 1,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            getSubCategory();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onDelete = async (id: any) => {
    setConfirmLoading(id);

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/category?categoryId=${
        categoryId ? categoryId : parentCategoryId
      }`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setConfirmLoading(undefined);
        if (res.statusCode === 200) {
          responseNotification("Category Deleted Successfully", "success");
          navigate("/categories");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const handleOk = () => {
    setVisible(undefined);
  };

  const handleCancel = () => {
    setVisible(undefined);
  };

  const onClose = () => {
    setSelectedCategoryForEdit(undefined);
    getSubCategory();
  };

  return (
    <React.Fragment>
      <div className="product-list-item-area white-bg">
        {/* <div className="details-area_">
            <div className="pt-4">
              <div className="inner-body">
                {!categoryData?.data?.categories?.length ? (
                  <div className="single mt-5">
                    <span className="info-name">Action</span>
                    <span className="info-desc">
                      <Popconfirm
                        title="Are you sure to delete this Category Permanently?"
                        onConfirm={(val) => onDelete(val)}
                        onVisibleChange={() => console.log("visible change")}
                      >
                        <Button danger type="primary">
                          <DeleteFilled /> Delete Permanently
                        </Button>
                      </Popconfirm>
                    </span>
                  </div>
                ) : (
                  ``
                )}
              </div>
            </div>
          </div> */}

        <div className="pb-5">
          {categoryData?.loading || loading === true ? (
            <Loading />
          ) : (
            <div className="product-table-area mt-3">
              {categoryData?.data?.categories?.length ? (
                <DirectoryTree
                  height={600}
                  multiple
                  showIcon={false}
                  draggable
                  treeData={categoryData?.data?.categories?.map(
                    (category: any) => ({
                      title: category?.title,
                      key: category?.id,
                      // children: category?.categories?.map((child) => ({
                      //   title: child?.categories?.title,
                      //   key: child?.categories?.id,
                      // })),
                    })
                  )}
                  onDrop={(e) => {
                    changePosition(e.dropPosition, e.dragNode);
                  }}
                  onRightClick={(val: any) => {
                    setCategoryId(val?.node?.key);
                    setCategoryName(val?.node?.title);
                    setVisible(val?.node?.key);
                  }}
                  onSelect={(val: any) => {
                    // console.log("ID :", val?.[0]);
                    setSelectedCategoryForEdit(val);
                  }}
                />
              ) : (
                <Empty />
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        title="Are you sure to Delete Category?"
        open={visible}
        onOk={() => {
          handleOk();
          onDelete(categoryId);
        }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okType={"danger"}
        okText="Delete"
      >
        <div className="alert alert-danger d-flex align-items-center justify-content-between">
          <span className="text-uppercase text-danger">
            Are you sure you want to <b>Delete</b> this category Permanently?
          </span>
          <ExclamationCircleOutlined
            style={{ fontSize: 56, marginLeft: 10, color: "#f00" }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CategoryReArrangeList;
