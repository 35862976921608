import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopLayout from "../Layout";
import ProductList from "../../Product/index";
import { useSelector } from "react-redux";
import { Button } from "antd"; 
import { useNavigate } from "react-router";
import { UploadOutlined } from "@ant-design/icons";

const ShopProductList = (): ReactElement => {
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ShopLayout
        shopId={shopInfo.id}
        title="Product List"
        subTitle="Details" 
        extra={
          <>
            <Button
              shape="round"
              className="px-4 bg-green-500 text-white leading-none flex items-center"
              onClick={() => {
                navigate(`/shops/${shopInfo?.id}/products/bulkupload`);
              }}
            >
              <UploadOutlined /> Bulk Upload
            </Button>
            <Button
              shape="round"
              className="px-4 bg-blue-500 text-white leading-none flex items-center"
              onClick={() => {
                navigate(`/shops/${shopInfo?.id}/products/bulkupdate`);
              }}
            >
              <UploadOutlined /> Bulk Update
            </Button>
          </>
        }
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ProductList inShop={true} getShopId={shopInfo.id} />
          </div>
        </div>
      </ShopLayout>
    </React.Fragment> 
  );
};

ShopProductList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopProductList;
