import { Avatar, Card, Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { getImgUrl } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const { Content } = Layout;

const FeedbackLayout = ({
  children,
  feedbackId,
}: {
  children: ReactElement;
  feedbackId: string;
}): ReactElement => {
  const navigate = useNavigate();
  const { feedbackInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    Feedback Details
                  </h1>
                }
                extra={""}
              />
            </div>

            <div className="col-span-full xl:col-auto">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl mb-6">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0">
                  <img
                    src={feedbackInfo?.customerImage}
                    alt=""
                    className="rounded-xl"
                  />
                  <div className="px-4 pb-2">
                    <div className="xl:-mt-14 flex justify-between items-baseline">
                      <div className="hidden lg:block">
                        <Avatar
                          size={100}
                          shape="circle"
                          src={getImgUrl(feedbackInfo?.customerImage)}
                          className="mb-5 w-20 h-20 shadow-lg border-4 border-white bg-gray-50"
                        />
                      </div>
                    </div>
                    <div>
                      <h4>
                        {feedbackInfo?.customerName?.toUpperCase()}{" "}
                        <small>({feedbackInfo?.customerType})</small>
                      </h4>
                      <span
                        className="radius-btn active-btn px-5"
                        style={{ fontSize: 14 }}
                      >
                        <span>
                          {feedbackInfo?.customerContact?.split("+88")}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Sidebar brandId={brandId} /> */}
            </div>
            <div className="col-span-2">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>{" "}
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

FeedbackLayout.propType = {
  children: PropTypes.element,
};

export default FeedbackLayout;
