import {
  IdcardOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../../../utils/services";

const ShopSidebar = ({ campaignId }: any): ReactElement => {
  const pathname = window.location?.pathname;

  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/products"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/products") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/promotion/campaign/${campaignId}`}>
                Product Management
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/brands"
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/brands") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/promotion/campaign/${campaignId}/brands`}
                className="hover:text-white"
              >
                Brand Management
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/shops"
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/shops") ? "active bg-gray-100" : ""
              }`}
              icon={<ShopOutlined />}
            >
              <Link
                to={`/promotion/campaign/${campaignId}/shops`}
                className="hover:text-white"
              >
                Shop Management
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
