import MainLayout from "../../../components/Layouts";
import PromoCodeList from "../../../components/Promotions/PromoCode";

const PromoCodePage = () => {
  return (
    <MainLayout>
      <PromoCodeList />
    </MainLayout>
  );
};

export default PromoCodePage;
