import { Alert, Button, Card, Divider, Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import SubmitResetBtn from "../common/SubmitBtn";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const AddKeyword = ({ visibleData, onCloseMethod }: any) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [keywordData, setKeywordData] = useState<any>(undefined);
  const [error, setError] = useState("");

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      key: data.key,
      type: type,
    };

    if (keywordData) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/keyword`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: keywordData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Keyword Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/keyword`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Keyword Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setKeywordData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (keywordData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [keywordData]);
  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...keywordData,
        }}
      >
        <Card size="small" style={{ minWidth: "100%", marginBottom: 25 }}>
          <p className="text-center mb-0">
            <b>N.B:</b> Every Key must be seperate by comma(",")
          </p>
        </Card>
        <Form.Item
          hasFeedback
          label="Keyword"
          rules={[
            {
              required: true,
              message: "Key is Required!",
            },
          ]}
          name="key"
          style={{ minWidth: "100%" }}
        >
          <Input.TextArea rows={5} id="key" placeholder="Enter Keyword" />
        </Form.Item>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddKeyword;
