import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import CorporateLayout from "../Layout";
import HubHeader from "../Layout/topHeader";
import { getPage, getParamValue } from "../../../utils/index";
import Loading from "../../common/Loader";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Button, DatePicker, Empty, Form } from "antd";
import Pagination from "../../common/Pagination";
import PaginationTwo from "../../common/PaginationTwo";
import { authenticateToken } from "../../../utils/auth";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "../../../styles/tailwind/List.module.css";
import Loader from "../../common/Loader";
const { RangePicker } = DatePicker;

const CorporateTopupHistoryList = ({ params }: any) => {
  const { corporateInfo } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(16);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [activeSearch, setActiveSearch] = useState(false);
  const startOfMonth = moment.utc().startOf("month").format();
  const endOfMonth = moment.utc().endOf("month").format();
  const [showSearch, setShowSearch] = useState<any>(true);

  const [range, setRange] = useState<any>({
    from: moment.utc().startOf("month")?.toISOString() || null,
    to: moment.utc().endOf("month")?.toISOString() || null,
  });

  const [topupData, setTopupData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTopupHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setTopupData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup/company-history?companyId=${corporateInfo?.ownerId}` +
          (from ? `&from=${from}` : `&from=${startOfMonth}`) +
          (to ? `&to=${to}` : `&to=${endOfMonth}`),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setTopupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTopupData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [range]);

  useEffect(() => {
    getTopupHistory();
  }, [getTopupHistory]);

  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <React.Fragment>
      <CorporateLayout
        corporateId={corporateInfo?.id}
        title="Topup History List"
        subTitle="Topup History List"
      >
        {showSearch && (
          <div className={`${styles.searchBox}`}>
            <Form form={form} layout="inline" className={styles.formInline}>
              <Form.Item name="range">
                <RangePicker
                  defaultValue={
                    range.from != null && range.to != null
                      ? [moment(range.from), moment(range.to)]
                      : null
                  }
                  onChange={(e, f) => {
                    console.log("momnet", moment.utc(f[0])?.toISOString());
                    setRange({ from: f?.[0], to: f?.[1] });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  inputReadOnly
                  allowClear
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    {topupData?.loading ? (
                      <Loader />
                    ) : (
                      <table className={styles.mainTable}>
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Number Of Request</th>
                            <th scope="col">Total Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {topupData?.data?.companyTopUps?.length ? (
                            topupData?.data?.companyTopUps?.map(
                              (item: any, index: any) => (
                                <tr
                                  className="border-t hover:bg-gray-100 cursor-pointer"
                                  key={index}
                                >
                                  <td>
                                    <span className="name">
                                      {moment(item?.createdAt).format("lll")}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="name">
                                      {item?.numberOfRequest}
                                    </span>
                                  </td>

                                  <td>
                                    <span className="name text-right">
                                      &#2547; {item?.totalAmount}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <Empty />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <Pagination
                {...topupData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </div>
        </div>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default CorporateTopupHistoryList;
