import {
  AutoComplete,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  TimePicker,
  Tooltip,
} from "antd";

import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getParamValue, ShopStatus } from "../../utils";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import { useLocation, useNavigate, useParams } from "react-router";
import ImageUploader from "../common/ImageUploader";
import { regions } from "../common/Region";
import ShopLayout from "./Layout";
import SubmitResetBtn from "../common/SubmitBtn";
import MapBox from "../common/MapBox";
const { TextArea } = Input;

import { useDispatch, useSelector } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { SET_SHOP_INFO } from "../../redux/auth/authType";
import moment from "moment";
import React from "react";

const AddShop = () => {
  const { type, shopInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );

  const fetchRef = useRef<any>(0);
  const route = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [startAt, setStartAt] = useState<any>("00:00");
  const [endAt, setEndAt] = useState<any>("00:00");
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState("");
  const [merchantId, setMerchantId] = useState();
  const [shopData, setShopData] = useState<any>({ loading: false, data: null });
  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });

  const [singleMerchanrInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const [logoUrl, setLogoUrl] = useState<string>();
  const [bannerUrl, setBannerUrl] = useState<string>();

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [geoAddress, setGeoAddress] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [locationListOptions, setLocationListOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });
  // **************************************

  // **************************************
  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });

    setLocationListOptions({
      loading: false,
      list: res?.data?.places?.map((place: any) => {
        return {
          value: place?.address,
          label: place?.address,
        };
      }),
    });
  }, []);

  const fetchMerchantDetails = useCallback((getMerchantId: any) => {
    if (getMerchantId) {
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/merchant/merchant/details?merchantId=${getMerchantId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [form, startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (logoUrl || bannerUrl) {
      setLoading(true);

      const readyData = data && {
        address: data.address,
        hubId: data.hubId,
        merchantId: data.merchantId,
        area: data.area,
        // bankInfo: {
        //   accountName: data.accountName,
        //   accountNumber: data.accountNumber,
        //   bankName: data.bankName,
        //   branchName: data.branchName,
        //   routingNumber: data.routingNumber,
        //   swiftCode: data.swiftCode,
        // },
        logo: logoUrl || data.logo,
        banner: bannerUrl || data.banner,
        baseDeliveryCharge: data.baseDeliveryCharge * 1,
        commissionType: data.commissionType,
        commissionAmount: parseFloat(data.commissionAmount),
        subsidy: parseFloat(data.subsidy),
        city: data.city,
        contactNumber: `+88${data?.contactNumber?.replace("+88", "")}`,
        mobileNumber: `+88${data?.mobileNumber?.replace("+88", "")}`,
        country: data.country,
        email: data.email,
        location: {
          latitude: data.latitude * 1,
          longitude: data.longitude * 1,
        },
        displayOrder: parseInt(data.displayOrder),
        description: data.description,
        keywords: data.keywords,
        isFreeDelivery: data.isFreeDelivery || false,
        dineIn: data.dineIn || false,
        pickUp: data.pickUp || false,
        isVerified: data.isVerified || false,
        prepareTime: data.prepareTime * 1,
        name: data.name,
        status: data.status,
        startAt: startAt || data.startAt,
        endAt: endAt || data.endAt,
        type: type,
        minimumAmountForFreeDelivery: freeDelivery
          ? null
          : parseInt(data.minimumAmountForFreeDelivery),
      };

      if (shopData.data) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: !shopData?.data?.hub?.id && shopData?.data?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Shop Updated Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Shop Create Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Shop Logo & Banner required`, "error");
    }
  };

  const getMerchantOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/merchant/all` +
            `?page=${page || 0}` +
            (limit ? `&limit=${limit || 16}` : ``) +
            (type ? `&serviceType=${type}` : ``) +
            (key ? `&nameOrMobileNumber=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setMerchantOptions({
            loading: false,
            list: res.data?.merchants?.map((merchant: any) => ({
              label: `${
                merchant?.fullName?.toUpperCase() ||
                merchant?.merchantPersonalDetail?.name?.toUpperCase()
              } - ${merchant?.mobileNumber?.replace("+88", "")}`,
              value: merchant?.merchantId,
            })),
          });
        })
        .catch((err) => {
          setMerchantOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, type]
  );

  const fetchGeoAddress = useCallback(() => {
    try {
      setGeoAddress({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_MAP_API}/geocode?latitude=${currentLocation?.lat}&longitude=${currentLocation?.lng}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setGeoAddress({
              loading: false,
              data: data?.data?.place,
            });
          } else {
            setGeoAddress({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setGeoAddress({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setGeoAddress({ loading: false, data: null });
      console.log(error, "error");
    }
  }, [currentLocation?.lat, currentLocation?.lng]);

  const fetchShopDetails = useCallback((getShopId: any) => {
    if (getShopId) {
      try {
        setShopData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              const obj: any = {};
              Object.keys(data?.data?.shop).map((key) => {
                if (
                  key === "createdBy" ||
                  key === "updatedBy" ||
                  key === "hub" ||
                  key === "merchant"
                )
                  return;
                const item = data.data.shop?.[key];

                if (item && typeof item === "object") {
                  if (key === "location") {
                    obj["latitude"] = item?.y;
                    obj["longitude"] = item?.x;
                  } else {
                    Object.keys(item).map((key) => {
                      if (key !== "type") {
                        const itemNested = item?.[key];
                        obj[key] = itemNested;
                      }
                    });
                  }
                }
                obj[key] = item;
              });

              setShopData({
                loading: false,
                data: obj,
              });
              setLogoUrl(data?.data?.shop?.logo);
              setBannerUrl(data?.data?.shop?.banner);
              setStartAt(data?.data?.shop?.startAt);
              setEndAt(data?.data?.shop?.endAt);

              dispatch({
                type: SET_SHOP_INFO,
                payload: {
                  shopInfo: {
                    id: data?.data?.shop?.id,
                    name: data?.data?.shop?.name,
                    type: data?.data?.shop?.type,
                    mobileNumber: data?.data?.shop?.mobileNumber,
                    logo: data?.data?.shop?.logo,
                    banner: data?.data?.shop?.banner,
                    rating: data?.data?.shop?.rating,
                    status: data?.data?.shop?.status,
                    hubId: data?.data?.shop?.hub?.id,
                    merchantId: data?.data?.shop?.merchantId,
                    deleted: data?.data?.shop?.deleted,
                  },
                },
              });
            } else {
              setShopData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })

          .catch((err) => {
            setShopData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setShopData({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  const handleSearch = useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef!.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "merchant") getMerchantOptions(value);
        else if (field === "locations") getLocationOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getLocationOptions, getMerchantOptions]);

  useEffect(() => {
    fetchShopDetails((route as any)?.shopId);
  }, [fetchShopDetails]);

  useEffect(() => {
    fetchMerchantDetails(merchantId);
  }, [fetchMerchantDetails, merchantId]);

  // useEffect(() => {
  //   if (shopId) {
  //     fetchShopDetails(shopId);
  //   }
  // }, [fetchShopDetails, shopId]);

  // useEffect(() => {
  //   if (shopData?.data?.isFreeDelivery) {
  //     setFreeDelivery(shopData?.data?.isFreeDelivery || false);
  //   }
  // }, [shopData?.data?.isFreeDelivery]);

  useEffect(() => {
    if (key) {
      getLocationOptions();
    }
  }, [getLocationOptions, key]);

  useEffect(() => {
    getMerchantOptions();
  }, [getMerchantOptions]);

  useEffect(() => {
    if (shopData.data) {
      form.resetFields(Object.keys(shopData.data as any));
      setLogoUrl(shopData?.data?.logo);
      setBannerUrl(shopData?.data?.banner);
      setCurrentLocation({
        lat: shopData?.data?.location?.y,
        lng: shopData?.data?.location?.x,
      });
    }
  }, [form, shopData]);

  useEffect(() => {
    if (
      shopData?.data &&
      currentLocation.lat !== shopData?.data?.location?.y &&
      currentLocation.lng !== shopData?.data?.location?.x
    ) {
      fetchGeoAddress();
    }
  }, [fetchGeoAddress, currentLocation]);

  const resetData = () => {
    // form?.resetFields();
    setLogoUrl(undefined);
    setBannerUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  useEffect(() => {
    form.resetFields(["latitude", "longitude", "address"]);
  }, [currentLocation]);

  useEffect(() => {
    form.resetFields(["address"]);
  }, [geoAddress]);

  useEffect(() => {
    dispatch({
      type: SET_SHOP_INFO,
      payload: {
        shopInfo: null,
      },
    });
  }, []);

  const OPTIONS = [
    {
      label: "15 Min",
      value: 15,
    },
    {
      label: "30 Min",
      value: 30,
    },
    {
      label: "45 Min",
      value: 45,
    },
    {
      label: "60 Min",
      value: 60,
    },
  ];

  console.log("Logo", shopInfo?.logo);

  return (
    <ShopLayout
      shopId={(route as any)?.shopId as string}
      title={shopData.data ? `Shop Edit` : `Shop Add`}
      subTitle={shopData.data ? `Edit Shop` : `Create Shop`}
      extra={
        <>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading"
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading"
            }
          />
        </>
      }
    >
      <div className="content-body rounded-2xl">
        <div className="">
          {" "}
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...shopData.data,
              contactNumber: shopData.data?.contactNumber?.replace("+88", ""),
              mobileNumber: merchantId
                ? singleMerchanrInfo?.data?.mobileNumber?.replace("+88", "")
                : shopData.data?.mobileNumber?.replace("+88", ""),
              email: merchantId
                ? singleMerchanrInfo?.data?.email
                : shopData.data?.email,
              address: geoAddress?.data?.address || shopData.data?.address,
              latitude: currentLocation?.lat,
              longitude: currentLocation?.lng,
            }}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Shop Name"
                    rules={[
                      {
                        required: true,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item hasFeedback label={`Merchant`} name="merchantId">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Merchant"
                      optionFilterProp="children"
                      onChange={(val) => setMerchantId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "merchant");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={merchantOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required!",
                      },
                      {
                        validator: async (_, email) => {
                          if (
                            email &&
                            !String(email)
                              .toLowerCase()
                              ?.match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              )
                          ) {
                            return Promise.reject(
                              new Error("Enter valid email")
                            );
                          }
                        },
                      },
                    ]}
                    name="email"
                  >
                    <Input id="email" type="email" placeholder="Enter Email" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Mobile Number (Login)"
                    rules={[
                      {
                        required: true,
                        message: "Mobile Number is required!",
                      },
                      {
                        validator: async (_, names) => {
                          if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                            return Promise.reject(
                              new Error("Enter valid mobile number")
                            );
                          }
                        },
                      },
                    ]}
                    name="mobileNumber"
                    initialValue={"01"}
                  >
                    <Input
                      disabled={shopData?.data || false}
                      id="mobileNumber"
                      type="contact"
                      addonBefore={"+88"}
                      placeholder="Enter Mobile Number"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                        message: "Contact Number is required!",
                      },
                      {
                        validator: async (_, names) => {
                          if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                            return Promise.reject(
                              new Error("Enter valid contact number")
                            );
                          }
                        },
                      },
                    ]}
                    name="contactNumber"
                    initialValue={"01"}
                  >
                    <Input
                      id="contact"
                      type="contact"
                      addonBefore={"+88"}
                      placeholder="Enter Contact Number"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Activity Status"
                    rules={[
                      {
                        required: true,
                        message: "Status is required!",
                      },
                    ]}
                    name="status"
                  >
                    <Select
                      placeholder="Select Status"
                      optionFilterProp="children"
                      allowClear
                      status={
                        shopData?.status?.toLowerCase() === "Close"
                          ? "error"
                          : shopData?.status?.toLowerCase() === "Busy"
                          ? "warning"
                          : "warning"
                      }
                    >
                      {Object.values(ShopStatus)?.map((status, i) => (
                        <Select.Option value={status} key={i}>
                          {status?.replace("_", " ")}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item hasFeedback label={`Opening Time (${startAt})`}>
                  <TimePicker
                    placeholder="Opening Time"
                    onChange={(val: any) =>
                      setStartAt(moment(val).format("HH:mm"))
                    }
                    // value={startAt}
                    format="HH:mm"
                    className="min-w-full"
                    // defaultPickerValue={moment(startAt).format("lll")}
                    value={moment(`2022-10-10 ${startAt || "00:00"}`)}
                  />
                </Form.Item>

                <Form.Item hasFeedback label={`Closing Time (${endAt})`}>
                  <TimePicker
                    placeholder="Closing Time"
                    onChange={(val: any) =>
                      setEndAt(moment(val).format("HH:mm"))
                    }
                    defaultValue={endAt}
                    format="HH:mm"
                    className="min-w-full"
                    // disabledHours={() => startAt}
                    value={moment(`2022-10-10 ${endAt || "00:00"}`)}
                  />
                </Form.Item>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Commission Type"
                    rules={[
                      {
                        required: false,
                        message: "Commission Type is Required!",
                      },
                    ]}
                    name="commissionType"
                    initialValue={discountType}
                  >
                    <Select
                      options={[
                        { value: null, label: "NONE" },
                        { value: "PERCENTAGE", label: "PERCENTAGE" },
                        { value: "FLAT", label: "FLAT" },
                      ]}
                      placeholder="Enter Commission Type"
                      onChange={(val) => setDiscountType(val)}
                    />
                  </Form.Item>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Form.Item
                    hasFeedback
                    label="Commission Amount"
                    rules={[
                      {
                        required: false,
                        message: "Commission Amount is Required!",
                      },
                    ]}
                    name="commissionAmount"
                  >
                    <Input
                      disabled={!discountType}
                      id="commissionAmount"
                      type="number"
                      min={0}
                      placeholder="Enter Commission Amount"
                    />
                  </Form.Item>

                  <Form.Item hasFeedback label="Subsidy" name="subsidy">
                    <Input
                      id="subsidy"
                      type="number"
                      min={0}
                      placeholder="Enter Subsidy"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Prepare Time"
                    rules={[
                      {
                        required: false,
                        message: "Prepare Time is Required!",
                      },
                      {
                        validator: async (_, name) => {
                          if (name && !(parseFloat(name) > 0)) {
                            return Promise.reject(
                              new Error("Invalid Delivery Time")
                            );
                          }
                        },
                      },
                    ]}
                    name="prepareTime"
                  >
                    <Select options={OPTIONS} />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Display Position"
                    rules={[
                      {
                        required: false,
                        message: "Display shop position  is required!",
                      },
                    ]}
                    name="displayOrder"
                  >
                    <Input
                      id="displayOrder"
                      type="number"
                      min={0}
                      placeholder="Enter Display Position"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Free Delivery?"
                    name="isFreeDelivery"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={shopData?.data?.isFreeDelivery}
                      onChange={setFreeDelivery}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Base Delivery Charge"
                    rules={[
                      {
                        required: true,
                        message: "Delivery Charge is Required!",
                      },
                      {
                        validator: async (_, name) => {
                          if (name && !(parseFloat(name) > 0)) {
                            return Promise.reject(
                              new Error("Invalid Delivery Charge")
                            );
                          }
                        },
                      },
                    ]}
                    name="baseDeliveryCharge"
                  >
                    <Input
                      id="baseDeliveryCharge"
                      type="text"
                      placeholder="Enter Delivery Charge"
                    />
                  </Form.Item>
                </div>

                {!freeDelivery && (
                  <div>
                    <>
                      <Form.Item
                        hasFeedback
                        label="Minimum Amount For Free Delivery"
                        name="minimumAmountForFreeDelivery"
                      >
                        <Input
                          id="minimumAmountForFreeDelivery"
                          type="text"
                          placeholder="Enter Minimum Amount"
                        />
                      </Form.Item>
                    </>
                  </div>
                )}

                <div>
                  <Form.Item hasFeedback label="Keywords" name="keywords">
                    <Input id="keywords" placeholder="Enter Shop keywords" />
                  </Form.Item>
                </div>

                <div className="col-span-2">
                  <Form.Item hasFeedback label="Description" name="description">
                    <TextArea
                      id="description"
                      placeholder="Enter Shop Description"
                      rows={4}
                    />
                  </Form.Item>
                </div>

                {/* <h4 className="title" style={{ maxWidth: "99%" }}>
                Bank Information:
              </h4>
              <Form.Item
                hasFeedback
                label="Account Name"
                rules={[
                  {
                    required: true,
                    message: "Account Name is Required!",
                  },
                ]}
                name="accountName"
              >
                <Input
                  id="accountName"
                  type="text"
                  placeholder="Enter Account Name"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Account Number"
                rules={[
                  {
                    required: true,
                    message: "Account Number is required!",
                  },
                ]}
                name="accountNumber"
              >
                <Input
                  id="accountNumber"
                  type="text"
                  placeholder="Enter Account Number"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Bank Name"
                rules={[
                  {
                    required: true,
                    message: "Bank Name is Required!",
                  },
                ]}
                name="bankName"
              >
                <Input
                  id="bankName"
                  type="text"
                  placeholder="Enter Bank Name"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Branch Name"
                rules={[
                  {
                    required: true,
                    message: "Branch Name is Required!",
                  },
                ]}
                name="branchName"
              >
                <Input
                  id="branchName"
                  type="text"
                  placeholder="Enter Branch Name"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Routing Number"
                rules={[
                  {
                    required: true,
                    message: "Routing Number is Required!",
                  },
                ]}
                name="routingNumber"
              >
                <Input
                  id="routingNumber"
                  type="text"
                  placeholder="Enter Routing Number"
                />
              </Form.Item>

              <Form.Item hasFeedback label="Swift Code" name="swiftCode">
                <Input
                  id="swiftCode"
                  type="text"
                  placeholder="Enter Swift Code"
                />
              </Form.Item> */}

                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <h3 className="mb-4 text-xl font-bold">Address</h3>
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item hasFeedback label="Area" name="area">
                    <Input
                      id="area"
                      type="text"
                      placeholder="Enter shops area"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="City"
                    name="city"
                    initialValue="Dhaka"
                  >
                    {/* <Input id="city" type="text" placeholder="Enter shops city" /> */}

                    <Select
                      showSearch
                      placeholder="Filter by Status"
                      optionFilterProp="children"
                      filterOption={() => {
                        return true;
                      }}
                    >
                      {Object.values(regions.City)?.map((city, i) => (
                        <Select.Option value={city} key={i}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Country"
                    name="country"
                    // initialValue="BD"
                  >
                    <Select
                      id="country"
                      placeholder="Select Country"
                      optionFilterProp="children"
                      allowClear
                    >
                      <Select
                        options={[{ value: "BD", label: "Bangladesh" }]}
                      />
                    </Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Address is required!",
                      },
                    ]}
                  >
                    <AutoComplete
                      autoClearSearchValue={false}
                      allowClear
                      showSearch
                      onSearch={(val) => {
                        handleSearch?.(val, "locations");
                        setKey(val);
                      }}
                      onSelect={(val: any) => {
                        const matchData = locationOptions?.list?.find(
                          (place: any) => val === place.address
                        );
                        setCurrentLocation({
                          lat: matchData?.location?.latitude,
                          lng: matchData?.location?.longitude,
                        });
                      }}
                      options={locationListOptions?.list}
                      notFoundContent={
                        locationListOptions?.loading ? (
                          <Spin size="small" />
                        ) : null
                      }
                      // loading={locationListOptions.loading}
                      placeholder="Search Address"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Latitude"
                    name="latitude"
                    // initialValue={currentLocation?.lat}
                  >
                    <Input id="latitude" type="text" placeholder="23.86450" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Longitude"
                    name="longitude"
                    // initialValue={currentLocation?.lng}
                  >
                    <Input id="longitude" type="text" placeholder="90.53680" />
                  </Form.Item>
                </div>

                <div className="col-span-2">
                  <MapBox
                    draggable={true}
                    coordinate={currentLocation}
                    setCoordinator={(val: any) => setCurrentLocation(val)}
                  />
                </div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <div className="flow-root">
                <h3 className="text-xl font-bold">Extra Options</h3>
                <div className="grid grid-cols-3 gap-x-6 mt-6">
                  {type == "JC_FOOD" && (
                    <>
                      <div>
                        <Form.Item hasFeedback label="Dine In?" name="dineIn">
                          <Switch
                            checkedChildren="Allow"
                            unCheckedChildren="Not Allow"
                            defaultChecked={shopData?.data?.dineIn}
                            // onChange={setFreeDelivery}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item hasFeedback label="PickUp?" name="pickUp">
                          <Switch
                            checkedChildren="Allow"
                            unCheckedChildren="Not Allow"
                            defaultChecked={shopData?.data?.pickUp}
                            // onChange={setFreeDelivery}
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}
                  <div>
                    <Form.Item
                      hasFeedback
                      label="isVerified?"
                      name="isVerified"
                    >
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={shopData?.data?.isVerified}
                        // onChange={setFreeDelivery}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4 xl:mb-0">
              <div className="flow-root">
                <h3 className="text-xl font-bold">Images</h3>

                <div className="grid grid-cols-2 mt-2">
                  <div className="mr-4">
                    <Divider orientation="left">
                      Logo
                      <Tooltip
                        placement="bottom"
                        title={
                          "Shop Logo should be square. We prefer height 200px and width 200px (Ratio 1)"
                        }
                      >
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>
                    <ImageUploader
                      imgUrl={logoUrl || ""}
                      startUpload={startUpload}
                      setStartUpload={(val: any) => {
                        setStartUpload(val);
                      }}
                      setGeneratedImgUrl={(url: any) => {
                        console.log("URL", url);
                        setLogoUrl(url);
                        dispatch({
                          type: SET_SHOP_INFO,
                          payload: {
                            shopInfo: {
                              logo: url,
                              // banner: data?.data?.shop?.banner,
                            },
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="">
                    <Divider orientation="left">
                      Banner
                      <Tooltip
                        placement="bottom"
                        title={
                          "Shop Banner image should 1200px/500px (Ratio 2.4)"
                        }
                      >
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={bannerUrl || ""}
                      startUpload={startUpload}
                      setStartUpload={(val: any) => setStartUpload2(val)}
                      setGeneratedImgUrl={(url: any) => setBannerUrl(url)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </ShopLayout>
  );
};

export default AddShop;
