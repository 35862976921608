import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import VatTaxDeliveryReport from "../../components/Reports/VatTaxDeliveryReport";
const VatTaxDeliveryReportPage = (): ReactElement => {
  return (
    <MainLayout>
      <VatTaxDeliveryReport />
    </MainLayout>
  );
};

export default VatTaxDeliveryReportPage;
