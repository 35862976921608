import { authenticateToken } from "../../../utils/auth";
import { DownOutlined, EditOutlined, SwapOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Drawer, Empty, Skeleton, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { responseNotification } from "../../../utils/notify";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleBanner from "./Details";
import AddTag from "./AssignTags";
import moment from "moment";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
const TagList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  //const [type, setType] = useState("JC_FOOD");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [selectedTagForEdit, setSelectedTagForEdit] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);

  const [tagData, setTagData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTags = useCallback(async () => {
    setTagData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/tag-shop?shopId=${getShopId}` +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setTagData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTagData({ loading: false, data: [] });
        console.error("Tags: Error", err);
      });
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    tagData?.data?.tags?.find((tag: any, i: string) => {
      if (tag?.id === node?.key) {
        curr = i;
        item = tag;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? tagData?.data?.tags
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : tagData?.data?.tags?.slice(0, pos),
        item,
        curr > pos
          ? tagData?.data?.tags
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, tagData?.data?.tags?.length - 1)
          : tagData?.data?.tags?.slice(pos, tagData?.data?.tags?.length)
      )?.map((item, i) => ({
        tagId: item?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/tag-shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tags: formated,
          shopId: getShopId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getTags();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onClose = () => {
    getTags();
    setVisible(undefined);
    setSelectedTag(undefined);
    setSelectedTagForEdit(undefined);
    setReArrangeDrawer(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Tag List"
        subTitle={`${
          tagData?.data?.totalElements || tagData?.data?.tags?.length || 0
        } Tag(s)`}
        extra={[
          <Button
            shape="round"
            type="primary"
            className="bg-blue-600"
            onClick={() => setReArrangeDrawer(true)}
          >
            <SwapOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {tagData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tagData?.data?.tags?.length ? (
                      tagData?.data?.tags?.map((tag: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          // onClick={() => setSelectedTag(tag)}
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {tag?.name}
                            </span>
                          </td>

                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(tag?.updatedAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(tag?.updatedAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(tag?.updatedAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {tag?.updatedBy?.name ||
                                tag?.updatedBy?.mobileNumber}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...tagData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Re Arrange Shop Tag"
        placement="right"
        onClose={onClose}
        visible={reArrangeDrawer}
        width={450}
      >
        {tagData?.loading ? (
          <Skeleton />
        ) : tagData?.data?.tags?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={tagData?.data?.tags?.map((tag: any) => ({
              title: tag?.name,
              key: tag?.id,
            }))}
            onDrop={(e: any) => {
              changeOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>

      <Drawer
        title={
          visible ? "Add Tag" : selectedTagForEdit ? "Edit Tag" : "Tag Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedTag || selectedTagForEdit}
        width={450}
      >
        {visible || selectedTagForEdit ? (
          <AddTag onCloseMethod={onClose} visibleData={selectedTagForEdit} />
        ) : (
          <SingleBanner BannerDetails={selectedTag} />
        )}
      </Drawer>
    </>
  );
};

export default TagList;
