import PropTypes from "prop-types";
import React, { useRef, useState, ReactElement } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
//import { useHistory, useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Form, Select, Button, Upload, Space } from "antd";
import Papa from "papaparse";
import styles from "../../../styles/tailwind/List.module.css";
import axios from "axios";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import builkproductcsv from "../../../demo-files/shop_products.csv";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
const { Option } = Select;

const BulkProductUpload = () => {
  const { shopInfo } = useSelector((state) => state?.authReducer);
  const shopId = shopInfo?.id;
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);
  //State to store the values
  const [values, setValues] = useState([]);
  const history = useNavigate();
  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        // Parsed Data Response in array format
        setParsedData(results.data);
        // Filtered Column Names
        setTableRows(rowsArray[0]);
        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  const handleOnSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);
    const readyData = e && {
      productAddRequestRests: parsedData?.map((product, index) => ({
        barCode: product?.barCode,
        name: product?.name,
        type: shopInfo?.type,
        isActive: product?.isActive,
        excludeFromPromo: Boolean(product?.excludeFromPromo.toLowerCase()),
        bookingEnabled: Boolean(product?.bookingEnabled.toLowerCase()),
        brandId: product?.brandId,
        campaignId: product?.campaignId,
        categoryId: product?.categoryId,
        supplierId: product?.supplierId,
        description: product?.description,
        productImage: product?.productImage,
        images: [product?.images],
        displayOrder: parseInt(product?.displayOrder),
        isPopular: Boolean(product?.isPopular.toLowerCase()),
        keyword: product?.keyword,
        metaDescription: product?.metaDescription,
        metaTitle: product?.metaTitle,
        sku: product?.sku,
        shopId: shopInfo?.id,
        variations: [
          {
            imageUrl: product.imageUrl,
            barCode: product.variationBarCode,
            maximumOrderLimit: parseInt(product?.maximumOrderLimit),
            price: {
              currencyCode: product.currencyCode,
              mrp: parseFloat(product.mrp) * 1,
              tp: parseFloat(product.tp) * 1,
            },
            productDiscount: {
              flat: parseInt(product.flat) || 0,
              percentage: parseFloat(product.percentage) || 0,
            },
            quantitativeProductDiscount: {
              freeProductId: product.freeProductId,
              minimumQuantity: parseInt(product.minimumQuantity) || 0,
              productDiscount: {
                flat: parseFloat(product?.productDiscountFlat) || 0,
                percentage: parseFloat(product?.productDiscountPercentage) || 0,
              },
            },
            regularVariationId: product.regularVariationId,
            stock: parseFloat(product.stock) || 0,
            variationId: product.variationId,
            variationName: product.variations,
          },
        ],
      })),
    };

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/product/bulk-add`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        console.log("product upload status :", res.statusCode);
        console.log("product upload status :::", res.status);
        if (res.statusCode === 200) {
          responseNotification("Product Bulk upload Successfully", "success");
          // window.location.href = "/shops";
          // history.push(`/shops`);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });

    // console.log("product List array : ", parsedData);
    // alert("okey");
  };

  const simpleFileDownload = () => {
    window.location.href = `${builkproductcsv}`;
  };
  //add new excl file end

  return (
    <React.Fragment>
      <ShopLayout shopId={shopInfo.id} title="Product List" subTitle="Details">
        <>
          <section className="product-lists">
            <div className="product-list-item-area white-bg">
              <BreadCrumb
                title="Bulk Product List"
                extra={[
                  <Button
                    shape="round"
                    type="primary"
                    className="bg-blue-600"
                    onClick={() => setReArrangeDrawer(true)}
                  ></Button>,
                  <div className="d-flex">
                    <Button
                      type="dashed"
                      className="mx-2"
                      onClick={simpleFileDownload}
                    >
                      <DownloadOutlined /> Sample File
                    </Button>
                  </div>,
                ]}
              /> 

              <div className="content-body_ rounded-2xl">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
                  <div
                    className="single-product-wrapper"
                    style={{ padding: "5px" }}
                  >
                    <div className="single-product-heading major">
                      {/* File Uploader */}
                      <input
                        type="file"
                        name="file"
                        onChange={changeHandler}
                        accept=".csv"
                        style={{ display: "block", margin: "20px auto" }}
                      />
                    </div>
                  </div>

                  <div className={styles.contentWrapper}>
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              {tableRows.map((rows, index) => {
                                return <th key={index}>{rows}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {values.map((value, index) => {
                              return (
                                <tr key={index}>
                                  {value.map((val, i) => {
                                    return <td key={i}>{val}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Space>
                      <Button
                        shape="round"
                        type="dashed"
                        danger
                        onClick={(e) => {
                          handleOnSubmit(e);
                        }}
                        disabled={parsedData.length < 1}
                        style={{ margin: "30px" }}
                      >
                        <UploadOutlined /> Upload Product
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </ShopLayout>
    </React.Fragment>
  );
};

export default BulkProductUpload;
