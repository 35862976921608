import { ReactElement } from "react";
import DriverCashCollect from "../../../components/Delivery/DeliveryHero/CashCollection";
import MainLayout from "../../../components/Layouts";

const DriversCashCollectPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverCashCollect />
    </MainLayout>
  );
};

export default DriversCashCollectPage;
