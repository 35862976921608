import { DatePicker, Form, Input, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import SubmitResetBtn from "../../common/SubmitBtn";
const { Option } = Select;

const AddTimeSlot = ({ visibleData, onCloseMethod }: any) => {
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [date, setDate] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [slotData, setSlotData] = useState<any>(undefined);
  const [serviceLocationOptions, setServiceLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      availableSlot: parseInt(data.availableSlot),
      date: moment(data.date),
      fromHour: moment(data.fromHour).format("hh:mm"),
      toHour: moment(data.toHour).format("hh:mm"),
      serviceLocation: data.serviceLocation,
    };

    if (slotData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/dm-time-slot`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: slotData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Slot Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/dm-time-slot`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Slot Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getLocationOptions = useCallback(async (name: any) => {
    setServiceLocationOptions({ loading: false, list: [] });

    setServiceLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;

    const res = await axios.get(
      `${encodedUri}/admin/service-location/all?` +
        (name ? `&name=${name}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );
    setServiceLocationOptions({
      loading: false,
      list: res?.data?.serviceLocations?.map((product: { name: any }) => {
        return {
          value: product?.name,
          label: product?.name,
        };
      }),
    });
  }, []);

  const fetchRef = useRef<any>(null);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "locations") getLocationOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getLocationOptions]);

  useEffect(() => {
    getLocationOptions("");
  }, [getLocationOptions]);

  useEffect(() => {
    if (visibleData) {
      setSlotData(visibleData);
      setDate(visibleData?.date);
    }
  }, [visibleData]);

  useEffect(() => {
    if (slotData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [slotData, form, visibleData]);

  // **********************************

  return (
    <Form
      name="control-hooks"
      autoComplete="off"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className="ant-form ant-form-vertical"
      onFinish={onSubmit}
      form={form} // like ref
      layout="vertical"
      initialValues={{
        ...slotData,
        date: moment(date),
        fromHour: moment(
          new Date(moment(date).valueOf()).setHours(
            visibleData?.fromHour?.split(":")[0] * 1,
            visibleData?.fromHour?.split(":")[1] * 1
          )
        ),
        toHour: moment(
          new Date(moment(date).valueOf()).setHours(
            visibleData?.toHour?.split(":")[0] * 1,
            visibleData?.toHour?.split(":")[1] * 1
          )
        ),
      }}
    >
      <div className="grid grid-cols-2 xl:gap-x-4">
        <div className="col-span-2">
          <Form.Item
            hasFeedback
            label="Service Location"
            rules={[
              {
                required: true,
                message: "Service Location is Required!",
              },
            ]}
            name="serviceLocation"
          >
            <Select
              showSearch
              placeholder="Select Product"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "locations");
              }}
              filterOption={() => {
                return true;
              }}
              allowClear
            >
              {serviceLocationOptions?.list?.map(
                (
                  location: {
                    value: any;
                    label:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  i: React.Key | null | undefined
                ) => (
                  <Option value={location.value} key={i}>
                    {location.label}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Available Slot"
            rules={[
              {
                required: true,
                message: "Available Slot is Required!",
              },
            ]}
            name="availableSlot"
          >
            <Input
              id="availableSlot"
              type="number"
              placeholder="Enter Available Slot"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Date"
            rules={[
              {
                required: true,
                message: "Date is Required!",
              },
            ]}
            name="date"
          >
            <DatePicker picker="date" style={{ minWidth: "100%" }} />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="From Hour"
            rules={[
              {
                required: true,
                message: "From Hour is Required!",
              },
            ]}
            name="fromHour"
          >
            <DatePicker picker="time" style={{ minWidth: "100%" }} />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="To Hour"
            rules={[
              {
                required: true,
                message: "To Hour is Required!",
              },
            ]}
            name="toHour"
          >
            <DatePicker picker="time" style={{ minWidth: "100%" }} />
          </Form.Item>
        </div>

        <div className="mt-2">
          <SubmitResetBtn
            onClickReset={() => {
              form?.resetFields();
            }}
            disabled={loading || !!error}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  );
};

export default AddTimeSlot;
