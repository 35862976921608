import {
  Rate,
  Dropdown,
  Menu,
  Modal,
  Tabs,
  Avatar,
  Drawer,
  Button,
} from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import CorporateLayout from "../Layout";
import { useDispatch } from "react-redux";
import { SET_CORPORATE_INFO } from "../../../redux/auth/authType";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ShopStatus } from "../../../utils";
import MapBox from "../../common/MapBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import AddCorporate from "../AddCorporate";
const { confirm } = Modal;
const { TabPane } = Tabs;

const CorporateDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const route = useParams();
  const [selectedCorporateForEdit, setSelectedCorporateForEdit] =
    useState<any>(undefined);
  const [visible, setVisible] = useState<any>(false);
  const [singleCorporateInfo, setSingleCorporateInfo] = useState<any>({
    loading: false,
    data: null,
  });

  console.log("corporate info :", singleCorporateInfo);
  const fetchCorporateDetails = useCallback((getCorporateId: any) => {
    if (getCorporateId) {
      try {
        setSingleCorporateInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/company/details?companyId=${getCorporateId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCorporateInfo({
                loading: false,
                data: data?.data?.company,
              });
              dispatch({
                type: SET_CORPORATE_INFO,
                payload: {
                  corporateInfo: {
                    id: data?.data?.company?.id,
                    ownerId: data?.data?.company?.ownerId,
                    name: data?.data?.company?.name,
                    image: data?.data?.company?.image,
                    balance: data?.data?.company?.balance,
                    isActive: data?.data?.company?.isActive,
                  },
                },
              });
            } else {
              setSingleCorporateInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCorporateInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleCorporateInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchCorporateDetails((route as any)?.corporateId);
  }, [fetchCorporateDetails]);
  const onClose = () => {
    setSelectedCorporateForEdit(null);
    fetchCorporateDetails((route as any)?.corporateId);
  };
  const corporate = singleCorporateInfo?.data;

  return (
    <React.Fragment>
      <CorporateLayout
        corporateId={corporate?.id}
        title={`Corporate Details`}
        subTitle={`Details`}
        extra={<div className="bg-white shadow-md rounded-md p-1"></div>}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <Tabs
              tabPosition="top"
              tabBarExtraContent={
                <Button
                  type="primary"
                  onClick={() => setSelectedCorporateForEdit(corporate?.id)}
                  danger
                >
                  <EditOutlined /> Edit
                </Button>
              }
            >
              <TabPane tab="CORPORATE DETAILS" key="General">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div>
                    <dt className="text-sm font-medium text-gray-900">ID</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.id}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      ownerId
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.ownerId}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Corporate Name
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.name}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Industry
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.industry}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Number Of Employee
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.numberOfEmployee}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">Type</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.type?.replace("_", " ")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">City</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.city}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Address
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.address}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Street
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {corporate?.street}
                    </dd>
                  </div>

                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Status
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <span
                        className={`radius-btn text-uppercase ${
                          corporate?.isActive === true ? "active" : "inactive"
                        }-btn`}
                      >
                        {corporate?.isActive === true ? (
                          <>
                            <CheckOutlined className="mr-1" /> Active
                          </>
                        ) : (
                          <>
                            {" "}
                            <CloseOutlined className="mr-1" /> Inactive
                          </>
                        )}
                      </span>
                    </dd>
                  </div>
                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Reason For Choosing
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{corporate?.reasonForChoosing}</p>
                    </dd>
                  </div>
                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Spokesman Name
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{corporate?.spokesmanName}</p>
                    </dd>
                  </div>
                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Spokesman Email
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{corporate?.spokesmanEmail}</p>
                    </dd>
                  </div>
                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Spokesman Mobile Number
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{corporate?.spokesmanMobileNumber}</p>
                    </dd>
                  </div>
                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Created Date
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <span className="info-desc">
                        {moment(corporate?.createdAt).format("lll")}
                      </span>
                    </dd>
                  </div>
                </dl>
              </TabPane>
            </Tabs>
          </div>
          <Drawer
            title={selectedCorporateForEdit ? "Edit Company" : ""}
            width={600}
            onClose={() => {
              setSelectedCorporateForEdit(null);
            }}
            visible={selectedCorporateForEdit}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            {selectedCorporateForEdit ? (
              <AddCorporate
                visibleData={selectedCorporateForEdit}
                onCloseMethod={onClose}
              />
            ) : undefined}
          </Drawer>
        </div>
      </CorporateLayout>
    </React.Fragment>
  );
};

CorporateDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CorporateDetails;
