import React, { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import PushNotifications from "../../components/Notification";

const PushNotificationPage = (): ReactElement => {
  return (
    <MainLayout>
      <PushNotifications />
    </MainLayout>
  );
};

export default PushNotificationPage;
