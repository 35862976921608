import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
  Switch,
  Tag,
  TreeSelect,
  TreeSelectProps,
  DatePicker,
  Popconfirm,
} from "antd";
import axios from "axios";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { CSVLink } from "react-csv";

import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../common/LoaderFull";
import { debounce } from "lodash";
import { DefaultOptionType } from "antd/es/select";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import QuickUpdate from "./QuickUpdate";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
const { RangePicker } = DatePicker;

const ProductList = ({
  inShop = false,
  inBrand = false,
  inMerchant = false,
  getShopId,
  getCategoryId,
  getBrandId,
  getMerchantId,
}: {
  inShop?: boolean;
  inBrand?: boolean;
  inMerchant?: boolean;
  getShopId?: string;
  getCategoryId?: string;
  getBrandId?: string;
  getMerchantId?: string;
}): ReactElement => {
  const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams();
  const history = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const pageType: any = getParamValue(loc.search, "page-type");

  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");
  const [shopId, setShopId] = useState<any>(getShopId);
  const [merchantId, setMerchantId] = useState<any>(getMerchantId);
  const [categoryId, setCategoryId] = useState<any>(getCategoryId);
  const [brandId, setBrandId] = useState<any>(getBrandId);
  const [campaignId, setCampaignId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [productDataForDownload, setProductDataForDownload] = useState<any>({
    loading: false,
    data: null,
  });

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    setCampaignId((route as any).campaignId || "");
  }, [route]);

  useEffect(() => {
    setBrandId((route as any)?.brandId || "");
  }, [route]);
  const isDeleted = false;
  const getProductsForDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    if (typeof campaignId === "string") {
      setProductDataForDownload({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=2000` +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (merchantId ? `&merchantId=${merchantId}` : ``) +
        (from ? `&startDate=${from}` : ``) +
        (to ? `&endDate=${to}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);
      axios
        .get(`${encodedUrl}/admin/product/v2${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductDataForDownload({ loading: false, data: res.data });
        })
        .catch((err) => {
          setProductDataForDownload({ loading: false, data: [] });
          console.error("Download Data: Error", err);
        });
    }
  }, [
    shopId,
    categoryId,
    merchantId,
    brandId,
    campaignId,
    range,
    isActive,
    page,
    limit,
  ]);

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    if (typeof campaignId === "string") {
      setProductData({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}` +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (merchantId ? `&merchantId=${merchantId}` : ``) +
        (key ? `&key=${key}` : ``) +
        (from ? `&startDate=${from}` : ``) +
        (to ? `&endDate=${to}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``) +
        (page
          ? `&page=${
              page == productData.data?.currentPageNumber ? 0 : page || 0
            }`
          : ``) +
        (limit ? `&limit=${limit}` : ``);
      url = encodeURI(url);
      axios
        .get(
          `${encodedUrl}/admin/product/${pageType ? pageType : `v2`}/${url}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setProductData({ loading: false, data: [] });
          console.error("Type: Error", err);
        });
    }
  }, [
    shopId,
    categoryId,
    merchantId,
    brandId,
    campaignId,
    range,
    isActive,
    key,
    pageType,
    page,
    limit,
  ]);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, campaignId, isActive, key, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      })),
    });
  }, []);

  // ************************
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getParentCategory = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/parent-by-type?type=${type}&page=0&limit=50` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTreeData(
      res.data?.categories?.map(
        (category: Omit<DefaultOptionType, "label">) => ({
          ...category,
          id: category?.id,
          pId: 0,
          title: category?.title,
          value: category?.id,
          isLeaf: false,
        })
      )
    );
  }, []);

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
  };

  const filteredCategory = treeData.find((category) => {
    return category?.id === categoryId;
  });

  // ************************
  const onProductRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Product Remove Successfully", "success");
            getProducts();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //
  const onStatusChange = async (
    id: SetStateAction<undefined>,
    val: boolean
  ) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getProducts();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions, getCampaignOptions]);

  // useEffect(() => {
  //   if (brandId) {
  //     getProducts();
  //   }
  // }, [getProducts, brandId]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getParentCategory(``);
      getBrandsOptions("");
      getCampaignOptions();
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setShopId("");
    setCategoryId("");
    setBrandId("");
    setCampaignId("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };

  function UnLoadWindow() {
    console.log("Un Load Window");
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
        campaignId: campaignId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        UnLoadWindow();
        return "";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  const onClose = () => {
    getProducts();
    setSelectedProductForEdit(undefined);
  };

  const downloadProduct = () => {
    getProductsForDownload();
    Modal.success({
      title: "Ready to Download!",
      okButtonProps: { danger: true },
      okText: "Close",
      onOk() {},

      content: (
        <div>
          <p className="mb-2 mt-6 text-red-600">
            Download {productDataForDownload?.data?.products?.length} items of{" "}
            {productDataForDownload?.data?.totalElements}
          </p>
          <p className="mb-4">Max Download Limit 2k</p>

          <CSVLink data={productDataForDownload?.data?.products}>
            <Button shape="round" type="dashed">
              <DownloadOutlined /> Download
            </Button>
          </CSVLink>
        </div>
      ),
    });
  };

  return (
    <>
      {!inBrand && !inMerchant && (
        <BreadCrumb
          title="Product List"
          subTitle={`${productData?.data?.totalElements} Product(s)`}
          childComponent={inShop ? true : false}
          extra={[
            !pageType && (
              <Button
                type="dashed"
                shape="circle"
                onClick={() => setShowSearch(!showSearch)}
                key={1}
              >
                <SearchOutlined />
              </Button>
            ),

            inShop && productData?.data?.products?.length && (
              <Button
                shape="round"
                type="dashed"
                onClick={downloadProduct}
                loading={productDataForDownload?.loading}
              >
                Download
              </Button>
            ),

            isAllowedService(`Products Create`) && !inBrand && (
              <AddBtn
                onClick={() => {
                  navigate(
                    inShop
                      ? `/shops/${getShopId}/products/create`
                      : `/products/create`
                  );
                }}
              />
            ),
          ]}
        />
      )}

      {showSearch && !pageType && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            {!inShop && (
              <Form.Item name="shop_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Shop"
                  optionFilterProp="children"
                  onChange={(val) => setShopId(val)}
                  onSearch={(e) => handleSearch(e, "shop")}
                  filterOption={() => {
                    return true;
                  }}
                  options={shopOptions?.list}
                ></Select>
              </Form.Item>
            )}

            {/* <Form.Item name="category_search">
              <TreeSelectTwo />
            </Form.Item> */}

            <Form.Item name="category_search">
              <TreeSelect
                allowClear
                treeDataSimpleMode
                style={{ width: "100%" }}
                value={categoryId}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Filter by Category"
                onChange={onChange}
                loadData={onLoadData}
                treeData={treeData}
              />
            </Form.Item>

            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>

            <Form.Item name="campaign_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Campaign"
                optionFilterProp="children"
                onChange={(val) => setCampaignId(val)}
                onSearch={(e) => handleSearch(e, "campaign")}
                filterOption={() => {
                  return true;
                }}
                options={campaignOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(_e, f) => {
                  // console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Price & Stock</th>
                      {!inBrand && (
                        <>
                          {isAllowedService(`Products Edit`) && (
                            <th scope="col">Status</th>
                          )}
                          <th scope="col">Action</th>
                          <th scope="col">Ratings</th>
                          <th scope="col">Created Info</th>
                          <th scope="col">Updated Info</th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   e.stopPropagation();
                              //   navigate(
                              //     `${
                              //       inShop ? `/shops/${getShopId}` : ``
                              //     }/products/${product?.slug}/details`
                              //   );
                              //   dispatch({
                              //     type: SHOW_SEARCHBAR,
                              //     payload: {
                              //       shopId: shopId,
                              //       categoryId: categoryId,
                              //       brandId: brandId,
                              //       campaignId: campaignId,
                              //     },
                              //   });
                              // }}
                              className="flex items-center gap-2"
                            >
                              <a
                                href={liveUrl + `products/` + product?.slug}
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <Avatar
                                  size={45}
                                  src={product?.productImage}
                                  shape="square"
                                />
                              </a>
                              <div className="flex flex-col">
                                <span className="font-medium text-gray-500">
                                  {product?.name?.slice(0, 50)}
                                </span>
                                {product?.barCode && (
                                  <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                    <BarcodeOutlined /> {product?.barCode}
                                  </span>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="flex_">
                                {product?.variations?.length
                                  ? product?.variations?.map(
                                      (variant: any, index: string) => (
                                        <React.Fragment key={index}>
                                          <div className="flex justify-content-start mb-1">
                                            <Tag
                                              color={
                                                variant?.stock < 10
                                                  ? `red`
                                                  : `green`
                                              }
                                            >
                                              {variant?.variationName?.toUpperCase()}
                                              : <b>{variant?.stock || 0}</b>
                                            </Tag>

                                            <Tag>
                                              MRP {variant?.price?.currencyCode}
                                              {variant?.price
                                                ?.discountedPrice ||
                                                variant?.price?.mrp}
                                              {variant?.price
                                                ?.discountedPrice &&
                                                variant?.price
                                                  ?.discountedPrice !==
                                                  variant?.price?.mrp && (
                                                  <span className="text-red-600 ml-1">
                                                    <del>
                                                      {variant?.price
                                                        ?.currencyCode || "Tk"}
                                                      {variant?.price?.mrp}
                                                    </del>
                                                  </span>
                                                )}
                                            </Tag>
                                            <Tag>
                                              TP {variant?.price?.tp}{" "}
                                              {variant?.price?.currencyCode}
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>

                            {!inBrand && (
                              <>
                                {isAllowedService(`Products Edit`) && (
                                  <td>
                                    <Switch
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      defaultChecked={product?.isActive}
                                      onChange={(val, e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onStatusChange(product?.id, val);
                                      }}
                                      className="mr-1"
                                      loading={
                                        confirmLoading &&
                                        product?.id === confirmLoading
                                      }
                                      disabled={
                                        product?.shop?.deleted ||
                                        product?.status === "REJECTED"
                                      }
                                    />
                                  </td>
                                )}
                                <td>
                                  <div className="flex flex-grow gap-1">
                                    {/* <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      dispatch({
                                        type: SHOW_SEARCHBAR,
                                        payload: {
                                          shopId: shopId,
                                          categoryId: categoryId,
                                          brandId: brandId,
                                          campaignId: campaignId,
                                        },
                                      });
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button> */}
                                    <Link
                                      title="Edit Product"
                                      to={
                                        inShop
                                          ? `/shops/${getShopId}/products/${product?.slug}/details`
                                          : `/products/${product?.slug}/details`
                                      }
                                      target={
                                        type == "JC_FOOD" ? "_self" : "_blank"
                                      }
                                      className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                    >
                                      <EyeOutlined />
                                    </Link>
                                    {isAllowedService(`Products Edit`) && (
                                      <>
                                        <Link
                                          title="Edit Product"
                                          to={
                                            inShop
                                              ? `/shops/${getShopId}/products/${product?.slug}/edit`
                                              : `/products/${product?.slug}/edit`
                                          }
                                          target={
                                            type == "JC_FOOD"
                                              ? "_self"
                                              : "_blank"
                                          }
                                          className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                        >
                                          <EditOutlined />
                                        </Link>
                                        <Button
                                          shape="round"
                                          type="primary"
                                          danger
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedProductForEdit(product);
                                          }}
                                        >
                                          <FileDoneOutlined />
                                        </Button>
                                        {isAllowedService(
                                          `Categories Delete`
                                        ) && (
                                          <Popconfirm
                                            placement="left"
                                            title="Are you sure to remove?"
                                            visible={
                                              product?.id === visiblePopconfirm
                                            }
                                            onConfirm={(e: any) => {
                                              console.log(true);
                                              e.preventDefault();
                                              e.stopPropagation();
                                              onProductRemove(product?.id);
                                            }}
                                            okButtonProps={{
                                              loading: confirmLoading,
                                              className: "bg-blue-400",
                                            }}
                                            onCancel={handleCancel}
                                          >
                                            <Button
                                              className="bg-white"
                                              type="dashed"
                                              danger
                                              shape="round"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                showPopconfirm(product?.id);
                                              }}
                                            >
                                              <DeleteOutlined />
                                            </Button>
                                          </Popconfirm>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <Rate value={product?.rating || 1} disabled />
                                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                    {product?.numberOfRating || 0}
                                  </span>
                                </td>
                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {product?.createdBy?.name ||
                                      product?.createdBy?.mobileNumber?.replace(
                                        "+88",
                                        ""
                                      )}
                                    {" - "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        product?.createdAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(product?.createdAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(product?.createdAt).format("lll")}
                                  </span>
                                </td>

                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {product?.updatedBy?.name ||
                                      product?.updatedBy?.mobileNumber?.replace(
                                        "+88",
                                        ""
                                      )}
                                    {" - "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        product?.updatedAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(product?.updatedAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(product?.updatedAt).format("lll")}
                                  </span>
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Quick Update"
        onCancel={onClose}
        open={selectedProductForEdit}
        width={700}
        centered
        footer={false}
      >
        <QuickUpdate
          onCloseMethod={onClose}
          visibleData={selectedProductForEdit}
        />
      </Modal>
    </>
  );
};

export default ProductList;
