import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddType from "./AddType";
import { Loader, Pagination, AddBtn } from "../../common";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import SingleType from "./Details";

const MerchantTypeList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedType, setSelectedType] = useState(undefined);
  const [selectedTypeForEdit, setSelectedTypeForEdit] = useState(undefined);

  const [typeData, setTypeData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTypes = useCallback(async () => {
    setTypeData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/merchant/type?type=${type}` +
          (status ? `&deleted=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTypeData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTypeData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, []);

  //
  // const onDelete = async (id: any, val: any) => {
  //   if (id) {
  //     await fetch(`${process.env.REACT_APP_RIDER_API}/type`, {
  //       method: "PUT",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: id,
  //         deleted: !val,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.statusCode === 200) {
  //           responseNotification("Status Updated Successfully", "success");
  //           getTypes();
  //         } else if (res.status === 500) {
  //           responseNotification("Internal server error", "error");
  //         } else {
  //           responseNotification(res.message || "something wrong", "warning");
  //         }
  //       })
  //       .catch((err) => {
  //         responseNotification(`${"Internal server error"} ${err}`, "error");
  //         console.error("err", err);
  //       });
  //   }
  // };

  useEffect(() => {
    getTypes();
  }, [getTypes]);

  const onClose = () => {
    getTypes();
    setVisible(undefined);
    setSelectedType(undefined);
    setSelectedTypeForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Merchant Type List"
        subTitle={`${
          typeData?.data?.totalElements ||
          typeData?.data?.merchantTypes?.length ||
          0
        } Type(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {typeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {typeData?.data?.merchantTypes?.length ? (
                      typeData?.data?.merchantTypes?.map(
                        (type: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedType(type)}
                          >
                            <td className="flex items-center gap-2">
                              <Avatar
                                size={45}
                                src={type?.icon}
                                shape="square"
                                className="border"
                              />
                              <span className="font-medium text-gray-500">
                                {type?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {type?.description?.slice(0, 70)}
                              </span>
                            </td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!type?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  // onDelete(type?.id, val);
                                }}
                              />
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...typeData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Type"
            : selectedTypeForEdit
            ? "Edit Type"
            : "Type Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedType || selectedTypeForEdit}
        width={450}
      >
        {visible || selectedTypeForEdit ? (
          <AddType onCloseMethod={onClose} visibleData={selectedTypeForEdit} />
        ) : (
          <SingleType SingleType={selectedType} />
        )}
      </Drawer>
    </>
  );
};

export default MerchantTypeList;
