import FileManager from "../components/FileManager";
import MainLayout from "../components/Layouts";

const FileUploaderPage = () => {
  return (
    <MainLayout>
      <FileManager />
    </MainLayout>
  );
};

export default FileUploaderPage;
