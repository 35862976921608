import React from "react";
import MainLayout from "../../components/Layouts";
import CorporateList from "../../components/Corporate";

const CorporatePage = () => {
  return (
    <MainLayout>
      <CorporateList />
    </MainLayout>
  );
};

export default CorporatePage;
