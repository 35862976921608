import React from "react";
import MainLayout from "../../components/Layouts";
import AddNeighborhood from "../../components/Neighborhood/AddNeighborhood";

const NeighborhoodAddPage = () => {
  return (
    <MainLayout>
      <AddNeighborhood />
    </MainLayout>
  );
};

export default NeighborhoodAddPage;
